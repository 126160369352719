import { isPlatformBrowser } from '@angular/common';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, take, timeout } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { CONSTANT } from '../../constant/CONSTANT';
import { ERRORS_TYPES } from '../../constant/ERRORS_TYPES';
import { ERRORS_THROWS } from '../../constant/ERRORS_THROWS';
import { SERVER_ERRORS } from '../../constant/server-errors';

export class ApiBaseService {
  protected apiUrl = `${environment.api}`;
  protected _httpRequest;

  constructor(protected _utils,
              protected http,
              @Inject(PLATFORM_ID) protected _platformId: Object,
              protected axiosClient) {
  }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.throwIfTimeoutError();

    return defer(() => this.axiosClient.get(`${this.apiUrl}${path}`, {params})).pipe(
      catchError((error: HttpErrorResponse) => this._handleError(error)
      )
    );
  }

  /**
   * Получение данных из внешних api
   * @param url - полный путь к endpoint
   * @param params - дополнительные параметры Get запроса
   */
  public getFromCustomApi(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.throwIfTimeoutError();
    return this.http.get(url, {params})
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => this._handleError(error))
      );
  }

  public post(path: string, body: Object = {}): Observable<any> {
    this.throwIfTimeoutError();

    return defer(() => this.axiosClient.post(`${this.apiUrl}${path}`, body))
      .pipe(
        take(1),
        timeout(CONSTANT.time.timeout),
        catchError((error: HttpErrorResponse) => this._handleError(error)
        )
      );
  }

  protected _handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this._platformId) && error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if ((error as { name: string }).name === 'TimeoutError') {
      // timeout error
      if (this._utils.apiTimeoutError !== true) {
        this._utils.requestErrorType = ERRORS_TYPES[ERRORS_TYPES.errorResponse];
        this._utils.apiTimeoutError = true;
      }
      return throwError(ERRORS_THROWS.timeout);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(ERRORS_THROWS.somethingBad);
  }

  protected throwIfTimeoutError() {
    if (this._utils.apiTimeoutError === true) {
      return throwError(ERRORS_THROWS.rejectedRequest);
    }
  }

  protected _setResponseInterceptor() {
    this.axiosClient.interceptors.response.use(
      (response) => {
        if (response.config?.url?.indexOf(environment.api) > -1) {
          const code = response.data?.error?.code;
          switch (code) {
            case SERVER_ERRORS.cant_detect_country:
            case SERVER_ERRORS.not_available_in_country:
              this._utils.requestErrorType = ERRORS_TYPES[ERRORS_TYPES.errorUnavailable];
              break;
            case SERVER_ERRORS.tech_work:
              this._utils.requestErrorType = ERRORS_TYPES[ERRORS_TYPES.errorResponse];
              break;
            default:
              break;
          }
        }

        return response.data;
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this._utils.requestErrorType = ERRORS_TYPES[ERRORS_TYPES.errorResponse];
        }
        return Promise.reject(err);
      }
    )
  }
}
