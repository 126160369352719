import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pk-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {
  @Input() type: {
    title: string;
    text: string;
  };
  @Output() Close = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeModal(): void {
    this.Close.next('close');
  }

}
