
<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>
  <div class="modalContent">
    <div class="modalContentInner">

        <!-- Загрузка -->
        <div *ngIf="!key" class="success-key__container-loading">
          <h1 class="success-key__title">{{'pkLoader' | translate}}</h1>
        </div>

        <!-- Загружено -->
        <div *ngIf="!!key">
          <h1 id="success-key__title" class="success-key__title">{{'keys.congratulations' | translate}}</h1>

          <h2 id="success-key__description" class="success-key__description">
            {{'keys.needAccount' | translate}} {{ rightholderName }}{{'keys.canCreate' | translate}} <a class="g-link" href="{{ rightholderLink }}" target="_blank">{{ rightholderName }}</a><br/><br/>
            <span [innerHTML]="type.keyInfo"></span>
            <br />
          </h2>

          <div id="success-key__key" class="success-key__key" title="{{ key }}">{{ key }}</div>
          <button
            id="modalButtonOk"
            class="modalButton modalButton--veryBig g-button--play-color"
            (click)="closeModal()">
            {{'popupBanner.play' | translate}}
          </button>
        </div>
    </div>
  </div>
</div>
