<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>
  <div class="modalContent">
    <div class="modalContentInner">
      <div class="modal__night-top">
        <div class="modal__night-start">{{util.timespanConverter(type.subscriptons.start_action ? type.subscriptons.start_action :'' , 'short')}}</div>
        <div class="modal__night-finish">{{util.timespanConverter(type.subscriptons.finish_action , 'short') }}</div>
      </div>
      <div class="modal__night-title">{{ 'modal.nightTitle' | translate }}</div>
      <div class="modal__night-description">{{ util.getParsedTime(('modal.nightAvailable' | translate), type.subscriptons) }}</div>
      <div class="g-button g-button--secondary g-button--medium" (click)="closeModal()">{{ 'close' | translate }}
      </div>
    </div>
    </div>
</div>
