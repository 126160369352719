import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { FreshChatService } from './fresh-chat.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { PaymentService } from '../../../payments/services/payment.service';
import { UserService } from '../../../user/services/user.service';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'pk-fresh-chat',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreshChatComponent implements OnInit, OnDestroy {
  private _ngUnsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private _freshChatService: FreshChatService,
    private _cd: ChangeDetectorRef,
    private _payment: PaymentService,
    private _user: UserService,
    private _lang: LangService
  ) {
  }

  ngOnInit(): void {
    this._initFreshChatComponent();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  /**
   * Инициализация компонента FreshChat'а
   * @private
   */
  private _initFreshChatComponent() {
    combineLatest([
      this._user.userInfo$,
      this._payment.getUserSales()
    ])
      .pipe(
        distinctUntilChanged(),
        filter(([userInfo, userSales]) => Boolean(userInfo) && userSales && userSales.length > 0),
        takeUntil(this._ngUnsubscribe$),
      )
      .subscribe(([userInfo, userSales]) => {
          this._freshChatService.setFreshChatWidgetSettings({
            firstName: userInfo.login,
            email: userInfo.email,
            externalId: userInfo.id
          }, () => this._freshChatOnLoadHandler(userInfo.id, userSales[0].number));
          this._freshChatService.initFreshChatWidget();
        },
      );
  }

  /**
   * Обработчик события onLoad виджета FreshChat
   * @private
   */
  private _freshChatOnLoadHandler(userId, userLastSaleId) {
    this._freshChatService.setFreshChatUserProps({
      userId: userId,
      saleId: userLastSaleId
    });
    this._freshChatService.emitFreshChatOnLoadEvent();
  }
}
