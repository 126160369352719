/**
 * CONSTANT. Lang list that used at portal
 */
export  const CURRENTLANG = {
  support: {
    ru: '//support.playkey.net/ru-RU/support/solutions',
    en: '//support.playkey.net/en/support/solutions',
  },
  supportEmail: {
    ru: 'support@playkey.net',
    en: 'support@playkey.net',
  }
};


