import { Injectable } from '@angular/core';
import {ISubscription} from '../../user/interfaces/subscription';
import {IProducts} from '../interfaces/IProducts';
import { Product, ProductCost } from '../../games/interfaces/product';
import { ApiService } from '../../shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../user/services/subscription.service';
import { UserService } from '../../user/services/user.service';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { Observable, of} from 'rxjs';
import {flatMap, map, take} from 'rxjs/operators';
import {ITariffDuration} from '../interfaces/tariff-duration';
import {UtilsService} from '../../shared/services/utils.service';
import { ConstantService } from '../../shared/services/constant.service';
import { CurrencyService } from '../../shared/services/currency.service';

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  private  baseUrl = 'PlaykeyAPI.svc';
  private tariffName = '720';

  public selectedProduct: any = null; // (IProduct & ITimePacket & ISubscription)
  public isSelectedSubscription = false;
  public constantSubscription: any;

  constructor(
    private _api: ApiService,
    private _translate: TranslateService,
    private _subscription: SubscriptionService,
    private _user: UserService,
    private _utils: UtilsService,
    private _cookie: CookieWrapperService,
    private _const: ConstantService,
    private _currency: CurrencyService
  ) {
    this.constantSubscription = this._const.get('CONSTANT.subscription');
  }

  /**
   * Метод получения списка тарифов
   */
  getProduct(): Observable<IProducts> {
    return  this._user.checkAuth()
      .pipe(
        take(1),
        flatMap(data => data ? this._subscription.data$ : of(null)),
        flatMap(
          data => {
            const isAuth = Array.isArray(data);
            const url = isAuth ? '/products/all/auth' : '/products/all';
            const props: any = {
              lang: this._translate.currentLang || this._cookie.get('lang'),
              subscription_id: isAuth && data.length ? data[0].id : null,
              subscription_type: isAuth && data.length ? data[0].type : null,
            };
            if (Boolean(this._user.token)) {
              props['token'] = this._user.token;
            }
            return this._api.post(`${this.baseUrl}${url}`, props);
          }
        ),
        map((productREs: IProducts) => {
          if (productREs?.products) {
            const ProductArr = productREs?.products.map(product => this._getProductInst(product));
            productREs.products = ProductArr;
            return productREs;
          }
          return null;
        })
      );
  }

  /**
   * Метод получения тарифа по его коду
   * @param code
   */
  getProductByCode(code: string): Observable<Product> {
    return this.getProduct()
      .pipe(
        map(data => {
          const products = data.products.filter(item => item.sub_code === code);
          return products.length ? products[0] : null;
        })
      );
  }

  /**
   * Метод получения тарифов по type_tariff
   * @param type
   */
  getProductByTypeTariff(type: string): Observable<Product> {
    return this.getProduct()
      .pipe(
        map(data => {
          const products = data.products.filter(item => item.type_tariff === type);
          return products.length ? products[0] : null;
        })
      );
  }

  /**
   * Метод получения символа валюты для вывода в карточке тарифа
   * @param cost
   */
  public getShortCurrency(cost: ProductCost): string {
    const currency = this._const.get('CONSTANT.currency');
    if (cost === undefined || cost.currency === undefined || cost.currency.code === currency.ruble) {
      return this._translate.instant('currencyRuble');
    }
    switch (cost.currency.code) {
      case currency.euro:
        return this._translate.instant('currencyEuroSymbol');
      case currency.dollar:
        return this._translate.instant('currencyDollar');
      case currency.pound:
        return this._translate.instant('currencyPound');
      default:
        return '';
    }
  }

  /**
   * Метод, проверяющий нужно ли выводить тариф на странице
   * @param tariff
   * @param subreg
   */
  needShowTariff (tariff: Product , subreg: ISubscription): boolean {
    if (tariff === undefined || tariff.options === undefined) {
      return false;
    }
    if (tariff.options.IS_HIDE === undefined) {
      if (tariff.type === this.constantSubscription.type.time) {
        return this._isActiveSubscription(subreg);
      }
      return true;
    }
    return false;
  }

  /**
   * Метод получения элементов оформления карточки тарифа (зависит от стоимости тарифа)
   * @param product - тариф
   * @param min - минимальная стоимость тарифа среди полученных из АПИ
   * @param max - максимальная стоимость тарифа среди полученных из АПИ
   */
  ConvertObjectProduct (product: Product, min: number, max: number): Product {
    if (product.type === this.constantSubscription.type.regularPartOfDay) {
      product.img = '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/payment-all/star-clock.svg';
    } else if  (product.price_sell === max) {
      product.img = '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/payment-all/wing_yellow_gamepad.svg';
    } else if (product.price_sell === min) {
      product.img = '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/payment-all/gamepad.svg';
    } else {
      product.img = '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/payment-all/big_gamepad.svg';
    }

    return product;
  }

  /**
   * Метод получения минимальной и максиммальной стоимости среди тарифов, полученных из АПИ
   * @param products
   */
  getProductsPrice(products: Product[]): Array<number> {
    const price = products.map( product => product.price_sell);
    return [ this._getMinPrice(price), this._getMaxPrice(price)];
  }

  /**
   * Метод получения самого дешевого тарифа
   */
  public getLowerPriceProduct(): Observable<Product> {
    return this.getProduct()
      .pipe(
        map(data => {
          const products = data.products.filter(p => Boolean(p.options) && Boolean(p.options.IS_HIDE));
          return products.reduce((p1, p2) => p1.price_sell < p2.price_sell ? p1 : p2);
        })
      );
  }

  /**
   * Метод получения самого дешевого тарифа из месячных
   * не учитываются тарифы IS_HIDE, а так же type 'Time' и 'RegularPartOfDay'
   */
  public getMonthLowerPriceProduct(): Observable<Product> {
    return this.getProduct()
      .pipe(
        map((data) => {
          const products = data.products.filter(p => {
            const isHide = Boolean(p.options) && Boolean(p.options.IS_HIDE);
            const notRegularTypes = [this.constantSubscription.type.time, this.constantSubscription.type.regularPartOfDay];
            return notRegularTypes.indexOf(p.type) < 0 && p.duration_days === 30 && !isHide;
          });
          return Boolean(products.length) ? products.reduce((p1, p2) => p1.price_sell < p2.price_sell ? p1 : p2) : null;
        })
      );
  }

  /**
   * Метод получения продолжительности тарифа
   * @param activeTo
   */
  getTariffDuration (activeTo: string): ITariffDuration {
    if (!activeTo) {
      return {} as ITariffDuration;
    }

    const endDate: any = new Date(this._utils.filter(activeTo, 'mm/dd/yyyy'));
    const diff: number = Math.abs(endDate - Date.now());
    return {
      days: this._utils.timestampToDays(diff),
      hours: new Date(diff).getUTCHours(),
    };
  }

  getCost(product: Product): ProductCost {
    if (Boolean(this._currency.selectedCurrency)) {
      const neededCost = product.costs.filter(
        cost => cost.currency.code === this._currency.selectedCurrency.code
      );
      if (neededCost.length) {
        return neededCost[0];
      }
    }
    return Boolean(product?.costs.length) ? product.costs[0] : null;
  }

  public isActiveAction(products: Product[]): boolean {
    return products.some(product => {
      if (this.isProductInAction(product)) {
        const cost = this.getCost(product);
        const discountActiveToDateTimestamp = this._utils.convertDateFromMoscowToLocalTimestamp(
          this._utils.filter(cost.discount.active_to, 'yyyy/dd/mm hh:mm'));
        const currentDateTimestamp = Date.now();
        return discountActiveToDateTimestamp > currentDateTimestamp;
      }
      return false;
    });
  }

  public isProductInAction(product: Product): boolean {
    if (!product || !product.costs) {
      return false;
    }

    const cost = this.getCost(product);
    return Boolean(cost) && Boolean(cost.discount)
      && !cost.discount.code.includes('deposit_action');
  }

  public getActiveActionDiscountCode(products: Product[]) {
    return this.getCost(products.find(pac => this.isProductInAction(pac)))?.discount?.code;
  }

  /**
   * Метод проверяющий, активна ли регулярная подписка пользователя
   * @param subreg
   */
  private _isActiveSubscription (subreg: ISubscription): boolean {
    return subreg === undefined
      || subreg.is_active_tariff === undefined
      || subreg.is_active_tariff === false;
  }

  private _getMaxPrice(price: number[]): number {
    return   Math.max(...price);
  }

  private _getMinPrice (price: number[]): number {
    return   Math.min(...price);
  }

  private _getMonth (days: number): string {
    switch (days) {
    case 0: return '';
    case 1: return this._translate.instant('tariff.24hours');
    case 5: return this._translate.instant('tariffList.fiveDays');
    case 7: return this._translate.instant('tariffList.week');
    case 30: return this._translate.instant('tariff.oneMonth');
    case 90: return this._translate.instant('tariff.3month');
    case 180: return this._translate.instant('tariff.6month');
    // TODO: Сделать показ месяцев по формуле
    default: return this._translate.instant('tariff.oneMonth');
    }
  }

  private _getLimitImg (type: string): string {
    if (type === this.constantSubscription.type.time) {
      return '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/' +
      'payment/tariff/tariff_bottom_block_endless.png';
    }
    return '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/' +
      'payment/tariff/tariff_bottom_block_period.png';
  }

  private  _minutesToHours (minutes: number): number {
      return Math.floor(minutes / 60);
  }

  /**
   * Метод получения лимита
   * @param type
   * @param minutes
   * @param durationDays
   */
  private _getLimitTime (type: string, minutes: number, durationDays: number): string {
      if (minutes === null || minutes === 0) { return ''; }
      const hours = this._minutesToHours(minutes);
      if (durationDays === 7) {
        return `${hours} ${this._translate.instant('tariff.hourWeek')}`;
        }
      if (type === this.constantSubscription.type.time) { return `${minutes} ${this._translate.instant('accountSettings.minute')}`;
      } else if (type === this.constantSubscription.type.custom)  { return `${this._filter( minutes, 'sec', 'min')}`;
      } else if (type === this.constantSubscription.type.regularPartOfDay) { return this._translate.instant('tariff.unlimit');
      } else { return `${hours} ${this._translate.instant('tariff.hourMonth')}`; }
  }

  private _getFullLimitTime (type: string, minutes: number, durationDays: number): string {
      if (minutes === null) {  return this._translate.instant('payment.tariff_list.play_tariffs.unlimit'); }
      if (minutes === 0) {  return ''; }
      const hours = this._minutesToHours(minutes);
      if (durationDays === 7) {
          return `${hours} ${this._translate.instant('payment.tariff_list.play_tariffs.hours_per_week')}`;
      }
      if (durationDays === 365) {
          return `${hours} ${this.getHourEnd(hours)} ${this._translate.instant('payment.tariff_list.play_tariffs.per_year')}`;
      }
      if (type === this.constantSubscription.type.time) {
        return `${minutes} ${this._translate.instant('payment.tariff_list.play_tariffs.minutes')}`;
      } else if (type === this.constantSubscription.type.custom) { return `${this._filter( minutes, 'sec', 'min')}`;
      } else if (type === this.constantSubscription.type.regularPartOfDay) {
      return this._translate.instant('payment.tariff_list.play_tariffs.unlimit');
      } else {
        return `${hours} ${this._translate.instant('payment.tariff_list.play_tariffs.hours_per_month')}`;
    }
  }

  public getHourEnd (hours: number): string {
    const ends = [
      this._translate.instant('decOfNum.hour'),
      this._translate.instant('decOfNum.houra'),
      this._translate.instant('decOfNum.hours'),
    ];
    const cases = [2, 0, 1, 1, 1, 2];
    return ends[ (hours % 100 > 4 && hours % 100 < 20) ? 2 : cases[(hours % 10 < 5) ? hours % 10 : 5] ];
  }

  /**
   * Метод получения интервала времени (для временных подписок)
   * @param type
   * @param minutes
   * @param durationDays
   * @private
   */
  private _getPeriodDate (type: string, minutes: number, durationDays: number): string {
    if (minutes === null || minutes === 0) {
      return '';
    }
    if (durationDays === 7) {
      return this._translate.instant('tariff.perodWeek');
    }
    if (type === this.constantSubscription.type.time) {
      return this._translate.instant('accountSettings.minute');
    } else if (type === this.constantSubscription.type.custom) {
      this._filter( minutes, 'sec', 'min');
    } else if (type === this.constantSubscription.type.regularPartOfDay) {
      return this._translate.instant('tariff.unlimit');
    } else {
      return this._translate.instant('tariff.periodMonth');
    }
  }

  private _getFullPeriodDate (type: string, minutes: number, durationDays: number): string {
    if (minutes === null || minutes === 0) {
      return '';
    }
    if (durationDays === 7) {
      return this._translate.instant('payment.tariff_list.play_tariffs.short_week');
    }
    if (type === this.constantSubscription.type.time) {
      return this._translate.instant('payment.tariff_list.play_tariffs.short_min');
    } else if (type === this.constantSubscription.type.custom) {
      return this._filter( minutes, 'sec', 'min');
    } else if (type === this.constantSubscription.type.regularPartOfDay) {
      return this._translate.instant('payment.tariff_list.play_tariffs.unlimit');
    } else {
      return this._translate.instant('payment.tariff_list.play_tariffs.short_month');
    }
  }

  /**
   * Метод получения продолжительности тарифа
   * @param type
   * @param minutes
   * @param durationDays
   * @private
   */
  private _getDurationText (type: string, minutes: number, durationDays: number): string {
    if (durationDays === 7) {
      return this._translate.instant('payment.tariff_list.play_tariffs.sub_on_week');
    }
    if (type === this.constantSubscription.type.time) {
      return this._translate.instant('payment.tariff_list.play_tariffs.sub_on_hour');
    }
    return this._translate.instant('payment.tariff_list.play_tariffs.sub_on_month');
  }

  /**
   * Метод получения текста для кнопки покупки тарифа
   * @param type
   * @param minutes
   * @param durationDays
   * @private
   */
  private _getBuyText (type: string, minutes: number, durationDays: number): string {
    if (durationDays === 7) {
      return this._translate.instant('payment.tariff_list.play_tariffs.buy_week');
    }
    if (type === this.constantSubscription.type.time) {
      return this._translate.instant('payment.tariff_list.play_tariffs.buy_hour');
    }
    return this._translate.instant('payment.tariff_list.play_tariffs.buy_month');
  }

  private _getProductInst (prod: Product): Product {
    prod.month = this._getMonth(prod.duration_days);
    prod.periodImg = this._getLimitImg(prod.type);
    prod.limit = this._getLimitTime(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.full_limit = this._getFullLimitTime(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.perDate = this._getPeriodDate(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.full_per_date_time = this._getFullPeriodDate(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.duration_text = this._getDurationText(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.buy_text = this._getBuyText(prod.type, prod.limit_count_minutes, prod.duration_days);
    prod.options = {} as any;
    prod.parameters.forEach(x => prod.options[x.name] = x.value);
    prod.is_full_hd = prod.options.RESOLUTION !== undefined
      && prod.options.RESOLUTION > this.tariffName;
    return prod;
  }

  public _filter (data, type, end) {
    let result = 0;
    if (type === 'min') { result = Math.round(data);
    } else {
      result = Math.round(data / 60);
    }
    if (result % 60 >= 11 && result % 60 <= 20) {
        return result + ' ' + this._translate.instant('catalog.play_minut');
    } else if (result % 10 === 1) { // Минута
        return end === 'second' ?
            result + ' ' + this._translate.instant('catalog.play_minutu') :
            result + ' ' + this._translate.instant('catalog.play_minuta');
    } else if (result % 10 >= 2 && result % 10 <= 4) { // Минуты
        return result + ' ' + this._translate.instant('catalog.play_minuti');
    } else if (result % 10 >= 5 && result % 10 <= 20 ) { // Минут
        return result + ' ' + this._translate.instant('catalog.play_minut');
    } else {
        return result + ' ' + this._translate.instant('catalog.play_end');
    }

  }
}
