<div class="error-unavailable">
  <div class="error-unavailable__inner">
    <div class="error-unavailable__pk-logo"></div>
    <div class="error-unavailable__user-info">
      {{'serviceBlock.yourIp' | translate}} {{ ipAdress }}<br/>
      {{'serviceBlock.yourCountry' | translate}} {{ country }}<br/>
    </div>
    <div class="error-unavailable__playkey-blocked">
      {{'serviceBlock.notAvailable' | translate}}
    </div>
    <div class="error-unavailable__be-first">{{'serviceBlock.beFirst' | translate}}</div>
    <div class="error-unavailable__send-mail">
      <input class="error-unavailable__send-mail_send-input"
             type="text"
             id="emailCities"
             name="emailCities"
             autofocus
             required
             #email="ngModel"
             [email]="emailCities !== ''"
             [class.has-error]="email.invalid && email.touched"
             [(ngModel)]="emailCities"
             placeholder="email@example.com">
      <span class="error-unavailable__send-mail_error"
            *ngIf="email.errors?.required && email.touched">
        Email is required
      </span>
      <span class="error-unavailable__send-mail_error"
            *ngIf="email.errors?.email && email.touched">
        Email is invalid
      </span>
      <div class="error-unavailable__send-mail_send-btn"
           (click)="sendEmail()"
           [class.disabled-btn]="email.invalid"
           *ngIf="!isLoad">
        {{'serviceBlock.signUp' | translate}}
      </div>
      <div class="error-unavailable__send-mail_send-btn"
           *ngIf="isLoad">
        <pk-loader></pk-loader>
      </div>
    </div>
  </div>
  <div class="error-unavailable__success" *ngIf="isResult">
    <div class="error-unavailable__success-content">
      <div class="error-unavailable__success-content_close-result"
           (click)="closeResult();"></div>
      <div class="error-unavailable__success-content_text">{{ resultMsg | translate }}</div>
    </div>
  </div>
  <div class="error-unavailable__fade-out"
       *ngIf="isResult"
       (click)="closeResult();"></div>
</div>
