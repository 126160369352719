import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GameShipment } from '../../../games/interfaces/game-shipment';
import { IBundleInfo } from '../../../payments/interfaces/bundle-info';
import { Product } from '../../../games/interfaces/product';
import { DecOfNumPipe } from '../../../shared/pipes/dec-of-num.pipe';
import { GamesService } from '../../../shared/services/games.service';
import { take } from 'rxjs/operators';
import { PlatformsInfoService } from '../../../shared/services/platforms-info.service';

@Component({
  selector: 'pk-success-bundle',
  templateUrl: './success-bundle.component.html',
  styleUrls: ['./success-bundle.component.scss'],
  providers: [DecOfNumPipe]
})
export class SuccessBundleComponent implements OnInit {
  public key: string;

  public game: GameShipment;

  public gamePlatformName: string;

  public product: Product;

  public productDurationTime: string;

  public bundleInfo: IBundleInfo;


  @Input() type: any;

  @Output() Close = new EventEmitter();

  @ViewChild('copykeyEl', { read: ElementRef }) copyKeyEl;

  constructor(
    private _localize: LocalizeRouterService,
    private _translate: TranslateService,
    private _router: Router,
    private _decOfNumPipe: DecOfNumPipe,
    private _game: GamesService,
    private _platformsInfo: PlatformsInfoService,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
  }

  ngOnInit() {
    this.game = this.type.gameInfo;
    this.product = this.type.productInfo;
    this.bundleInfo = this.type.bundleInfo;
    this._platformsInfo.getGamePlatformInfoByCode(this.game.platform_code)
      .pipe(take(1))
      .subscribe( platformInfo => {
        this.gamePlatformName = platformInfo.name;
        this.key = this.type.key;
      });
    this.productDurationTime = this._decOfNumPipe.transform(
      this.product.duration_minutes / 60,
      [
        this._translate.instant('duration.hour1'),
        this._translate.instant('duration.hour2'),
        this._translate.instant('duration.hour3')
      ]
    );
  }

  // Закрытие модального окна по ESC
  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal(): void {
    // переходим на главную страницу
    this._router.navigate([this._localize.translateRoute('/game'), this.game.code]);
    // удаляем динамический компонент
    this.Close.next('close');
  }

  /**
   * Копирование ключа в буфер обмена
   * TODO - подготовать, как замену AccountService -> copyToClipboard,
   * которые после своей работы убивает обычное поведение копирования на странице
   */
  public copyKeyClick() {
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(this.copyKeyEl.nativeElement);
    window.getSelection().addRange(range);

    try {
      document.execCommand('copy');
    } catch (err) {
      console.log('Can`t copy');
    }

    window.getSelection().removeAllRanges();
  }
}
