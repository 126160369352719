import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GamesService } from '../../../../shared/services/games.service';
import { ICategoryAndGenresResponse } from '../../../interfaces/category-and-genres-response';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../user/services/user.service';
import { ApiService } from '../../../../shared/services/api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GamesCategoriesService {

  private _gamesCategories = {};
  private _baseUrl = `${environment.apiPathPrimary}games/categories`;
  private _gamesCategoriesChanged = new BehaviorSubject({});
  public gamesCategoriesChanged$ = this._gamesCategoriesChanged.asObservable();

  constructor(
    private _games: GamesService,
    private _translate: TranslateService,
    private _user: UserService,
    private _api: ApiService
  ) {
  }

  public getGamesCategories() {
    return this._gamesCategories;
  }

  public setGamesCategories(categories) {
    this._gamesCategories = {...categories};
    this._gamesCategoriesChanged.next(this._gamesCategories);
  }

  public clearGamesCategories() {
    this._gamesCategories = {};
    this._gamesCategoriesChanged.next(this._gamesCategories);
  }

  public getGamesCategoryByCode(code) {
    return this._gamesCategories[code];
  }

  /**
   * Метод получения категорий и жанров игр для фильтров
   * @returns {Observable<ICategoryAndGenresResponse>}
   */
  public getCategoriesAndGenres() {
    const url = `${this._baseUrl}`;
    const params: any = {
      lang: this._translate.currentLang
    };
    if (Boolean(this._user.token)) {
      params.token = this._user.token;
    }
    return this._api.post(`${url}`, params)
      .pipe().subscribe(data => {
        const gamesCategoriesObj = {};
        const allItems = [...data.categories];
        allItems.forEach(item => {
          gamesCategoriesObj[item.code] = item.name;
        });
        this.setGamesCategories(gamesCategoriesObj);
      });
  }
}
