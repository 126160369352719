import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'pk-attention-night',
  templateUrl: './attention-night.component.html',
  styleUrls: ['./attention-night.component.scss']
})
export class AttentionNightComponent implements OnInit {
  @Input() type: any;
  @Output() Close = new EventEmitter<any>();
  constructor(
    public util: UtilsService,
  ) {}
  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }
  ngOnInit() {
  }

  closeModal(): void {
    this.Close.next('close');
  }

}
