import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropMiddleTextPipe } from '../../pipes/crop-middle-text.pipe';
import { DecOfNumPipe } from '../../pipes/dec-of-num.pipe';
import { DateConverterPipe } from '../../pipes/date-converter.pipe';
import { SecondsToDatePipe } from '../../pipes/seconds-to-date.pipe';
import { SecretNumberPipe } from '../../pipes/secret-number.pipe';
import { EmailGoodPipe } from '../../pipes/email-good.pipe';
import { NewLineConverterPipe } from '../../pipes/new-line-converter.pipe';
import { SanitizeHtmlPipe, SanitizeResourceUrlPipe } from '../../pipes/sanitize-html.pipe';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { LoaderModule } from '../loader/loader.module';
import { LongDateLocalize } from '../../pipes/long-date-localize';



@NgModule({
  declarations: [
    CropMiddleTextPipe,
    DecOfNumPipe,
    DateConverterPipe,
    SecondsToDatePipe,
    SecretNumberPipe,
    EmailGoodPipe,
    NewLineConverterPipe,
    SanitizeHtmlPipe,
    SanitizeResourceUrlPipe,
    ReplacePipe,
    LongDateLocalize
  ],
  providers: [
    DecOfNumPipe
  ],
  imports: [
    CommonModule,
    LoaderModule
  ],
  exports: [
    CropMiddleTextPipe,
    DecOfNumPipe,
    DateConverterPipe,
    SecondsToDatePipe,
    SecretNumberPipe,
    EmailGoodPipe,
    NewLineConverterPipe,
    SanitizeHtmlPipe,
    SanitizeResourceUrlPipe,
    ReplacePipe,
    LongDateLocalize
  ]
})
export class SharedPipesModule { }
