import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LangService } from './lang.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    @Inject(DOCUMENT) private _doc: any,
    private _lang: LangService,
    private _translate: TranslateService,
    private _meta: Meta,
  ) {}

  public updateCanonicalUrl(url: string | null) {
    const head = this._doc.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this._doc.querySelector(`link[rel='canonical']`) || null;
    if (!url) {
      if (Boolean(element)) {
        head.removeChild(element);
      }
      return;
    }
    if (!element) {
      element = this._doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  public initSubscribeForLocalizeMeta () {
    const lang = this._lang.getLang();
    this._translate.setDefaultLang(lang);
    this._translate.use(lang)
      .pipe(take(1))
      .subscribe(() => {
        this._updateMainMetaTags();
    });
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._updateMainMetaTags();
    });
  }

  private _updateMainMetaTags () {
    const descriptionContent = this._translate.instant('meta.description');
    this._meta.updateTag({name: 'description', content: descriptionContent});
    this._meta.updateTag({property: 'og:description', content: descriptionContent});
    this._meta.updateTag({name: 'twitter:description', content: descriptionContent});
    this._meta.updateTag({content: descriptionContent}, `itemprop="description"`);
  }
}
