import {Injectable, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {combineLatest, Subscription} from 'rxjs';
import {UserService} from '../../user/services/user.service';
import {SubscriptionService} from '../../user/services/subscription.service';
import {UtilsService} from './utils.service';
import {ISubscription} from '../../user/interfaces/subscription';
import {IUserInfo} from '../../user/interfaces/user-info';
import {AnalyticService} from './analytic.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushService implements OnDestroy {
  public isLoaderRequest = false;
  public  isSend = false;
  public isInit = false;
  private  _subscriptions: ISubscription[];
  private  _userInfo: IUserInfo;
  private _unsub: Subscription;
  constructor(
    private  _user: UserService,
    private  _sub: SubscriptionService,
    private _util: UtilsService,
    private _analytic: AnalyticService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
  this._unsub = combineLatest([this._sub.data$, this._user.userInfo$])
    .subscribe(data => {
      this._subscriptions = data[0];
      this._userInfo = data[1];
      if (data[1] && isPlatformBrowser(this._platformId)) {
        this.initNotification();
      }
    });

  }

  public initNotification (): void {
    if (this._user.token) {
      this._util.loadScript('//vkplaycloud.mrgcdn.ru/portal-static/OneSignalSDK.js').subscribe(data => {
        if (this.isShowNotify() === true && data) {
          this.notify();
        }
      });
    }
  }


  public isShowNotify (): boolean {
    const isNowShowNotify: boolean = localStorage.getItem('notify') === null
      && localStorage.getItem('notified') === null;
    const isDemoRun: boolean = localStorage.getItem('demoRun') !== null;
    const isWasInSelectPaymentSystem = localStorage.getItem('wasInSelectPayment') !== null;

    const isInWeek = (timestamp: number): boolean => {
      const currentDate = new Date();
      return Math.round(this._util.timestampToDays(currentDate.getTime() - timestamp)) < 8;
    };

    const isRestricted = this.isNotifyRestrictedInThisUrl();

    const isSecondEnterToSiteInWeekPeriod = localStorage.getItem('enterSite') !== null
      && isInWeek(parseInt(localStorage.getItem('enterSite'), 10)) === true;

    return isNowShowNotify === true
      && (this._subscriptions !== undefined
        || isDemoRun === true
        || isWasInSelectPaymentSystem === true
        || isSecondEnterToSiteInWeekPeriod === true)
      &&  isRestricted === false;
  }

  /**
   * Проверяет, запрещен ли попап про пушы на текущем url по заранее
   * определенному списку.
   */
  public isNotifyRestrictedInThisUrl (): boolean {
    const restrictingPathes = ['/steps/payment', '/steps/select_ps'];
    const currentPath = `${location.pathname}`;
    return restrictingPathes.some(item => currentPath.indexOf(item) > -1);
  }

  public isNotAcceptNotify (): boolean {
    if (this._userInfo !== undefined && isPlatformBrowser(this._platformId)) {
      return localStorage.getItem('notified') === null;
    }
  }
  public unsubscribe (): void {
    const oneSignal = (<any>window).OneSignal || [];
    oneSignal.setSubscription(false);
    localStorage.removeItem('notified');
  }
  public subscribe (): void {
    const oneSignal = (<any>window).OneSignal || [];
    oneSignal.setSubscription(true);
    localStorage.setItem('notified', 'true');
  }

  public requestConfirmMail () {
    this.isLoaderRequest = true;
    this._user.requestConfirmMail().subscribe(resp => {
      if (resp.error.code === 0) {
      }
      this.isLoaderRequest = false;
    });
    this.isSend = true;
  }

  public notify (): void {
    const oneSignal = (<any>window).OneSignal || [];
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1
      && navigator.userAgent.indexOf('Chrome') === -1;
    const id = isSafari === true
      ? 'web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174'
      : '639e2399-b0ae-4e6f-b679-98762ce7ee2a';

    if (this.isInit === false) {
      oneSignal.push(['init', {
        appId: id,
        autoRegister: false,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: false,
        },
      }]);
      if (this._userInfo !== undefined) {
        this._analytic.pushTag([
          {
            user_id: this._userInfo.id.toString(),
          },
          {
            email: this._userInfo.email,
          },
        ]);
      }
      this.isInit = true;
    }

    oneSignal.push(function() {
      oneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (!isEnabled) {
          oneSignal.registerForPushNotifications();
        } else {
          localStorage.setItem('notified', 'true');
        }
      });
      oneSignal.on('notificationPermissionChange', function(permissionChange) {
        if (permissionChange.to === 'granted') {
          localStorage.setItem('notified', 'true');
        }
      });
    });
  }

  ngOnDestroy() {
      this._unsub.unsubscribe();
  }

}


