import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FRESH_CHAT_SETTINGS } from './fresh-chat.const';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FreshChatChannelsResponse, FreshChatSettings, FreshChatUserSettings } from './fresh-chat.types';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FreshChatService {
  private _freshChatInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _freshChatWidgetContainer: ViewContainerRef;
  public freshChatWidgetInitStatus$ = this._freshChatInitialized.asObservable();

  private _freshChatOnLoad = new BehaviorSubject(false);
  public freshChatOnLoad$ = this._freshChatOnLoad.asObservable();

  private _freshChatOnClose = new BehaviorSubject(false);
  public freshChatOnClose$ = this._freshChatOnClose.asObservable();

  constructor(
    private _http: HttpClient,
    private _factoryResolver: ComponentFactoryResolver,
  ) {
  }

  public emitFreshChatOnLoadEvent() {
    this._freshChatOnLoad.next(true);
    // Подписка на закрытие окна виджета, чтобы скорректировать сбивающееся позиционирование иконки
    (window as any).fcWidget.on( 'widget:closed', () => {
      this._freshChatOnClose.next(true);
    });
  }

  /**
   * Возврат Observable при активности канала
   */
  public isActiveFreshChatChannel(): Observable<boolean> {
    return this._getFreshChatActiveChannels()
      .pipe(
        filter((response) => {
          return Boolean(response.channels
            .find(channel =>
              channel.tags.some(channelTag => channelTag === FRESH_CHAT_SETTINGS.channelTag),
            ),
          );
        }),
        switchMap(() => of(true)),
      );
  }

  /**
   * Установка дефолтных настроек виджета FreshChat'а
   * @param userSettings - параметры пользователя
   * @param onLoadHandler - обработчик события onLoad (виджет загружен)
   */
  public setFreshChatWidgetSettings(userSettings: FreshChatUserSettings = {}, onLoadHandler) {
    const fcSettings: FreshChatSettings = {
      token: FRESH_CHAT_SETTINGS.appToken,
      host: FRESH_CHAT_SETTINGS.widgetUrl,
      siteId: FRESH_CHAT_SETTINGS.siteId,
      tags: [FRESH_CHAT_SETTINGS.channelTag],
      config: {
        cssNames: FRESH_CHAT_SETTINGS.widgetCssClasses,
      },
    };

    if (onLoadHandler && typeof onLoadHandler === 'function') {
      fcSettings.onLoad = () => {
        onLoadHandler();
        this._clearFreshChatWidgetSettingsObject();
      };
    }

    (window as any).fcSettings = {...fcSettings, ...userSettings};
  }

  /**
   * Добавление динамического компонента FreshChat
   * @param component
   * @param container
   * @private
   */
  public addFreshChatDynamicComponent(component, container) {
    if (this._freshChatInitialized.getValue() === false) {
      this._freshChatWidgetContainer = container;
      const freshChatComponentFactory = this._factoryResolver.resolveComponentFactory(component);
      this._freshChatWidgetContainer.createComponent(freshChatComponentFactory);
      this._freshChatInitialized.next(true);
    }
  }

  /**
   * Установка внешнего id для пользователя FreshChat
   * @param id
   */
  public setFreshChatUserExternalId(id) {
    this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.setExternalId(id),
    );
  }

  /**
   * Установка имени для пользователя FreshChat
   * @param name
   */
  public setFreshChatUserName(name) {
    this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.user.setFirstName(name),
    );
  }

  /**
   * Установка email для пользователя FreshChat
   * @param email
   */
  public setFreshChatUserEmail(email) {
    this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.user.setEmail(email),
    );
  }

  /**
   * Установка дополнительных параметов пользователя
   * @param props
   */
  public setFreshChatUserProps(props) {
    this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.user.setProperties(props),
    );
  }

  /**
   * Установка локализации FreshChat
   * @param locale
   */
  public setFreshChatLocale(locale) {
    this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.user.setLocale(locale),
    );
  }

  /**
   * Сброс данных сессии пользователя FreshChat
   */
  public clearFreshChatUser() {
    const emptyCallback = () => {
    };
    return this._checkIfInitializedFreshChatWidget(
      () => (window as any).fcWidget.user.clear().then(emptyCallback, emptyCallback),
    );
  }

  /**
   * Инициализация виджета FreshChat
   */
  public initFreshChatWidget() {
    this._injectFreshChatWidgetScript('freshChatContainer', 'freshchat-js-sdk');
  }

  /**
   * Уничтожение виджета FreshChat
   */
  public destroyFreshChatWidget() {
    this._checkIfInitializedFreshChatWidget(() => {
      if (this._freshChatInitialized.getValue() === true) {
        this.clearFreshChatUser();
        (window as any).fcWidget.destroy();
        document.getElementById('freshChatContainer').removeChild(document.getElementById('freshchat-js-sdk'));
        document.getElementsByTagName('pk-fresh-chat')[0].remove();
        this._clearFreshChatWidgetObject();
        this._freshChatWidgetContainer.clear();
        this._freshChatInitialized.next(false);
      }
    });
  }

  /**
   * Удаление объекта window.fcSettings
   * @private
   */
  private _clearFreshChatWidgetSettingsObject() {
    delete (window as any).fcSettings;
  }

  /**
   * Удаление объекта window.fcWidget
   * @private
   */
  private _clearFreshChatWidgetObject() {
    delete (window as any).fcWidget;
  }


  /**
   * Инжект скрипта FreshChat
   * @param containerId
   * @param t
   * @private
   */
  private _injectFreshChatWidgetScript(containerId, t: string) {
    let e;
    if (!document.getElementById(t)) {
      return ((e = document.createElement('script')).id = t,
        e.async = !0,
        e.src = `${FRESH_CHAT_SETTINGS.widgetUrl}/js/widget.js`,
        document.getElementById(containerId).appendChild(e));
    }
  }

  /**
   * Получение списка активных каналов FreshChat
   * @private
   */
  private _getFreshChatActiveChannels(): Observable<FreshChatChannelsResponse> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');

    return this._http.get(FRESH_CHAT_SETTINGS.channelsUrl(),
      {
        headers,
      });
  }

  /**
   * Проверка на наличие window.fcWidget
   * @param func - функция-колбэк
   * @private
   */
  private _checkIfInitializedFreshChatWidget(func) {
    if (typeof(window) !== 'undefined' && (window as any).fcWidget && typeof func === 'function') {
      return func();
    }
  }
}
