<aside class="cookies-notice"
       id="cookiesNoticeContainer"
       #cookiesNoticeContainer
       [ngClass]="{'cookies-notice--visible': isShowCookiesNotice}">
  <div class="cookies-notice__text" [innerHTML]="'cookiesNotice.noticeText' | translate"></div>
  <div class="cookies-notice-buttons">
    <button class="cookies-notice-buttons__button cookies-notice-buttons__button--ok"
            (click)="okButtonClick()">{{'cookiesNotice.okButton' | translate}}</button>
    <button class="cookies-notice-buttons__button cookies-notice-buttons__button--details"
            [routerLink]="['/documents/privacyPolicy'] | localize">{{'cookiesNotice.details' | translate}}</button>
  </div>
</aside>
