import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit, PLATFORM_ID, Renderer2,
  ViewChild
} from '@angular/core';
import { CookieWrapperService } from '../../services/cookie-wrapper.service';
import { FreshChatService } from '../../modules/fresh-chat/fresh-chat.service';
import { Subject } from 'rxjs';
import { takeUntil, take, filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component ({
  selector: 'pk-cookies-notice',
  templateUrl: './cookies-notice.component.html',
  styleUrls: ['./cookies-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesNoticeComponent implements OnInit, OnDestroy {
  /**
   * Отображение сообщения
   */
  public isShowCookiesNotice: boolean;
  /**
   * Время задержки изменения флага, чтобы успела отработать css-анимация
   */
  private readonly _transitionTimeout = 300;

  private _unsubAll: Subject<any> = new Subject();

  @ViewChild('cookiesNoticeContainer', { read: ElementRef }) cookiesNoticeContainer: ElementRef;

  constructor(
    private _renderer: Renderer2,
    private _freshChat: FreshChatService,
    private _cookie: CookieWrapperService,
    private _cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  ngOnInit() {
      this._freshChat.freshChatOnLoad$
        .pipe(takeUntil(this._unsubAll))
        .subscribe(() => {
          this._setFreshChatPosition();
        });
      this._freshChat.freshChatOnClose$
        .pipe(
          filter(data => data),
          takeUntil(this._unsubAll)
        ).subscribe(() => {
        this._setFreshChatPosition(false);
      });
    // Добавил задержку для плавного появления блока
    if (isPlatformBrowser(this._platformId)) {
      const timeout = setTimeout(() => {
        this.isShowCookiesNotice = !this._cookie.get('agreedForUseCookies');
        this._setFreshChatPosition();
        clearTimeout(timeout);
        this._cdr.markForCheck();
      }, this._transitionTimeout);
    }
  }

  ngOnDestroy(): void {
    this._unsubAll.next();
    this._unsubAll.complete();
  }

  /**
   * Клик на кнопку согласия с политикой конфиденциальности
   */
  public okButtonClick () {
    const timeout = setTimeout(() => {
      this.isShowCookiesNotice = false;
      this._setFreshChatPosition();
      clearTimeout(timeout);
      this._cdr.markForCheck();
    }, this._transitionTimeout);
    this._cookie.put('agreedForUseCookies', 'true');
  }

  private _setFreshChatPosition(withTransition: boolean = true) {
    if (!this.cookiesNoticeContainer) {
      return;
    }
    if (isPlatformBrowser(this._platformId)) {
      const fcFrame = document.getElementById('fc_frame');
      const upBtn = document.getElementById('upButton');
      const containerEl = this.cookiesNoticeContainer?.nativeElement;
      if (Boolean(containerEl)) {
        if (Boolean(fcFrame)) {
          const fcBottom = this.isShowCookiesNotice ? containerEl.offsetHeight + 4 : 15;
          fcFrame.style.transition = `bottom ${withTransition ? 0.3 : 0}s`;
          fcFrame.style.bottom = `${fcBottom}px`;
        }
        if (Boolean(upBtn)) {
          let upBottom = this.isShowCookiesNotice ? containerEl.offsetHeight - 12 : 0;
          if (Boolean(fcFrame)) {
            upBottom += fcFrame.offsetHeight - 10;
          }
          this._renderer.setStyle(upBtn, 'transition', `margin-bottom ${withTransition ? 0.3 : 0}s`);
          this._renderer.setStyle(upBtn, 'margin-bottom', `${upBottom}px`);
        }
      }
    }
  }
}
