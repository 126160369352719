import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { GamePlatform, GamePlatformsInfoResponse } from '../../games/interfaces/game-platform';
import { map, publish, refCount, take } from 'rxjs/operators';
import { GameShipment } from '../../games/interfaces/game-shipment';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Game } from '../../games/interfaces/game';

@Injectable({
  providedIn: 'root'
})
export class PlatformsInfoService {

  private _gamePlatformsInfo = new BehaviorSubject<GamePlatform[]>([]);
  public gamePlatformsInfo$ = this._gamePlatformsInfo.asObservable().pipe(publish(), refCount());

  constructor(
    private _api: ApiService,
    private _translate: TranslateService
  ) {
    this._getGamePlatformsInfo().subscribe();
  }


  /**
   * Метод получения данных об одной платформе по коду
   * @param platformCode {string}
   * @returns {Observable<GamePlatform>}
   */
  public getGamePlatformInfoByCode(platformCode: string): Observable<GamePlatform> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) =>
        Boolean(platformsData) ? platformsData.find(pl => pl.code === platformCode) : null
      ));
  }

  /**
   * Метод получения данных обо всех платформах в которых доступна игра
   * @param game {GameShipment}
   * @returns {Observable<GamePlatform[]>}
   */
  public getPlatformsForGame(game: GameShipment): Observable<GamePlatform[]> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) => {
        if (!platformsData) {
          return [];
        }
        let platformsCodes = Boolean(game.platform_code) ? [game.platform_code] : [];
        if (Boolean(game.child_games)) {
          platformsCodes = [...platformsCodes, ...game.child_games.map(childGame => childGame.platform_code)];
        }
        // Список формируется так, чтобы порядок платформ сохранился,
        // т.е. платформа основной игры первая, дочерних в том же порядке, как пришли
        // важно для игр, которые у нас продаются.
        return platformsCodes.map(pCode => platformsData.find(p => p.code === pCode));
      }));
  }

  /**
   * Метод получения данных обо всех платформах в которых доступна игра из каталога
   * @param gamePlatforms
   * @returns {Observable<GamePlatform[]>}
   */
  public getPlatformsDataForCatalogGame(gamePlatforms: string[]): Observable<GamePlatform[]> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) => {
        if (!platformsData || gamePlatforms.length === 0) {
          return [];
        }
        return gamePlatforms.map(platformCode => platformsData.find(p => p.code === platformCode))
      }));
  }

  public getPlatformsInfoAsObject<T>(): Observable<T> {
    return this.gamePlatformsInfo$.pipe(
      map((platformsInfo) => {
        let platformsObject = {};
        platformsInfo.forEach((platform: GamePlatform) => {
          platformsObject = {
            ...platformsObject,
            [platform.code]: platform
          }
        })
        return platformsObject as T;
      })
    )
  }

  /**
   * Метод получения данных обо всех платформах на сервисе
   * @private
   * @returns {Observable<GamePlatform[]>}
   */
  private _getGamePlatformsInfo(): Observable<GamePlatform[]> {
    return this._api.post(
      `${environment.apiPathPrimary}gameplatforms/info`,
      {lang: this._translate.currentLang}
    ).pipe(
      take(1),
      map((data: GamePlatformsInfoResponse) => {
        if (Boolean(data) && Boolean(data.error) && data.error.code === 0) {
          this._gamePlatformsInfo.next(data.game_platforms_info);
          return data.game_platforms_info;
        } else {
          this._gamePlatformsInfo.next(null);
          return null;
        }
      })
    );
  }
}
