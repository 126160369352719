/**
 * CONSTANT. Tariff names
 * @type {Object}
 */
export const TARIFFNAME = {
  prices: {
    fun: '590',
    night: '390',
    uk: {
      fun: '15'
    }
  },
  subCode: {
    hour1FHD: 'Hour1_FullHD',
    hour5FHD: 'Hour5_FullHD',
    nightFHD: 'Night_FullHD',
    fun790FHD: 'fun790_FullHD',
    fun390FHD: 'Fun390_FullHD',
    ultraFDH60: 'Ultra_FullHD60',
    ultraFHD: 'Ultra_FullHD',
    WeekExpensive190HD: 'WeekExpensive190_HD',
    WeekUltra590: 'WeekUltra590',
    WeekFullHD290: 'WeekFullHD290',
    HD: {
      NightHD390: 'NightHD390',
      fun590HD: 'fun590_HD',
      WeekHD190: 'WeekHD190',
    },
    sharp790FHD: 'Sharp790_FullHD',
    newUltra: 'Tariff_Playkey',
    yearNewUltra: 'Tariff_Playkey_Year',
    unlim200: 'Tariff_Playkey_200_1990',
    unlimFull: 'Tariff_Playkey_Unlimited_2290'
  },
  customData: {
    badge: {
      new: 'New',
      economy: 'Economy'
    },
    quality: {
      ultra: 'Ultra',
      high: 'High',
      medium: 'Medium'
    },
    color: {
      blue: 'Blue',
      yellow: 'Yellow',
      white: 'White'
    },
    configuration: {
      all: 'All',
      part: 'Part'
    },
    resolution: {
      hd: 720
    }
  }
};


