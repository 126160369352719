import { Injectable } from '@angular/core';
import { SteamApiService } from './steam/services/steam-api.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UserService } from '../../../user/services/user.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { PLATFORMS_CONNECT_CODES } from './platforms-connect.types';
import { LangService } from '../../services/lang.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformsConnectService {

  constructor(
    private _steamApi: SteamApiService,
    private _api: ApiService,
    private _user: UserService,
    private _router: Router,
    private _lang: LangService,
  ) {
  }

  public goToPlatformSettingsFromAccount(platformCode: string, platformAccountId: string, target: string = '_self') {
    switch (platformCode) {
      case PLATFORMS_CONNECT_CODES.Steam:
      default:
        return this._steamApi.goToSteamAccountSettingFromAccount(platformAccountId, target)
    }
  }

  public bindPlatform(platformCode: string) {
    switch (platformCode) {
      case PLATFORMS_CONNECT_CODES.Steam:
      default:
        return this._steamApi.goToSteamOpenIdUrl(this._lang.getLang());
    }
  }

  /**
   * Привязка платформы к пользователю
   * @param platformCode - код платформы
   * @param extId - внешний идентификатор пользователя на стороне привязываемой платформы
   */
  public bindPlatformToUser(platformCode: string, extId: any): Observable<any> {
    const params = {
      lang: this._lang.getLang(),
      token: this._user.token,
      platform_code: platformCode,
      external_id: extId
    };

    return this._api.post(`${environment.apiPathPrimary}user/platform/bind`, params)
      .pipe(
        this._updateUserInfoAfterBind()
      );
  }

  public unbindPlatform(platformCode: string) {
    switch (platformCode) {
      case PLATFORMS_CONNECT_CODES.Steam:
      default:
        this._steamApi.unbindPlatformEvent();
        break;
    }

    return this._unbindPlatformFromUser(platformCode)
  }

  /**
   * Отвязка платформы от пользователя
   * @param platformCode - код платформы
   */
  private _unbindPlatformFromUser(platformCode: string): Observable<any> {
    const params = {
      lang: this._lang.getLang(),
      token: this._user.token,
      external_id: this._user.getCurrentUserInfo().external_game_platforms[PLATFORMS_CONNECT_CODES.Steam],
      platform_code: platformCode
    };
    return this._api.post(`${environment.apiPathPrimary}user/platform/unbind`, params)
      .pipe(
        switchMap((val) => {
          if (val?.error?.code === 0) {
            return this._user.getUserInfo().pipe(
              take(1)
            )
          }
          //TODO выводить модалку при ошибке
          of(null);
        })
      );
  }


  /**
   * Запуск лаунчера в клиенте
   * TODO Сделать отдельный сервис отвечающий за запуск игр, который позволит запускать игры сразу из каталога, в обход карточки.
   * После реализации сервиса убрать аналогичный код в карточке игры
   * В данный момент в метод прокидываются сервисы для избежания циклических DI при реализации. После выполнения туду потребность в пробросе сервисов отпадёт
   * @param platform - объект с данными платформы
   * @param isActiveTariff - активность тарифа
   * @param hasDebt - наличие задолженности
   * @param gameClient - GameClientService
   */
  public runPlatform(platform, isActiveTariff: boolean, hasDebt: boolean, gameClient): Observable<boolean | string> {
    const isBought: boolean = platform.isBought;
    const code: string = platform.code;
    const clearCode: string = code;


    if (this._user.token) {
      const params: any = {
        code,
        isBought,
        clearCode,
        mainGameCode: code,
      };
      params.defaultConfig = gameClient.getDefaultQualityCode(gameClient.getParsedGameConfig(platform.run), platform.code);
      gameClient.loadingGame = params.clearCode;

      if (isActiveTariff) {
        if (!hasDebt) {
          gameClient.playOrShowDownloadClick(params, platform);
          return of(false);
        } else {
          gameClient.loadingGame = null;
          return of(false);
        }
      }
      return of(`/steps/payment`)

    } else {
      return of(false);
    }
  }


  /**
   * Обновление userInfo после привязки/отвязки платформы
   * @private
   */
  private _updateUserInfoAfterBind() {
    return switchMap((val: any) => {
      if (val?.error?.code === 0) {
        return this._user.getUserInfo().pipe(
          take(1)
        )
      }
      of(null);
    })
  }
}
