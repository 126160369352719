<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>
  <div class="modalContent">
    <div class="modalContentInner">
      <h1 id="success-key__title" class="success-key__title">{{'keys.congratulations' | translate}}</h1>
      <h2 id="success-key__description" class="success-key__description"
          [innerHTML]="'preorder.text' | translate | replace: '%game%': type.title : 'bold' ">
      </h2>
      <button
        id="modalButtonOk"
        class="modalButton modalButton--veryBig"
        (click)="closeModal()">
        {{'close' | translate}}
      </button>
  </div>
  </div>
</div>
