import { Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { LANGUAGE } from '../constant/LANG';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class HrefLangService {
  private _renderer: Renderer2;
  public langHrefElements;

  constructor(
    @Inject(DOCUMENT) private _doc: any,
    private _router: Router,
    private _rendererFactory: RendererFactory2
  ) {
  }

  public initHrefLang() {
    this._renderer = this._rendererFactory.createRenderer(null, null);

    this.langHrefElements = {
      ...this.langHrefElements,
      [LANGUAGE.RU]: this._doc.querySelector('[hreflang=ru]'),
      [LANGUAGE.EN]: this._doc.querySelector('[hreflang=en]'),
      default: this._doc.querySelector('[hreflang=x-default]'),
    }

    this.updateHref();
  }

  public updateHref() {
    const [locationPathLang, ...locationPathRest] =
      this._doc.location.pathname.split('/').filter(x => Boolean(x));

    Object.entries(this.langHrefElements)
      .forEach(([hrefLang, el]) => {
        let langPathNameArray = [locationPathLang, ...locationPathRest];
        if (locationPathLang !== hrefLang) {
          const langName = hrefLang === 'default' ? LANGUAGE.EN : hrefLang;
          langPathNameArray = [langName, ...locationPathRest];
        }
        const hrefString = `${this._doc.location.origin}/${langPathNameArray.join('/')}${this._doc.location.search}`;

        this._renderer.setAttribute(el, 'href', hrefString)
      });
  }

}
