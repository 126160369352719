
<!-- Список привязанных карт -->
<div id="{{ card.id }}"
     class="account-card__container"
     [ngClass]="{ 'account-card__container--active': card.is_main === true }"
     *ngFor="let card of userPs">
  <!-- Иконка -->
  <div class="account-card__icon">
    <div class="account-card__iconImage" [ngStyle]="{ 'background-image': 'url(' + card.payment_system.image_link + ')' }"></div>
  </div>
  <!-- Номер карты (небалансовая пс) -->
  <div id="account-card__number" class="account-card__number" *ngIf="card.payment_system.is_balanced === false">
    {{ card.info | secretNumber: card.payment_system.code }}
  </div>

  <!-- балансовая пс -->
  <div class="account-card__number" *ngIf="card.payment_system.is_balanced === true">
    <a href="#" (click)="openPktInfo(card,$event)" *ngIf="card.payment_system.current_balance === 0">{{ card.payment_system.name }}</a>
    <a href="#" (click)="openPktInfo(card,$event)" *ngIf="card.payment_system.current_balance > 0">
      {{ 'account.ps-balance' | translate}}:
      {{ getViewBalance(card.payment_system.current_balance) }}
      {{ card.payment_system.currency.sign }}
    </a>
  </div>

  <div id="accountCardBreak" class="account-card__break"></div>
  <!-- Управление картами -->
  <div class="account-card__manage"
       [ngClass]="{ 'account-card__manage--active': card.is_main === true }">
        <span *ngIf="card.is_main === true">
            {{'accountSettings.main' | translate}}
        </span>
    <span *ngIf="card.is_main === false" (click)="selectCard(card.id);">
            {{'accountSettings.makeMain' | translate}}
        </span>
  </div>
  <!-- Удаление -->
  <div *ngIf="needDelete === true && card.payment_system.is_balanced === false">
    <div
      id="account-card__delete"
      class="account-card__delete"
      (click)="showConfirm(card.id)"></div>
  </div>
</div>

<!-- Подтверждение удаление карты -->
<pk-sure-unbind
  *ngIf="isConfirm"
  [cardIdForDel]="currentCard"
  (CloseConf)="closeConfirm()">
</pk-sure-unbind>

<!-- pkt info -->
<pk-pkt-balance
  *ngIf="openPKT"
  [card] = "currentCard"
  (Close)="closepktInfo()">
</pk-pkt-balance>


