import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decOfNum'
})
export class DecOfNumPipe implements PipeTransform {
  /**
   * Возвращает окончание для множественного числа слова на основании числа и массива окончаний
   * @param  number Integer - Число на основе которого нужно сформировать окончание
   * @param  titles Array<String> - Массив слов или окончаний для чисел (1, 4, 5),
   *         например ['яблоко', 'яблока', 'яблок']
   * @param  justEnd Boolean - необязательный, при заничении true вернёт в строке только окончание, без числа
   * @return String
   */
  transform(data: number, ends: string[], justEnd?: boolean ): string {
    const result = Math.round(data);
    const cases = [2, 0, 1, 1, 1, 2];
    const end = ends[ (result % 100 > 4 && result % 100 < 20) ? 2 : cases[(result % 10 < 5) ? result % 10 : 5] ];
    return `${!!justEnd ? '' : result + ' '}${end}`;
  }

}
