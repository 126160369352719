import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import { UserService } from '../../user/services/user.service';
import {
  FRESH_WORKS_LOCALE_LABELS,
  FRESH_WORKS_WIDGET_ID,
  FRESH_WORKS_WIDGET_URL
} from '../constant/fresh-works.const';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(
    private _user: UserService,
    private _utils: UtilsService,
    private _translate: TranslateService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this._initFreshWorksWidget();
  }

  public openWidget() {
    SupportService._checkIfInitializedFreshWorksWidget(() => {
      (window as any).FreshworksWidget('open');
    });
  }

  public closeWidget() {
    SupportService._checkIfInitializedFreshWorksWidget(() => {
      (window as any).FreshworksWidget('close');
    });
  }

  private _initFreshWorksWidget() {
    if (isPlatformServer(this._platformId)) {
      return;
    }

    (window as any).fwSettings = {
      widget_id: FRESH_WORKS_WIDGET_ID,
      locale: this._translate.currentLang,
    };

    if (typeof (window as any).FreshworksWidget != 'function') {
      const n = function() { n.q.push(arguments); };
      n.q=[];
      (window as any).FreshworksWidget = n;
    }
    this._utils.loadScript(
      FRESH_WORKS_WIDGET_URL,
      {type: 'text/javascript', async: true, defer: true}
    )
      .pipe(take(1))
      .subscribe(() => {
        this._freshWorksWidgetOnLoadHandler();
      });
  }

  private _freshWorksWidgetOnLoadHandler() {
    SupportService._checkIfInitializedFreshWorksWidget(() => {
      (window as any).FreshworksWidget('hide', 'launcher');
      (window as any).FreshworksWidget('setLabels', FRESH_WORKS_LOCALE_LABELS);
    });

    this._user.userInfo$
      .pipe(distinctUntilChanged())
      .subscribe((userInfo) => {
        SupportService._checkIfInitializedFreshWorksWidget(() => {
          const userEmail = userInfo?.email || '';
          const userId = userInfo?.id || '';
          (window as any).FreshworksWidget('prefill', 'ticketForm', {
            email: userEmail,
            custom_fields: {
              cf_id: `${userId}`
            },
          });
        });
      });
  }

  /**
   * Проверка на наличие window.FreshworksWidget
   * @param func - функция-колбэк
   * @private
   */
  private static _checkIfInitializedFreshWorksWidget(func) {
    if (typeof(window) !== 'undefined' && (window as any).FreshworksWidget && typeof func === 'function') {
      return func();
    }
  }
}
