export const CONSTANT = {
  currency: {
    pound: 'pound',
    ruble: 'ruble',
    dollar: 'dollar',
    euro: 'euro',
  },
  time: {
    infinity: 9999,
    day: 1,
    intro: 90,
    advert: 30,
    banner: 2,
    experiment: 30,
    month: 30,
    year: 365,
    timeout: 300000,
    inMilliseconds: {
      second: 1000,
      minute: 60000,
      day: 86400,
    },
    inSeconds: {
      minute: 60,
    },
  },
  subscription: {
    type: {
      time: 'Time',
      regular: 'RegularAllTime',
      regularDay: 'RegularAllDay',
      custom: 'Custom',
      regularPartOfDay: 'RegularPartOfDay',
    },
    typeTariff: {
      minute: 'MINUTE_BASED',
      deposit: 'TIME_BASED',
      regular: 'REGULAR'
    },
    parameter: {
      defaultResolution: 720,
    },
    paymentStatus: {
      process: 'Processing',
    },
    codeStatus: {
      extendedActive: 'ExtendedActive',
      suspend: 'Suspend',
      frozen: 'Frozen',
      active: 'Active',
    },
    namePartner: {
      playkey: 'Playkey',
    },
    tariff: {
      codeTariff: {
        training: 'Training',
        promo: 'PromoLimit',
        promoTime: 'PromoLimitTime'
      },
    },
    method: {
      activate: 'activate',
      change: 'change',
      bindPrefix: 'andbind',
    },
    configuration: {
      fullhd: 'FullHD',
    },
  },
  mailgun: {
    pubkey: 'pubkey-68fb5dbd26eb7948206577f986091039',
  },
  payment: {
    paymentSystem: {
      alfa: 'AlfaBank',
      qiwi: 'Qiwi',
      webmoney: 'WebMoney',
      mts: 'MTS',
      xsolla: 'XSolla',
      mobile: 'Mobile',
      cloudpayments: 'CloudPayments',
    },
    successBindMethodName: {
      buy: 'buy',
      pay: 'pay',
      preorder: 'preorder',
      buyPacket: 'buyPacket',
      bind: 'bind',
      specialOffer: 'specialoffer',
      bundle: 'bundle',
    },
    product: {
      specialOffer: 'specialOffer',
      specialBundle: 'specialBundle',
    },
    constructor: {
      buttonType: {
        change: 'change',
        changeNow: 'change-now',
      },
    },
    status: {
      success: 'success',
      awaiting: 'awaiting',
      failed: 'failed',
    },
  },
  params: {
    reloadTrue: {
      needReload: true,
      reload: true,
    },
    reloadFalse: {
      needReload: false,
      reload: false,
    },
  },
  auth: {
    simplePasswords: [
      '123456', '123456789', 'qwerty', '111111', '1234567', '666666', '12345678',
      '7777777', '123321', '654321', '1234567890', '123123', '555555', 'vkontakte',
      'gfhjkm', '159753', '777777', 'TempPassWord', 'qazwsx', '1q2w3e', '112233',
      '121212', 'qwertyuiop', 'qq18ww899', '987654321', 'zxcvbn', 'zxcvbnm',
      '999999', 'samsung', 'ghbdtn', '1q2w3e4r', '1111111', '123654', '159357',
      '131313', 'qazwsxedc', '123qwe', '222222', 'asdfgh', '333333', '9379992',
      'asdfghjkl', '4815162342', '12344321', 'любовь', '88888888', '11111111',
      'knopka', 'пароль', '789456', 'qwertyu', '1q2w3e4r5t', 'iloveyou', 'vfhbyf',
      'marina', 'password', 'qweasdzxc', '987654', 'yfnfif', 'cjkysirj', 'nikita',
      'fylhtq', 'q1w2e3', 'stalker', '1111111111', 'q1w2e3r4', 'nastya', '147258369',
      '147258', 'fyfcnfcbz', '1234554321', '1qaz2wsx', 'andrey', '111222', '147852',
      'genius', 'sergey', '7654321', '232323', '123789', 'fktrcfylh', 'spartak',
      'thomas', 'killer', 'master', 'aaaaaa', 'monkey', 'daniel', 'asdasd',
      'e10adc3949ba59abbe56e057f20f883e', 'changeme', 'computer', 'jessica', 'letmein',
      'mirage', 'loulou', 'superman', 'shadow', 'admin123', 'secret', 'administrator',
      'sophie', 'kikugalanetroot', 'doudou', 'liverpool', 'sunshine', 'charlie',
      'parola', '100827092', 'michael', 'andrew', 'password1', 'fuckyou', 'matrix',
      'cjmasterinf', 'internet', 'hallo123', 'eminem', 'gewinner', 'pokemon', 'abcd1234',
      'ngockhoa', 'martin', 'sandra', 'hejsan', 'george', 'qweqwe', 'lollipop',
      'lovers', 'q1q1q1', 'tecktonik', 'naruto', 'password12', 'password123',
      'password1234', 'password12345', 'password123456', 'password1234567',
      'password12345678', 'password123456789', '000000', 'maximius', '123abc',
      'baseball1', 'football1', 'soccer', 'princess', 'slipknot', '666999',
      '12341234', '1234321', '135790', '159951', '212121', 'zzzzzz', '121314',
      '134679', '142536', '19921992', '753951', '1111114', '124578', '19951995',
      '258456', 'qwaszx', 'zaqwsx',
    ],
    captchaUrl: 'https://www.google.com/recaptcha/api.js' +
      '?onload=vcRecaptchaApiLoaded' +
      '&render=explicit' +
      '&hl=',
  },
  confirm: {
    mode: {
      default: 'default',
      protection: 'protection',
      trial: 'trial',
      done: 'done',
      expires: 'expires',
      none: 'none',
      error: 'error',
    },
  },
  banner: {
    type: {
      carousel: 'Carousel',
      fullscreen: 'Fullscreen',
    },
    mode: {
      blueFlag: 'BlueFlag',
      game: 'Game',
      newFlag: 'NewFlag',
      green: 'Green',
      blue: 'Blue',
    },
  },
  cookie: {
    catalogView: 'catalogView',
  },
  intro: {
    WorldOfTanks: 'intro_wot',
    Warthunder: 'intro_warthunder',
    doom: 'intro_doom',
    witcher3: 'intro_witcher3',
    params: {
      doom: 'doom',
      witcher3: 'witcher3',
    },
    GTA5: 'intro_gta5',
    Witcher3demo: 'intro_witcher3',
    fallout4: 'intro_fallout4',
    division: 'intro_division',
    FarCryPrimal: 'intro_FarCryPrimal',
    rise_of_tomb_raider: 'intro_riseOfTombRaider',
    doom_demo: 'intro_doom',
  },
  priceType: {
    catalogVertical: 'catalogVertical',
    catalogHorizontal: 'catalogHorizontal',
    card: 'card',
  },
  catalogue: {
    maxCountGamesAtOnePage: {
      vertical: 9,
      horizontal: 5,
    },
    stepPagingGames: {
      vertical: 9,
      horizontal: 5,
    },
    view: {
      horizontal: 'horizontal',
      vertical: 'vertical',
    },
    filter: {
      all: 'all',
      clear: 'clear',
      type: {
        genre: 'genre',
        category: 'category',
      },
    },
    searchElCount: 7,
  },
  main: {
    button: {
      sub: 'sub',
      overlimit: 'overlimit',
    },
  },
  userVoice: 'https://playkey.uservoice.com/forums/584323-Идеи?sso=',
  selectedQuality: {
    ultra: 'Ultra',
    high: 'High',
    medium: 'Medium',
  },
  tariffCodesDefined: {
    playkey: 'Tariff_Playkey',
    playkey1990: 'Tariff_Playkey_200_1990',
    playkeyUnlimited2290: 'Tariff_Playkey_Unlimited_2290',
  },
  kohortTypes: {
    macNewUsers: 'MAC_NEW_USERS',
    personalOffer: 'PERSONAL_OFFER',
    landingRegistrationDate: 'LANDING_REGISTRATION_DATE',
    kohortTypeExpWinAA: 'LANDING_EXP_AA',
    regDate: 'REGISTRATION_DATE',
  },
  referral: {
    ruleType: {
      buyDeposit: 'BuyDeposit',
      buyTariff: 'BuyTariff'
    },
    rewardType: {
      playMinutes: 'PlayMinutes',
      gameCode: 'GameCode'
    }
  },
  missionType: {
    referral: 'ReferralMissionProgram',
    referrer: 'ReferrerMissionProgram',
    buyTariff: 'BuyTariff'
  },
  partners: {
    codes: {
      ttk: 'TTK'
    }
  }
};



