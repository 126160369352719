<div class="footer-social-links">
  <a href="//sk.ru/net/1121496/"
     class="footer-social-links__item"
     rel="noopener nofollow"
     target="_blank"
     [title]="'footer.sk' | translate">
    <img [src]="'footer.skImg' | translate"
         [alt]="'footer.sk' | translate">
  </a>
  <a class="footer-social-links__item"
     href="#"
     rel="noopener nofollow"
     (click)="openBlog($event)"
     [title]="'footer.blog' | translate">
    <img class="img-blog"
         [src]="'footer.blogImg' | translate"
         [alt]="'footer.blog' | translate">
  </a>
  <ng-container *ngFor="let icon of socialIcons">
    <a
      *ngIf="icon.region === 'any' || icon.region === user.getDetectedRegion() || (icon.region === 'no-CIS' && !user.isCis())"
      class="footer-social-links__item footer-social-links__item--icon"
      href="{{ icon.title === 'Youtube' ? (icon.aUrl | translate) : icon.aUrl}}"
      rel="noopener nofollow"
      title="{{icon.title}}"
      target="_blank">
      <img src="{{icon.imgUrl}}"
           alt="{{icon.title}}">
    </a>
  </ng-container>
</div>
<footer class="footer" *ngIf="footer.isVisible">
  <div class="footer__row">
    <section class="footer-menu-container">
      <ng-container *ngIf="user.isCis(); then footerMenuItemsCIS else footerMenuItemsNotCIS"></ng-container>
    </section>
  </div>
</footer>

<ng-template #footerMenuItemsNotCIS>
  <nav class="footer-menu">
    <ng-container [ngTemplateOutlet]="footerLogo"></ng-container>
    <a class="footer-menu__item"
       (click)="openTermsOfUse()">
      {{ 'footer.usage' | translate }}
    </a>
    <a class="footer-menu__item"
       (click)="openPrivacyPolicyNotCIS()">
      {{ 'footer.privacyPolicy' | translate }}
    </a>
    <a class="footer-menu__item"
       (click)="openPrivacyPolicyForChildren()">
      {{ 'footer.privacyPolicyForChildren' | translate }}
    </a>
  </nav>
  <nav class="footer-menu">
    <ng-container [ngTemplateOutlet]="footerCopyright"></ng-container>
    <a class="footer-menu__item"
       (click)="openPrivacyPolicyCookie()">
      {{ 'footer.privacyPolicyCookie' | translate }}
    </a>
    <a class="footer-menu__item"
       (click)="openImprint()">
      {{ 'footer.requisites' | translate }}
    </a>
  </nav>
</ng-template>
<ng-template #footerMenuItemsCIS>
  <nav class="footer-menu">
    <ng-container [ngTemplateOutlet]="footerLogo"></ng-container>
    <a class="footer-menu__item"
       (click)="openTermsOfUse()">
      {{ 'footer.usage' | translate }}
    </a>
    <a class="footer-menu__item"
       (click)="openPrivacyPolicyCIS()">
      {{ 'footer.privacyPolicy' | translate }}
    </a>
    <a class="footer-menu__item"
       [routerLink]="['/documents/infoRightHolder'] | localize">
      {{ 'footer.infoRightHolder' | translate }}
    </a>
  </nav>
  <nav class="footer-menu">
    <ng-container [ngTemplateOutlet]="footerCopyright"></ng-container>
    <a class="footer-menu__item"
       (click)="openPromo()">
      {{ 'footer.promoCodes' | translate }}
    </a>
    <a class="footer-menu__item"
       (click)="openImprint()">
      {{ 'footer.requisites' | translate }}
    </a>
  </nav>
</ng-template>
<ng-template #footerLogo>
  <div class="footer-menu__item footer-logo">
    <img class="footer-logo__icon" src="//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/logo.svg" alt="PLAYKEY"/>
    <img class="footer-logo__text" src="//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/logo-text.svg" alt="PLAYKEY.NET"/>
  </div>
</ng-template>
<ng-template #footerCopyright>
  <div class="footer-menu__item footer-copy">
    <span>© {{copyrightYear}} {{ 'footer.vk' | translate }}</span>
  </div>
</ng-template>
