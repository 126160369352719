import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorResponseComponent } from './components/error-pages/error-response/error-response.component';
import { ErrorUnavailableComponent } from './components/error-pages/error-unavailable/error-unavailable.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings
} from 'ng-recaptcha';
import { SetEmailComponent} from './components/set-email/set-email.component';
import { DebtComponent } from './components/debt/debt.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ResumeSubscribeComponent } from './components/resume-subscribe/resume-subscribe.component';
import { ErrorNotFoundComponent } from './components/error-pages/error-not-found/error-not-found.component';
import { ManageCardsComponent } from './components/manage-cards/manage-cards.component';
import { PktBalanceComponent } from './components/pkt-balance/pkt-balance.component';
import { SureUnbindComponent } from './components/sure-unbind/sure-unbind.component';
import { CookiesNoticeComponent } from './components/cookies-notice/cookies-notice.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TariffUpdateInformationComponent } from './components/tariff-update-information/tariff-update-information.component';
import { PaymentLoaderComponent } from './components/payment-loader/payment-loader.component';
import { ReferralRewardBageComponent } from './components/referral-reward-bage/referral-reward-bage.component';
import { SocialShareButtonsComponent } from './components/social-share-buttons/social-share-buttons.component';
import { DropdownMenuComponent } from './components/menus/dropdown-menu/dropdown-menu.component';
import { DropdownMenuItemComponent } from './components/menus/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import { SharedPipesModule } from './modules/shared-pipes/shared-pipes.module';
import { LoaderModule } from './modules/loader/loader.module';
import { PriceModule } from './modules/price/price.module';
import { FreshChatModule } from './modules/fresh-chat/fresh-chat.module';
import { InnerPageWrapperComponent } from './components/inner-page-wrapper/inner-page-wrapper.component';
import { InnerPageFixedMenuComponent } from './components/menus/inner-page-fixed-menu/inner-page-fixed-menu.component';
import { InnerPageFixedMenuItemComponent } from './components/menus/inner-page-fixed-menu/inner-page-fixed-menu-item/inner-page-fixed-menu-item.component';
import { SearchFormComponent } from '../search/components/search-form/search-form.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { TonDetailsComponent } from './components/ton-details/ton-details.component';
import { BadgeComponent } from './components/badge/badge.component';
import { InlineSvgComponent } from './components/inline-svg/inline-svg.component';
import { TooltipModule } from './modules/popups/tooltip/tooltip.module';
import { TipModule } from './modules/popups/tip/tip.module';
import { HeaderRolledPanelModule } from './modules/header-rolled-panel/header-rolled-panel.module';
import { UpButtonComponent } from './components/up-button/up-button.component';
import { OnClickGTMEventDirective } from './directives/on-click-gtm-event/on-click-gtm-event.directive';
import { environment } from '../../environments/environment';
import { LangService } from './services/lang.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TextMaskModule,
    SharedPipesModule,
    LoaderModule,
    PriceModule,
    FreshChatModule,
    TooltipModule,
    TipModule,
    HeaderRolledPanelModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    FooterComponent,
    ErrorResponseComponent,
    ErrorUnavailableComponent,
    SetEmailComponent,
    DebtComponent,
    ResumeSubscribeComponent,
    ErrorNotFoundComponent,
    ManageCardsComponent,
    PktBalanceComponent,
    SureUnbindComponent,
    CookiesNoticeComponent,
    ChangePasswordComponent,
    TariffUpdateInformationComponent,
    PaymentLoaderComponent,
    ReferralRewardBageComponent,
    SocialShareButtonsComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    InnerPageWrapperComponent,
    InnerPageFixedMenuComponent,
    InnerPageFixedMenuItemComponent,
    SearchFormComponent,
    BreadcrumbsComponent,
    TonDetailsComponent,
    BadgeComponent,
    InlineSvgComponent,
    UpButtonComponent,
    OnClickGTMEventDirective,
  ],
  exports: [
    PriceModule,
    SharedPipesModule,
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    LoaderModule,
    ErrorResponseComponent,
    ErrorUnavailableComponent,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SetEmailComponent,
    DebtComponent,
    TextMaskModule,
    ResumeSubscribeComponent,
    ErrorNotFoundComponent,
    ManageCardsComponent,
    PktBalanceComponent,
    SureUnbindComponent,
    CookiesNoticeComponent,
    ChangePasswordComponent,
    PaymentLoaderComponent,
    ReferralRewardBageComponent,
    SocialShareButtonsComponent,
    FreshChatModule,
    InnerPageWrapperComponent,
    InnerPageFixedMenuComponent,
    InnerPageFixedMenuItemComponent,
    SearchFormComponent,
    BreadcrumbsComponent,
    BadgeComponent,
    InlineSvgComponent,
    UpButtonComponent,
    OnClickGTMEventDirective,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.captchaPublicKey,
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (lang: LangService) => lang.getLang(),
      deps: [LangService],
    },
  ],
})

export class SharedModule {

}
