import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../services/api.service';
import { SteamApiData } from '../interfaces/steam-api-data';
import { AnalyticService } from '../../../../services/analytic.service';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SteamApiService {
  // список данных пользователя из steam-api
  private _steamApiData: BehaviorSubject<SteamApiData> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _api: ApiService,
    private _analytic: AnalyticService
  ) {}

  public prepareSteamOpenIdUrl(currentLang) {
    const returnUrl = `${environment.platformsBindDomain}/${currentLang}/users/bind-platform/steam/bind-result`;
    return `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.ns.sreg=http://openid.net/extensions/sreg/1.1&openid.sreg.optional=nickname,email,fullname,dob,gender,postcode,country,language,timezone&openid.ns.ax=http://openid.net/srv/ax/1.0&openid.ax.mode=fetch_request&openid.ax.type.fullname=http://axschema.org/namePerson&openid.ax.type.firstname=http://axschema.org/namePerson/first&openid.ax.type.lastname=http://axschema.org/namePerson/last&openid.ax.type.email=http://axschema.org/contact/email&openid.ax.required=fullname,firstname,lastname,email&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.return_to=${returnUrl}&openid.realm=${returnUrl}`
  }

  public goToSteamOpenIdUrl(currentLang: string) {
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'user',
      eventAction: `steam clicked connect`,
    });
    return window.open(this.prepareSteamOpenIdUrl(currentLang), '_blank');
  }

  public prepareSteamProfileUrl(steamId: string) {
    return `https://steamcommunity.com/profiles/${steamId}/edit/settings`;
  }

  public goToSteamAccountSettingFromAccount(steamId: string, target: string) {
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'user',
      eventAction: `steam clicked more info`,
    });
    return window.open(this.prepareSteamProfileUrl(steamId), target);
  }

  public unbindPlatformEvent() {
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'user',
      eventAction: `steam clicked detach`,
    });
  }

  /**
   * Получение текущих значений данных steamApiData
   * @private
   */
  private _getSteamApiData() {
    return this._steamApiData.getValue();
  }

}
