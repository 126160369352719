import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceComponent } from './price.component';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { LoaderModule } from '../loader/loader.module';



@NgModule({
  declarations: [
    PriceComponent
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    LoaderModule
  ],
  exports: [
    PriceComponent
  ]
})
export class PriceModule { }
