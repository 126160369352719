import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  /**
   * Замещает одну часть строки на другую
   * @param {string} input
   * @param {string} pattern
   * @param {string} replacement
   * @returns {string}
   */
  transform (input: string, pattern: string, replacement: string): string {
    if (!(input) || !pattern || !replacement) {
      return input;
    }
    return input.replace(pattern, replacement);
  }

}
