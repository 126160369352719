import { Injectable } from '@angular/core';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { ApiService } from '../../shared/services/api.service';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription, ISubscriptionResponse } from '../interfaces/subscription';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ConstantService } from '../../shared/services/constant.service';
import { DynamicService } from '../../modal/service/dynamic.service';
import { IErrorResponse } from '../../interfaces/error';
import { ISubLoader } from '../interfaces/ISubLoader';
import { UtilsService } from '../../shared/services/utils.service';
import { ILeftAndLimitParams } from '../interfaces/ILeftAndLimitParams';
import { AnalyticService } from '../../shared/services/analytic.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private baseUrl = `${environment.apiPathPrimary}user/subscriptions`;

  private subscriptions = new BehaviorSubject<ISubscription[]>([]);

  private regularSubscription = new BehaviorSubject<ISubscription>(null);

  public data$: Observable<ISubscription[]> = this.subscriptions.asObservable();

  public regular$: Observable<ISubscription> = this.regularSubscription.asObservable();

  // Observable следящий за состоянием isLoad при остановне и возобновлении подписки(метод изменения состояния описан ниже)
  private _subIsLoad = new Subject<ISubLoader>();

  public subIsLoad: Observable<ISubLoader> = this._subIsLoad.asObservable();

  // Блок Констаны
  private _constTime: any;

  public isTariffLimitEnded = false;

  readonly _defaultPeriodInSeconds = 1800;

  constructor(
    private _api: ApiService,
    private _user: UserService,
    private _analytic: AnalyticService,
    private _translate: TranslateService,
    private _constant: ConstantService,
    private _cookie: CookieWrapperService,
    private _dynamic: DynamicService,
    private _const: ConstantService,
    private _util: UtilsService,
  ) {
    this.get().subscribe();
    this._constTime = this._const.get('CONSTANT.subscription.type.time');
  }

  get(): Observable<ISubscription[]> {
    return this._user.checkAuth()
      .pipe(
        distinctUntilChanged(),
        mergeMap(data => {
          return data ? this._getSubscriptions() : of(null);
        }),
        map((data: ISubscriptionResponse) => {
          if (!!data && !data.error.code) {
            // Инициализация дополнительных параметров
            data.subscriptions.forEach((subscription, i) => {
              // TODO заполняем обьект параметрами ..PS перенесено с 1.5 проэкта (обьекты сравнивал после функции нет никакой разницы)
              // TODO  возможны варианты когда бэк не присылает все поля и тогда кастомно приходиться их заполнять
              if (i === 0) {
                subscription = this._initAdditionalParams(subscription);
              }
              subscription.options = {};
              subscription.parameters.forEach(x => subscription.options[x.name] = x.value);
              subscription.is_full_hd = !!subscription.options.RESOLUTION
                && +subscription.options.RESOLUTION > 720;
            });
            this._setRegular(data.subscriptions);
            this.subscriptions.next(data.subscriptions);
            return data.subscriptions;
          } else {
            this.clearSubscriptions();
            return [];
          }
        }),
      );
  }

  /**
   * Метод установки состояния Лоадера
   * {type: string state: boolean }
   */
  public setLoaderState(obj): void {
    this._subIsLoad.next(obj);
  }

  /**
   * Метод проверяет тип подписки пользователя на соответствие временной подписке
   */
  isTimeSubscription(type: string): boolean {
    const timeType = this._constant.get('CONSTANT.subscription.type.time');
    return type === timeType;
  }

  private _setRegular(subscriptions: ISubscription[]): void {
    const regularSub = this.getRegularSubscription(subscriptions);
    this.regularSubscription.next(regularSub);
  }

  public getRegularSubscription(subscriptions: ISubscription[]): ISubscription {
    if (subscriptions.length === 0) {
      return undefined;
    } else {
      const resSub = subscriptions.filter((subscription) => ['RegularAllDay', 'RegularPartOfDay'].indexOf(subscription.type) > -1);
      return (resSub && resSub.length) ? resSub[0] : undefined;
    }
  }

  private _getSubscriptions(): Observable<ISubscriptionResponse> {
    return this._api.post(`${this.baseUrl}`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      api_version: 4
    });
  }

  // Метод остановки подписки
  public stopSubscription(id): Observable<any> {
    return this.suspend(id).pipe(
      mergeMap(data => {
          if (data.error.code === 0) {
            this._analytic.dataLayerPush({
              event: 'event',
              eventCategory: 'subscriptions',
              eventAction: 'suspend success',
            });
            return this.get();
          } else {
            // если произошла ошибка
            const errMsg = data.error.message;
            this._dynamic.addDynamicComponent({type: 'error', data: {errMsg: errMsg}});
            return of(null);
          }
        }
      ));
  }

  public cancelSubscription(): Observable<IErrorResponse> {
    const url = `${this.baseUrl}/close`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token
    };
    return this._api.post(url, params);
  }

  // Метод отписки пользователя
  private suspend(subscriptionId?: number): Observable<any> {
    const url = `${this.baseUrl}/suspend`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      subscription_id: subscriptionId,
      is_change_now: true
    };
    return this._api.post(url, params);
  }

  // Метод возобновления регулярной подписки
  public resumeRegular(subscriptionId?: any): Observable<IErrorResponse> {
    const url = `${this.baseUrl}/resumeregular`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      subscription_id: subscriptionId.id,
    };
    return this._api.post(url, params).pipe(
      map((data: any) => {
        return data;
      }));
  }

  // метод активации прмокода
  public activatePromocode(promoCode: string): Observable<any> {
    const url = `${this.baseUrl}/extend`;
    return this._api.post(url,
      {
        lang: this._translate.currentLang,
        token: this._user.token,
        code: promoCode,
      });
  }

  private _getLeftAndLimit(sub: ISubscription): ILeftAndLimitParams {
    const result: ILeftAndLimitParams = {} as ILeftAndLimitParams;
    if (sub.type === this._constTime) {
      if (sub.time_left_play_in_seconds > (this._util.minutesToSeconds(sub.tariff_duration_minutes))) {
        result.left = sub.time_left_play_in_seconds;
        result.limit = sub.time_left_play_in_seconds;
        this.isTariffLimitEnded = true;
      } else {
        result.left = sub.time_left_play_in_seconds;
        result.limit = this._util.minutesToSeconds(sub.tariff_duration_minutes);
      }
    } else {
      result.left = sub.played_time_in_seconds;
      result.limit = sub.limit_time_in_seconds;
    }
    if (sub.over_limit_time_in_seconds) {
      result.limit += sub.over_limit_time_in_seconds;
      this.isTariffLimitEnded = true;
    }
    return result;
  }

  private _getLeftTime(sub: ISubscription): number {
    const data: ILeftAndLimitParams = this._getLeftAndLimit(sub);
    const left: number = data.left;
    const limit: number = data.limit;
    if (!left && left !== 0) {
      return 0;
    }
    if (left > limit) {
      return 0;
    }
    if (left < 0) {
      return 0;
    }
    if (sub.type === this._constTime) {
      return this._util.secondsToMinutes(left);
    }
    return this._util.secondsToMinutes(limit - left);
  }

  private _isStartEndPeriod(sub: ISubscription): boolean {
    const data: ILeftAndLimitParams = this._getLeftAndLimit(sub);
    if (sub.is_active_tariff === false) {
      return false;
    }
    if (data.limit === null) {
      return false;
    }
    if (sub.type === this._constTime) {
      return data.left < this._defaultPeriodInSeconds;
    }
    return data.left + this._defaultPeriodInSeconds >= data.limit;
  }

  private _getLimitTime(sub: ISubscription): number {
    return this._util.secondsToMinutes(this._getLeftAndLimit(sub).limit);
  }

  private _getPercent(sub: ISubscription): number {
    const data: ILeftAndLimitParams = this._getLeftAndLimit(sub);
    const limit = data.limit;
    let left = data.left;
    if (left > limit) {
      left = limit;
    }
    if (sub.type === this._constTime) {
      return Math.round(((left - limit) / limit) * -100);
    }
    return Math.round(((left - limit) / limit + 1) * 100);
  }

  private _isEndLimit(sub: ISubscription): boolean {
    const data: ILeftAndLimitParams = this._getLeftAndLimit(sub);
    if (data.limit === null) {
      return false;
    }
    return data.left >= data.limit;
  }


  private _initAdditionalParams(sub: ISubscription): ISubscription {
    sub.leftTime = this._getLeftTime(sub);
    sub.isEndLimit = this._isEndLimit(sub);
    sub.limitTime = this._getLimitTime(sub);
    sub.percent = this._getPercent(sub);
    sub.isStartEndPeriod = this._isStartEndPeriod(sub);
    return sub;
  }

  /**
   * Удаление подписок при выходе пользователя
   * @private
   */
  public clearSubscriptions() {
    this.subscriptions.next([]);
    this._setRegular([]);
  }

}
