<div class="debt-popup" *ngIf="DebtService.showPopup">
  <div class="debt-popup__close" (click)="DebtService.closePopup()">
    <img src="//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/close_button.svg" alt="">
  </div>
  <div class="debt-popup__inner" [ngSwitch]="DebtService.popupState">
    <div *ngSwitchDefault>
      <div class="debt-popup__info"
           [innerHTML]="'debtPopup.title' | translate | replace: '%debtCost%': DebtService.debtCost"></div>
      <div class="debt-popup__pay"
           [innerHTML]="'debtPopup.payInfo' | translate | replace: '%debtCost%': DebtService.debtCost"></div>
      <div class="debt-popup__actions">
        <button class="debt-popup__pay-button"
                *ngIf="!DebtService.isPaying"
                (click)="DebtService.payDebt()">{{ 'debtPopup.payButton' | translate }}</button>
        <button class="debt-popup__pay-button"
                *ngIf="DebtService.isPaying">
          <pk-loader></pk-loader>
        </button>
      </div>
    </div>
    <div *ngSwitchCase="'paidSuccess'">
      <div class="debt-popup__info">
        <img src="//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/paid_success.svg" alt="">
      </div>
      <div class="debt-popup__pay" [innerHTML]="'debtPopup.paidSuccess' | translate"></div>
      <div class="debt-popup__actions">
        <button class="debt-popup__play-button" (click)="DebtService.play()">{{ 'cardGame.play' | translate }}</button>
      </div>
    </div>
    <div *ngSwitchCase="'paidError'">
      <div class="debt-popup__info">{{ 'debtPopup.paidErrorTitle' | translate }}</div>
      <div class="debt-popup__pay" [innerHTML]="'debtPopup.paidErrorText' | translate"></div>
      <div class="debt-popup__actions">
        <button class="debt-popup__pay-button"
               *ngIf="!DebtService.isPaying"
        (click)="DebtService.payDebt()">{{ 'debtPopup.payButton' | translate }}</button>
        <button class="debt-popup__pay-button"
                *ngIf="DebtService.isPaying">
          <pk-loader></pk-loader>
        </button>
        <a href="https://support.playkey.net/"
           class="debt-popup__support"
           target="_blank">{{ 'debtPopup.support' | translate }}</a>
      </div>
    </div>
  </div>
</div>

