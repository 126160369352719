import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostBinding, HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

/**
 * Компонент элемента выпадающего меню
 */
@Component({
  selector: 'pk-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuItemComponent {
  /**
   * Возврат события клика на элемент в родительский компонент
   */
  @Output() itemClick: EventEmitter<Event> = new EventEmitter();

  /**
   * Дополнительные классы для элемента списка
   */
  @Input() itemClass: string;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {
  }

  /**
   * Установка дополнительных, передаваемых классов для компонента
   */
  @HostBinding('class') get class() {
   return `dropdown-menu-item ${this.itemClass || ''}`;
  };

  /**
   * Передача события клика по элементу в родительсвкий компонент
   * @param el
   */
  @HostListener('click') onClick(el) {
    if (this.itemClick.observers.length > 0) {
      this.itemClick.emit(el);
    }
  }
}
