<div class="modal">
  <div (click)="closeModal()" class="modal__shadow"></div>
  <div class="modal__wrapper">
      <ng-container *ngIf="!isLoading && !!product.selectedProduct">
        <ng-container *ngIf="resumeSubscribe.params.isTimePacket">
          <h1 class="modal__title modal__title--uppercase">
            {{'resumeSubscribe.buyOverlimit' | translate}}
          </h1>
          <h3 class="modal__description">
            {{'resumeSubscribe.willPayPacket' | translate}}
            <!-- В ПКТ -->
            <span *ngIf="resumeSubscribe.params.isPktAvailible && resumeSubscribe.params.isPktMain">
              {{ resumeSubscribe.params.pktPrice }} PKT
            </span>
            <!-- В фиате -->
            <pk-price *ngIf="!resumeSubscribe.params.isPktAvailible || !resumeSubscribe.params.isPktMain"
                      [price]="productCost.cost"
                      [code]="productCost.currency.code"></pk-price>
          </h3>
        </ng-container>

        <ng-container *ngIf="!resumeSubscribe.params.isTimePacket">
          <!-- Заголовок -->
          <h1 class="modal__title modal__title--uppercase">
            {{'resumeSubscribe.buyTitle' | translate}}
          </h1>

          <!-- Описание (без ПКТ) -->
          <h3 class="modal__description"
              *ngIf="!resumeSubscribe.params.isPktAvailible || !resumeSubscribe.params.isPktMain">
            <span *ngIf="!resumeSubscribe.params.isChangeNow"
                  [innerHtml]="(('resumeSubscribe.buyDescription' | translate)
                    .replace('%tariffName%', product.selectedProduct.name || product.selectedProduct.name_tariff)
                    .replace('%price%', getFormattedPrice(productCost?.price_sell, false, productCost.currency.code)))">
            </span>
            <span *ngIf="resumeSubscribe.params.isChangeNow"
                  [innerHtml]="('resumeSubscribe.buyDescription' | translate)
                    .replace('%tariffName%', product.selectedProduct.name)
                    .replace('%price%', getFormattedPrice(productCost.change_now_price, false, productCost.currency.code))">
            </span>
          </h3>

          <!-- Описание (с божественным ПКТ) -->
          <h3 class="modal__description"
              *ngIf="resumeSubscribe.params.isPktAvailible && resumeSubscribe.params.isPktMain">
            <span [innerHtml]="(('resumeSubscribe.buyPKTdesc' | translate)
                    .replace('%tariffName%', product.selectedProduct.name)
                    .replace('%price%', resumeSubscribe.params.pktPrice)
                .replace('%fiat%', getFormattedPrice(resumeSubscribe.params.pktFiat*100)))
                .replace('%discount%', resumeSubscribe.params.pktDiscount)">
            </span>
          </h3>
        </ng-container>

        <div class="modal__pay">
          <!-- Выбор способа оплаты -->
          <div class="modal__pay-title">
            {{'resumeSubscribe.paymentMethod' | translate}}
          </div>
          <div class="modal__pay-list">
            <pk-manage-cards [userPs]="paymentsUser" (updatePayment)="selectCard($event)"></pk-manage-cards>
          </div>

          <!-- Денег на ПКТ нет, но вы держитесь там -->
          <div class="balance-error"
               *ngIf="resumeSubscribe.params.isPktAvailible && resumeSubscribe.params.isPktMain && !resumeSubscribe.params.userCanPayPkt && !isLoadPayment">
            {{ 'payment.noPkt' | translate }}
          </div>

          <div class="modal__pay-new" (click)="closeModal(); openSelectPs()">
            {{'resumeSubscribe.payNewMethod' | translate}}
          </div>
        </div>

        <!-- Кнопки -->
        <div class="modal__buttons">
          <!-- Рабочие кнопки -->
          <ng-container *ngIf="!isLoadPayment">

            <div *ngIf="resumeSubscribe.params.isPktAvailible && resumeSubscribe.params.isPktMain && !resumeSubscribe.params.userCanPayPkt">
              <button
                class="modal__button modal__button--close"
                (click)="closeModal();">
                {{'resumeSubscribe.changeMind' | translate}}
              </button>

              <button
                class="modal__button"
                (click)="goAndBuyPkt()">{{'payment.addPkt' | translate}}</button>
            </div>

            <div *ngIf="!resumeSubscribe.params.isPktAvailible || !resumeSubscribe.params.isPktMain || resumeSubscribe.params.userCanPayPkt">
              <button
                class="modal__button modal__button--close"
                (click)="closeModal()">
                {{'resumeSubscribe.changeMind' | translate}}
              </button>

              <!-- Покупка подписки -->
              <button
                class="modal__button"
                (click)="paymentPS()"
                [disabled]="resumeSubscribe.params.isPktMain && !resumeSubscribe.params.isPktAvailible"
                *ngIf="!resumeSubscribe.params.isTimePacket">
                {{'resumeSubscribe.buyButton' | translate}}
              </button>

              <!-- Покупка сверх лимит -->
              <button
                class="modal__button"
                (click)="buyOverlimit()"
                [disabled]="resumeSubscribe.params.isPktMain && !resumeSubscribe.params.isPktAvailible"
                *ngIf="resumeSubscribe.params.isTimePacket">
                {{'resumeSubscribe.buyButton' | translate}}
              </button>
            </div>

          </ng-container>

          <!-- Загрузка -->
          <ng-container *ngIf="isLoadPayment">
            <button
              class="modal__button modal__button--close modal__button--disabled"
              *ngIf="isLoadPayment">
              {{'resumeSubscribe.changeMind' | translate}}
            </button>
            <button
              class="modal__button modal__button--disabled"
              *ngIf="isLoadPayment">
              <pk-loader></pk-loader>
            </button>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading || !product.selectedProduct">
        <div class="modal__title modal__title--uppercase">{{'resumeSubscribe.loadingData' | translate}}</div>
      </ng-container>
  </div>
</div>
