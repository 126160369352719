export const URLS = {
  base: '',
  index: '/index_app.html',
  en: '/en',
  langDefault: '/:lang',
  modal: '/:lang/m/:page',
  modalMore: '/:lang/m/:folder/:page',
  setPass: '/:lang/set_pass/:token',
  addCard: '/:lang/addCard',
  promoCode: '/:lang/promoCode',
  payment: {
    base: '/:lang/payment',
    lk: '/:lang/payment_lk',
    game: '/:lang/payment_game',
  },
  confirm: '/:lang/confirm/:token',
  finishDemo: '/:lang/demo_finished',
  intro: {
    onlyIntroPrefix: 'intro_',
    introPrefix: '/:lang/intro_',
    main: '/:lang/intro_know',
    WorldOfTanks: '/:lang/intro_wot',
    Warthunder: '/:lang/intro_warthunder',
    GTA5: '/:lang/intro_gta5',
    Witcher3: '/:lang/intro_witcher3',
    Witcher3demo: '/:lang/intro_witcher3',
    fallout4: '/:lang/intro_fallout4',
    division: '/:lang/intro_division',
    FarCryPrimal: '/:lang/intro_FarCryPrimal',
    rise_of_tomb_raider: '/:lang/intro_riseOfTombRaider',
    doom_demo: '/:lang/intro_doom',
    Doom: '/:lang/intro_doom',
    mail_armata: '/:lang/intro_mail_armata',
    eldorado: '/:lang/intro_eldorado',
    intro_promocode: '/:lang/intro_promocode',
    headerHash: 'introHeader',
    beeline: '/:lang/intro_beeline',
    bundle: '/:lang/bundle',
    mac1: '/:lang/intro_mac1',
    mac2: '/:lang/intro_mac2',
    mac3: '/:lang/intro_mac3',
    year_present: '/:lang/intro_year_present',
    unlimit_new: '/:lang/intro_unlimited_new',
    unlimit_old: '/:lang/intro_unlimited_old',
    unlimit_servers: '/:lang/intro_servers',
    search: '/:lang/intro_search',
    search_new: '/:lang/intro_search_new',
    search_girls: '/:lang/intro_search_girls',
    access: '/:lang/intro_access',
    girls: '/:lang/intro_girls',
    universal: '/:lang/intro_universal',
    universal_button: '/:lang/intro_universal_button',
    universal_subscription: '/:lang/intro_universal_subscription',
    universal_subscription_retarget_mac: '/:lang/intro_universal_subscription_retarget_mac',
    universal_subscription_retarget_mac_unfinished_purchase: '/:lang/intro_universal_subscription_retarget_mac_unfinished_purchase',
    universal_demo: '/:lang/intro_universal_demo',
    universal_gta: '/:lang/intro_universal_gta',
    universal_tariff: '/:lang/intro_universal_tariff',
    universal_tariff_14077: '/:lang/intro_universal_tariff_14077',
    universal_tariff_14077e: '/:lang/intro_universal_tariff_14077-e',
    universal_gta_retarget_win: '/:lang/intro_universal_gta_retarget_win',
    universal_gta_retarget_win_unfinished_purchase: '/:lang/intro_universal_gta_retarget_win_unfinished_purchase',
    year_gta: '/:lang/intro_year_gta',
    test_A: '/:lang/intro_test_A',
    test_B: '/:lang/intro_test_B',
    slider_games: '/:lang/intro_slidergames',
    slider_games_de: '/:lang/intro_slidergames_de',
    slider_games_de_demo: '/:lang/intro_slidergames_de_demo',
    search_games: '/:lang/intro_searchgames',
    search_games_ad: '/:lang/intro_searchgamesad',
    // ФРИИ лендинги
    lp32over24: '/:lang/intro_lp-32_a-over24',
    lp32below24: '/:lang/intro_lp-32_a-below24',
    lp331over24: '/:lang/intro_lp-331_a-over24',
    pubg: '/:lang/intro_pubg',
    ps_pubg: '/:lang/intro_ps_pubg',
    // 14210Landings
    pubg14210_01: '/:lang/intro_pubg_14210_01',
    universal_tariff_14210_02: '/:lang/intro_universal_tariff_14210_02',
    universal_tariff_14210_03: '/:lang/intro_universal_tariff_14210_03',
    universal_tariff_14210_04: '/:lang/intro_universal_tariff_14210_04',
    universal_tariff_14210_05: '/:lang/intro_universal_tariff_14210_05',
    item_game: '/:lang/intro_itemgame',
    farcry: '/:lang/intro_lp-333-fcr5',
    farcry_advert: '/:lang/intro_lp-333-fcr5-ad',
    farcry_stream: '/:lang/intro_lp-333-fcr5-strim',
    farcry34: '/:lang/intro_lp-34',
    farcry3499: '/:lang/intro_lp-34-99',
    lp333testA: '/:lang/intro_lp-333_test_A',
    october50ruble: '/:lang/intro_lp-known-50',
    mobile: '/:lang/intro_lp-known-mobile',
    farcry34_5R: '/:lang/intro_lp-34-5R',
    farcry34_190: '/:lang/intro_lp-34-190',
    farcry34_190ad: '/:lang/intro_lp-34-190-ad',
    farcry34_290: '/:lang/intro_lp-34-290',
    intro_lp_35: '/:lang/intro_lp-35',
    intro_lp_34_190290: '/:lang/intro_lp-34-190-290',
    farcry_ref_programm: '/:lang/intro_lp-ref-program',
    farcry_referral: '/:lang/intro_lp-referral',
    intro_lp_de34_190: '/:lang/intro_lp_de-34-190',
    intro_lp_34_1m_ad: '/:lang/intro_lp-34-1m-ad',
    intro_lp_34_1m_d_ad: '/:lang/intro_lp-34-1m-d-ad',
    intro_lp_34_1m: '/:lang/intro_lp-34-1m',
    intro_lp_34_1m_d: '/:lang/intro_lp-34-1m-d',
    intro_lp_34_1m_quiz: '/:lang/intro_lp-34-1m-quiz',
    intro_lp_34_1m_quiz_ad: '/:lang/intro_lp-34-1m-quiz-ad',
  },
  auth: '/:lang/auth',
  reg: '/:lang/reg',
  enter: '/:lang/enter',
  account: '/:lang/account',
  games: {
    base: '/:lang/games',
    category: '/:lang/games/category/:filter',
    categoryPrefix: '/:lang/games/category', // only internal use
    game: '/:lang/game/:code',
    gamePrefix: '/:lang/game',  // only internal use
  },
  paymentList: '/:lang/paymentlist',
  about: '/:lang/about',
  technology: '/:lang/technology',
  ideas: '/:lang/ideas',
  reveal: '/:lang/reveal',
  errors: {
    notFound: '/go/404',
    blocked: '/:lang/blocked',
  },
  support: {
    home: '/support/home',
    newTicket: '/support/tickets/new',
  },
  supportLang: '/:lang/support',
  helpPlaying: '/:lang/helpplaying',
  steps: {
    reg: '/:lang/steps/reg',
    download: '/:lang/steps/download',
    install: '/:lang/steps/install',
    payment: {
      base: '/:lang/steps/payment',
      noLang: '/steps/payment',
      allTariffs: '/:lang/steps/paymentAll',
      payFormCp: '/:lang/steps/payment/payFormCp',
      waitingForPayment: '/:lang/steps/payment/waitingForPayment',
    },
    selectPs: {
      base: '/:lang/steps/select_ps',
      lk: '/:lang/steps/select_ps_lk',
      noLang: '/steps/select_ps',
    },
    success: '/:lang/steps/success',
    buy: '/:lang/steps/buy',
  },
  payForm: '/:lang/steps/payment/payForm',
  welcomeSimple: '/:lang/welcome',
  welcomeAdvanced: '/:lang/upgrade',
  speedTest: '/:lang/speedTest',
  guide: {
    fallout4: '/:lang/guide/fallout4',
    gta5: '/:lang/guide/gta5',
  },
  fromDesktop: '/:lang/desktop',
  referral: '/referral/:user_id',
  specialOffer: '/:lang/specialOffer',
  shortPayment: '/:lang/shortpayment',
  fullScreenBundle: '/:lang/fullBundle',
  setEmailModal: '/:lang/setEmail',
  popupBanner: '/:lang/popupBanner',
  // Api
  api: {
    user: {
      ps: '/user/ps',
      setMain: '/user/ps/setmain',
      unbindCard: '/user/ps/unbind',
      info: '/user/info',
      sales: '/user/sales',
      salesStatus: '/user/sales/status',
      accounts: '/user/accounts',
      pass: {
        recall: '/user/pass/recall',
        change: '/user/pass/change',
      },
      requestConfirmMail: '/user/requestconfirmmail',
      setPass: '/user/pass/set',
      confirm: '/user/confirm',
      registration: '/user/registration',
      authorization: '/user/auth',
      captcha: {
        auth: '/user/auth/captcha/get',
        reg: '/user/registration/captcha/get',
        recall: '/user/recall/captcha/get',
        setemail: '/user/setemail/captcha/get',
      },
      ssotoken: '/user/uservoice/ssotoken',
      anonymous: {
        setemail: '/user/anonymous/setemail',
      },
      kohortNext: '/transitions/next',
      kohortsAdd: '/transitions/add',
      gaIdAdd: '/transitions/gaclientid/add',
      kohorts: {
        set: '/user/kohorts/set',
      },
      contract: '/user/contract/address',
      remove: '/user/delete',
      yametric: '/user/yandexmetrics/add',
      debt: {
        info: '/user/postpurchase/debtinfo',
        pay: '/user/postpurchase/paydebt',
      },
    },
    games: {
      info: '/games/info',
      all: {
        auth: '/games/all/auth',
        notAuth: '/games/all',
      },
      play: '/games/play',
      keys: '/user/games/keys',
      bought: '/user/games/bought',
      leftfree: '/user/games/leftfree',
      buyAndBind: '/user/games/buyandbind',
      gameshipments: {
        all: '/gameshipments/all',
        auth: '/gameshipments/all/auth',
        light: {
          all: '/gameshipments/all/light',
          auth: '/gameshipments/all/light/auth',
        },
      },
      similar: {
        base: '/gameshipments/similar',
        auth: '/gameshipments/similar/auth',
      },
    },
    products: {
      all: {
        auth: '/products/all/auth',
        base: '/products/all',
      },
    },
    game: {
      readOffer: '/user/game/readoffer',
      offer: '/user/game/game-offer',
      info: {
        base: '/gameshipments/info',
        auth: '/gameshipments/info/auth',
      },
    },
    subscription: {
      change: '/user/subscriptions/change',
      activate: '/user/subscriptions/activate',
      extend: '/user/subscriptions/extend',
      resume: '/user/subscriptions/resumeregular',
      suspend: '/user/subscriptions/suspend',
      get: '/user/subscriptions',
      buyAndBindPacket: '/user/subscriptions/buytimepacketandbind',
      buyPacket: '/user/subscriptions/buytimepacket',
    },
    referral: {
      transition: '/referral/transition',
      authTransition: '/user/referral/transition',
      set: '/user/referral/set',
      stat: '/user/referral/stat',
    },
    banners: {
      notAuth: '/banners/all',
      auth: '/banners/all/auth',
    },
    payment: {
      psAll: {
        notAuth: '/ps/all',
        auth: '/ps/all/auth',
      },
      buySpecialOffer: '/purchase/specialoffer/buyandbind',
      buySpecialBundle: '/purchase/specialbundle/buyandbind',
      bind: '/user/ps/bind',
    },
    currency: {
      base: '/currencies/all',
      auth: '/currencies/all/auth',
      rate: '/currencies/rate',
    },
    offers: {
      info: '/purchase/specialoffer/info',
    },
    bundle: {
      all: '/bundle/all',
      allAuth: '/bundle/all/auth',
      buyAndBind: '/bundle/buyandbind',
      buy: '/bundle/buy',
    },
  },
};



