import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

/**
 * Обёртка для выпажающей части меню
 */
@Component({
  selector: 'pk-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuComponent {
  @Input() menuClass: string;

  constructor() {
  }

  @HostBinding('class') get class() {
    return `dropdown-menu ${this.menuClass ? this.menuClass : ''}`;
  }
}
