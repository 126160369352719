import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _isFixed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  /**
   * Сохранение состояние фиксированности шапки
   * @param state
   * @private
   */
  public setFixedState(state) {
    this._isFixed$.next(state);
  }

  /**
   * Текушее состояние фиксации шапки
   */
  public getFixedState(): Observable<boolean> {
    return this._isFixed$.asObservable().pipe(distinctUntilChanged());
  }
}
