import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IKohort } from '../../interfaces/kohorts';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { map } from 'rxjs/operators';
import { IUserInfo } from '../interfaces/user-info';

@Injectable({
  providedIn: 'root'
})
export class KohortService {
  private baseUrl = 'KohortsAPI.svc';

  constructor(private _api: ApiService,
              private _translate: TranslateService,
              private _cookie: CookieWrapperService) { }

  getTransitions (): number[] {
    let kohortTransitions: number[] = [];
    const transitions: string = this._cookie.get('kohort_transitions');
    const transitionsAdd: string = this._cookie.get('kohort_transitions_add');
    if (!!transitions) {
      kohortTransitions.push(parseInt(transitions, 10));
    }
    if (!!transitionsAdd) {
      kohortTransitions = kohortTransitions.concat(transitionsAdd.split(',')
        .filter(id => id !== 'undefined').map(id => parseInt(id, 10)));
    }
    return kohortTransitions;
  }
  sendGaClient(kohortTransitions: number[]): Observable<boolean> {
    return this._api.post(`${this.baseUrl}/transitions/gaclientid/add`, {
      lang: this._translate.currentLang,
      kohort_transition_ids: kohortTransitions
    })
      .pipe(
        map((data) => !data.error.code || data.error.code === 3005)
      );
  }
  addKohorts(kohorts: IKohort[]): Observable<any> {
    return this._api.post(`${this.baseUrl}/transitions/add`, {
      lang: this._translate.currentLang,
      kohorts: kohorts
    });
  }

  /**
   * Метод проверяет наличие когорт с лендингов,
   * по которым идет трафик от СРА ShareASale
   * @param userInfo
   */
  checkShareASaleKohorts(userInfo: IUserInfo): boolean {
    const kohorts: string[] = [
      '201903_17723_lp-eu-quiz-before_en',
      '201903_17723_lp-eu-quiz-before_de'
    ];
    return Boolean(userInfo) && Boolean(userInfo.kohorts.filter(kohort => kohorts.indexOf(kohort.kohort_code) > -1).length);
  }
}
