<div class="modalContainer">
  <div (click)="CloseDinamicWindow()" class="modalShadow"></div>
  <div class="popup-banner"
       [ngStyle]= "{ 'background': 'url('+type.image_link+') #111936 center center no-repeat' }">

    <div class="popup-banner__content">
      <ng-container *ngIf="!unionBanner">
        <div class="popup-banner__additional-info" [innerHTML]="newLineConverter(type.header)"></div>
        <div class="popup-banner__additional-bottom" [innerHTML]="newLineConverter(type.subheader)"></div>
      </ng-container>

      <!-- Кнопка играть -->
      <button
        class="popup-banner__play popup-banner__play--{{type.options?.COLOR_BUTTON || 'blue'}}"
        [ngClass]="type.options?.TYPE && 'popup-banner__play popup-banner__play--' + type.options?.TYPE"
        id="popup-banner__play"
        (click)="openLink()">
					<span>{{(type.options.CUSTOM_PLAY_TEXT ? type.options.CUSTOM_PLAY_TEXT : 'popupBanner.play') | translate}}
					</span>
      </button>
    </div>
  </div>

</div>
