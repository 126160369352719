export const PLATFORMS = {
    Uplay: {
      code:       'Uplay',
      name:       'Uplay',
      link:       '//www.uplay.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_uplay_logo_v3.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_uplay.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/uplay_platform_circle_v2.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/uplay68x68.svg',
      text:       'card.needFreeAccountUplay',
      show:       true,
    },
    Uplay_free: {
      code:       'Uplay_free',
      name:       'Uplay',
      link:       '//www.uplay.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_uplay_logo_v3.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_uplay.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/uplay_platform_circle_v2.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/uplay68x68.svg',
      text:       'card.needFreeAccountUplayFree',
      show:       true,
    },
    Rockstar: {
      code:       'Rockstar',
      name:       'Rockstar',
      link:       '//socialclub.rockstargames.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_rockstar_logo.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_rockstar.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/rockstar_platform_circle.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/rockstar68x68.svg',
      text:       'card.needFreeAccountRockstar',
      show:       true,
    },
    Steam: {
      code:       'Steam',
      name:       'Steam',
      link:       '//store.steampowered.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/gameCard_steam_logo_buy_v5.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_steam.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/gameCard_steam_logo_buy_v2.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/steam68x68.svg',
      iconInversed: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/launchers/icon-steam-inversed.svg',
      text:       'card.needFreeAccountSteam',
      show:       true,
    },
    Steam_free: {
      code:       'Steam_free',
      name:       'Steam',
      link:       '//store.steampowered.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/gameCard_steam_logo_buy_v5.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_steam.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/gameCard_steam_logo_buy_v2.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/steam68x68.svg',
      text:       'card.needFreeAccountSteamFree',
      show:       true,
    },
    Origin: {
      code:       'Origin',
      name:       'Origin',
      link:       '//www.origin.com',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_origin_logo_2.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_catalog_platform_origin.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/gameCard_origin_logo_buy.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/origin68x68.svg',
      text:       'card.needFreeAccountOrigin',
      show:       true,
    },
    Battlenet: {
      code:       'Battlenet',
      name:       'Battle.net',
      link:       '//eu.battle.net',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_card_platform_battlenet.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/game_card_platform_battlenet.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/platform_battlenet_circle.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/battlenet68x68.svg',
      text:       'card.needFreeAccountBattlenet',
      show:       true,
    },
    Bethesda: {
      code:       'Bethesda',
      name:       'Bethesda',
      link:       '//bethesda.net/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/game_card_platform_bethesda.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/game_card_platform_bethesda_inverted.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/platform_bethesda_circle.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/bethesda68x68.svg',
      text:       'card.needFreeAccountBethesda',
      show:       true,
    },
    ESonline: {
      code:       'ESonline',
      name:       'TES Online',
      link:       '//www.elderscrollsonline.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/game_card_platform_tesonline.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/game_catalog_platform_tesonline.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/games/platforms/platform_tesonline_circle.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/tesonline68x68.svg',
      text:       'card.needFreeAccountTESonline',
      show:       true,
    },
    Mailru: {
      code:       'Mailru',
      name:       'Mail.ru',
      link:       '//mail.ru/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_mailru_logo.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/catalog_mailru_logo.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/button_mailru_logo.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/mail68x68.svg',
      text:       'card.needFreeAccountMailru',
      show:       true,
    },
    Mailru_free: {
      code:       'Mailru_free',
      name:       'Mail.ru',
      link:       '//mail.ru/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_mailru_logo.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/catalog_mailru_logo.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/button_mailru_logo.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/mail68x68.svg',
      text:       'card.needFreeAccountMailruFree',
      show:       true,
    },
    EpicGames: {
      code:       'EpicGames',
      name:       'Epic Games',
      link:       '//www.epicgames.com/',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/card_epicgames_logo.png',
      badge:      '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/catalog_epicgames_logo.png',
      circle:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/button_epicgames_logo.png',
      icon68:     '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/onBoarding/epic68x68.png',
      text:       'card.needFreeAccountEpicGames',
      show:       true,
    },
    NotSupportSteam: {
      code:       'NotSupportSteam',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/new/caution_ico.png',
      show:       false,
    },
    NotValveAntiCheat: {
      code:       'NotValveAntiCheat',
      img:        '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/new/caution_ico.png',
      show:       false,
    },
    None: {
      title:      'None',
      link:       '//www.playkey.net/',
      img:        '',
      badge:      '',
      circle:     '',
      text:       '',
      show:       false,
    },
  };

