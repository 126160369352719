export const environment = {
  name: 'prod',
  production: true,
  api: 'https://api.playkey.net/rest/',
  apiPathPrimary: 'PlaykeyAPI.svc/',
  cookiesDomain: 'playkey.net',
  platformsBindDomain: 'https://playkey.net',
  client: {
    mac: 'https://cdnmgc.playkey.net/clientdownload.aspx?file=MacClient-Release/Playkey.pkg',
    windows: 'https://cdnmgc.playkey.net/clientdownload.aspx?file=windows-desktop/Release/PlaykeySetup.exe',
  },
  gaCounter: 'UA-52416734-12',
  yaMetrika: 51718127,
  stripeCode: 'pk_live_AhuQ9ZNmY169ld9Itmz8H4Tp',
  partnerUrls: {
    'TTK': 'https://ttk.playkey.net'
  },
  captchaPublicKey: '6Lf3VDEUAAAAAByqcc6dbbG4_Y4rmefeBb3EEhUa',
};
