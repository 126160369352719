<div class="token-info__wrapper" id="tokenWrapper" (click)="close($event)">
  <div class="token-info">
    <header>{{ 'token.header' | translate}}</header>

    <!-- PKT balance-->
    <div class="token-info__current-balance">
      <p class="token-info__balance">{{ 'token.current_tariff' | translate }}</p>
    </div>

    <div class="token-info__inner">
      <!-- wallet address -->
      <div class="token-info__wallet">
        <div class="token-info__wallet-row">
          <form class="token-info__wallet-form">
            <div class="token-info__wallet-header">
              {{ 'token.buy_minutes_header' | translate }}
            </div>
            <div class="token-info__wallet-form--set">
              <p class="token-info__wallet-form--legend" [innerHTML]="'token.field_legend' | translate"></p>

              <div class="token-info__wallet-form--field">
                <input #inpElem value="{{ contract.contract_address }}" type="text" name="address" id="address" readonly>
                <button (click)="copy(contract.contract_address, $event)" [ngClass]="{ 'copied' : isCopied }">
                  {{ (isCopied ? 'modal.copied' : 'modal.copy') | translate }}
                </button>
              </div>
              <div class="token-info__wallet-form--helper" [innerHTML]="'token.field_instruction' | translate | replace: '%supportLink%' : getSupportLink()"></div>
            </div>
            <div class="token-info__wallet-form--set">
              <p class="token-info__wallet-form--legend" [innerHTML]="'token.params_legend' | translate"></p>

              <p class="token-info__wallet-form--params">
                Gas Limit: 60000 <br>
                Gas Price: 20 Gwei
              </p>
            </div>
          </form>
          <img width="200" height="200" src="{{ contract.qr_code}}" alt="">
        </div>
      </div>

      <!-- how to -->
      <div class="token-info__how-to">
        <div class="token-info__how-to--header">
          {{ 'token.how_title' | translate }}
        </div>

        <ol>
          <li [innerHTML]="'token.how_1' | translate"></li>
          <li [innerHTML]="'token.how_2' | translate"></li>
          <li [innerHTML]="'token.how_3' | translate"></li>
          <li [innerHTML]="'token.how_4' | translate"></li>
        </ol>
      </div>
    </div>

    <!-- buttons -->
    <div class="token-info__controls">
      <button (click)="close()">{{ 'close' | translate }}</button>
      <a href="{{'token.instruction' | translate}}" target="_blank">{{ 'token.button_instruction' | translate }}</a>
    </div>
  </div><!-- token-info-->
</div>
