import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// TODO: Добавить методы PUT и DELETE, когда API обновится

@Injectable({
  providedIn: 'root'
})
/**
 * Абстракный сервис HttpClient для корректного инжекта необходимого класса через di
 */
export abstract class ApiService {
  abstract get(path: string, params?: HttpParams): Observable<any>;

  abstract post(path: string, body?: Object): Observable<any>;
}
