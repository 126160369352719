export const FRESH_WORKS_WIDGET_ID = '13000000016';

export const FRESH_WORKS_WIDGET_URL = `https://widget.freshworks.com/widgets/${FRESH_WORKS_WIDGET_ID}.js`;

export const FRESH_WORKS_LOCALE_LABELS = {
  'en': {
    contact_form: {
      title: 'Contact us',
      submit: 'Send',
      confirmation: 'Thank you! We will respond to your email within an hour',
    }
  },
} as const;
