import {Injectable} from '@angular/core';
import {CookieWrapperService} from '../../shared/services/cookie-wrapper.service';
import {IActions} from '../interfaces/IAction';
import {IAdInfo} from '../interfaces/IAdInfo';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdvertService {
  public onlyProd: boolean;
  public advertParams: any = {
    ad1: {
      cookieStorage:  'advertStorage_ad1',
      cookieLifetime: 30,
      campaigns: [
        'partner_01',
        'partner_02',
      ],
    },

    s2s: {
      cookieStorage:  'advertStorage_s2s',
      cookieLifetime: 30,
      campaigns: [
        's2s',
      ],
    },

    vk_hic: {
      cookieStorage:  'advertStorage_vk_hic',
      cookieLifetime: 180,
      campaigns: [
        'vk_hic',
      ],
    },

    fb_hic: {
      cookieStorage:  'advertStorage_fb_hic',
      cookieLifetime: 180,
      campaigns: [
        'fb_hic',
      ],
    },

    driverpack: {
      cookieStorage:  'advertStorage_driverpack',
      cookieLifetime: 30,
      campaigns: [
        'driverpack'
      ]
    },
  };
  private enableProdLinks: boolean;
  private disableAdvLog: boolean;

  constructor(
    private _cookie: CookieWrapperService,
  ) {
  }


  public needSave(source: string, campaign: string): boolean {
    const canWork = !this.onlyProd || environment.production;
    const hasSource = !!this.advertParams[source];
    let hasSavedData = false;
    if (hasSource) {
      const info = this._getAdInfo(source);
      const params = this.advertParams[source];
      if (info && params) {
        const camInfo = info.campaigns && info.campaigns[campaign];
        hasSavedData = !!camInfo;
      }
    }
    return canWork && hasSource && !hasSavedData;
  }

  private _getAdInfo(source: string) {
    const params = this.advertParams[source];
    if (!params) {
      return null;
    }
    const cookieStorageName = params.cookieStorage;
    let storage = this._cookie.get(cookieStorageName);
    if (storage == null) {
      return null;
    }
    storage = decodeURIComponent(storage);
    return JSON.parse(storage);
  }
  public save (options: IActions): void {
    const source = options.source;
    const campaign = options.campaign;
    const params = this.advertParams[source];
    if (!params) {
      return;
    }
    const currentInfo: IAdInfo = this._getAdInfo(source) || {} as IAdInfo;
    currentInfo.campaigns = currentInfo.campaigns || {};
    currentInfo.campaigns[campaign] = {
      content:    options.content,
      term:       options.term,
      actions:    {},
    };
    this._setAdInfo(source, currentInfo);
  }

  private _setAdInfo (source: string, value: IAdInfo): void {
    const  params = this.advertParams[source];
    if (!params) { return; }
    this._cookie.put(
      params.cookieStorage,
      JSON.stringify(value),
      params.cookieLifetime,
    );
  }
}
