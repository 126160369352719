import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { AccountService } from '../../../account/service/account.service';
import { IUserContract } from '../../../user/interfaces/IUserContract';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../services/constant.service';


@Component({
  selector: 'pk-pkt-balance',
  templateUrl: './pkt-balance.component.html',
  styleUrls: ['./pkt-balance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PktBalanceComponent implements OnInit {
  @Output() Close = new EventEmitter<any>();
  @Input() card: any;
  @ViewChild('inpElem') elem: ElementRef;
  public isCopied = false;
  public contract: IUserContract = {
    contract_address: '0x23859DBF88D714125C65d1B41a8808cADB199D9E',
    qr_code: '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/account/pktQR.png'
  };

  constructor(
    private _user: UserService,
    private _account: AccountService,
    private _translate: TranslateService,
    private _const: ConstantService,
  ) {}

  ngOnInit() {}

  public getSupportLink (): string {
    const link = this._const.get('REDIRECTS').supportNewTicket[this._translate.currentLang];
    return `<a href="${link}" target="_blank">${this._translate.instant('token.field_instruction_support')}</a>`;
  }

  public balance() {
    return  this._account.getViewBalance(this.card.payment_system.current_balance) ;
  }

  public copy(text: string,  $event: MouseEvent) {
    $event.stopPropagation();
    this._account.copyToClipboard(text);
    this._account.selectTextInput(this.elem);
    this.isCopied = true;
  }

  public  close($ev?: MouseEvent) {
    if ($ev) {
     if (($ev.target as Element).getAttribute('class') === 'token-info__wrapper') { this.Close.next(null); } else { return; }
    }
    this._account.openPKT = false;
    this.Close.next(null);
  }
}
