import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'pk-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() code: string; // код валюты в которой выводить цену
  @Input() price: number; // цена в копейках/центах
  @Input() useSymbol = true; // Испльзовать/нет символ валюты
  @Input() wrapSymbol = false; // Обернуть/нет символ валюты в span

  private _unsubscribe$ = new Subject();

  currencyList;

  constructor(
    private _currency: CurrencyService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._currency.data$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(res => {
      this.currencyList = res;
      this._cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.price
      && changes.price.currentValue !== changes.price.previousValue
    ) {
      this.price = this.price / 100;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public getSign(sign: string): string {
    return this.wrapSymbol ? `<span class="price__sign">${sign}</span>` : sign;
  }

}
