import { InjectionToken, NgModuleFactory, Type } from '@angular/core';

export const LAZY_COMPONENTS = new InjectionToken<{ [key: string]: string }>('LAZY_COMPONENTS');

export const lazyComponents: { path: string, loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    path: 'lazyOnBoarding',
    loadChildren: () => import('../../games/modules/on-boarding/on-boarding.module').then(m => m.OnBoardingModule)
  }
];
