import { Injectable, Injector, Compiler, Inject, NgModuleFactory, Type, ViewContainerRef } from '@angular/core';
import { LAZY_COMPONENTS } from '../constant/lazy-components.const';

/**
 * LazyLoader - компонент для ленивой загрузки модулей и динамической вставки компонентов данных модулей
 */
@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {

  constructor(
    private injector: Injector,
    private compiler: Compiler,
    @Inject(LAZY_COMPONENTS) private _lazyComponents: { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> }
  ) {
  }

  /**
   * Асинхронная загрузка lazy-модуля и дальнейшее создание компонента данного модуля
   * @param name
   * @param container
   * @param inputs
   * @param outputs
   */
  async load(name: string, container: ViewContainerRef, inputs?, outputs?) {
    const tempModule = await this._lazyComponents[name]();

    let moduleFactory;

    if (tempModule instanceof NgModuleFactory) {
      // For AOT
      moduleFactory = tempModule;
    } else {
      // For JIT
      moduleFactory = await this.compiler.compileModuleAsync(tempModule);
    }
    this._createComponent(moduleFactory, container, inputs, outputs);
  }

  /**
   * Создание динамического компонента модуля
   * @param moduleFactory
   * @param container
   * @param inputs
   * @param outputs
   * @private
   */
  private _createComponent(moduleFactory, container, inputs, outputs) {

    const entryComponent = (moduleFactory.moduleType as any).entry;
    const moduleRef = moduleFactory.create(this.injector);
    const compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);

    const component = container.createComponent(compFactory);
    if (inputs) {
      this._setInputAttributesToComponent(inputs, component);
    }
    if (outputs) {
      this._setOutputAttributesToComponent(outputs, component);
    }
  }

  /**
   * Присваивание компоненту @Input аттрибутов
   * @param inputs
   * @param component
   * @private
   */
  private _setInputAttributesToComponent(inputs, component) {
    Object.keys(inputs).forEach(key => {
      component.instance[key] = inputs[key];
    });
  }

  /**
   * Присваивание компоненту @Output аттрибутов
   * @param outputs
   * @param component
   * @private
   */
  private _setOutputAttributesToComponent(outputs, component) {
    Object.keys(outputs).forEach(key => {
      component.instance[key].subscribe(outputs[key]);
    });
  }
}
