import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserService } from '../../user/services/user.service';
import { CookieWrapperService } from './cookie-wrapper.service';
import { isPlatformBrowser } from '@angular/common';
import { LANGUAGE, LanguageTypes } from '../constant/LANG';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  langData: any = {
    ru: {
      code: LANGUAGE.RU,
      prefix: `/${LANGUAGE.RU}/`,
      imgName: 'footer_lang_ru.png',
      title: 'Русский'
    },
    en: {
      code: LANGUAGE.EN,
      prefix: `/${LANGUAGE.EN}/`,
      imgName: 'footer_lang_uk.png',
      alt: 'English'
    }
  };

  constructor(private _user: UserService,
              private _cookie: CookieWrapperService,
              @Inject(PLATFORM_ID) private _platformId: Object) {
  }

  private _getUserLang() {
    return this._user.getDetectedRegion() === 'CIS'
      ? this.langData.ru.code
      : this.langData.en.code;
  }

  private _indexOfObject(searched: string) {
    for (const item in this.langData) {
      if (searched.indexOf(this.langData[item].prefix) > -1) {
        return item;
      }
    }
  }

  private _getLangByUrl() {
    const currentLang = isPlatformBrowser(this._platformId) ? this._indexOfObject(window.location.pathname) : null;
    if (!!currentLang && this._cookie.get('lang') !== currentLang) {
      this._cookie.put('lang', currentLang);
    }

    return this._checkLangString(currentLang);
  }

  private _checkLangString(langString: string) {
    const lang = langString as LanguageTypes;
    const supportedLagsArr = Object.values(LANGUAGE);
    if (supportedLagsArr.includes(lang)) {
      return lang;
    }
    if (langString === 'de') {
      return LANGUAGE.EN;
    }
  }

  getLang(): LanguageTypes {
    const langByUrl = this._getLangByUrl();
    const cookieLang = this._checkLangString(this._cookie.get('lang'));
    const userLang = this._getUserLang();
    return langByUrl ?? cookieLang ?? userLang;
  }
}
