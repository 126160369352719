import { Component, OnInit } from '@angular/core';

/**
 * Компонент для инжекта inline-svg в подвал приложения и последующего вызова через use с возможностью гибкой работы с svg через scss
 * (добавлять только предварительно оптимизированный, переиспользуемый в нескольких компонентах svg)
 */
@Component({
  selector: 'pk-inline-svg',
  templateUrl: './inline-svg.component.html',
  styleUrls: ['./inline-svg.component.scss']
})
export class InlineSvgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
