import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { HttpClient } from '@angular/common/http';
import { APP_ROUTES } from './app.routes';
import { LangService } from './shared/services/lang.service';

export function createTranslateLoader(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient,
  lang: LangService
) {
  settings.defaultLangFunction = (locales: string[], cachedLang?: string, browserLang?: string) => lang.getLang();
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

@NgModule({
  imports: [
    RouterModule.forRoot(
      APP_ROUTES,
      {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
      },
    ),
    LocalizeRouterModule.forRoot(
      APP_ROUTES,
      {
        parser: {
          provide: LocalizeParser,
          useFactory: createTranslateLoader,
          deps: [
            TranslateService,
            Location,
            LocalizeRouterSettings,
            HttpClient,
            LangService
          ]
        }
      })
  ],
  declarations: [],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
