/**
 * CONSTANT. User actions for observing
 * @type {Object}
 */
export const OBSERVEDACTIONS = {
  buy: {
    subscription: 'buySubscription'
  },
  addCard: 'addCard',
  referral: 'referral',
  resumeSubscribe: 'resumeSubscribe'
};


