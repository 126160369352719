import { environment } from '../../environments/environment';

const axios = require('axios');

const createAxiosInstance = (isServerAxiosInstance: boolean = false) => {
  const axiosDefaultConfig: { baseURL: string, timeout?: number, proxy?: boolean, httpsAgent?: any } = {
    baseURL: `${environment.api}${environment.apiPathPrimary}`,
    timeout: 30_000
  };

  return axios.create(axiosDefaultConfig);
}

export default createAxiosInstance;
