import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  HostListener, Inject, Input, OnChanges, OnDestroy,
  OnInit, Output, PLATFORM_ID, SimpleChanges, ViewChild
} from '@angular/core';
import { GamesService } from '../../../shared/services/games.service';
import { SearchService } from '../../search.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'pk-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isActive = false;
  @Output() changeState = new EventEmitter<boolean>();
  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;

  public searchInputValue = '';
  private _ngUnsubscribe$ = new Subject<any>();

  constructor(
    private _el: ElementRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _searchService: SearchService,
    private _localize: LocalizeRouterService,
    private _cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _game: GamesService
  ) {
  }

  @HostBinding('class') get searchFormClass() {
    return this.isActive ? 'search-form search-form--active' : 'search-form';
  }

  @HostListener('window:click', ['$event']) needCloseSearchForm(e: MouseEvent) {
    const curEl = (e.target || e.srcElement || e.currentTarget as Element);
    if (
      (this._el && this._el.nativeElement.contains(curEl))
      || Boolean(this._searchService.getSearchValue())
      || this._searchService.isSearchRoute()
    ) {
      return;
    }

    if (this.isActive === true) {
      this.isActive = false;
      this.changeState.emit(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive
      && !changes.isActive.firstChange
      && Boolean(changes.isActive.currentValue)
      && changes.isActive.previousValue !== changes.isActive.currentValue) {
      this._focusOnSearchInput();
    }
  }

  ngOnInit() {
    this._handleQueryParamsChanged();
    this._handleSearchValueChanged();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  /**
   * Обработка смены поискового запроса
   * @param value
   */

  public onSearchInputValueChanged(value) {
    if (value) {
      this._searchService.setSearchValue(value);
    } else {
      this._searchService.clearSearchValue();
    }
  }

  /**
   * Очистка поискового запроса
   */
  public clearSearch() {
    this._searchService.clearSearchValue();
  }

  /**
   * Открытие поисковой формы
   * @param ev
   */
  public openSearchForm(ev) {
    ev.stopPropagation();
    this.isActive = true;
    this.changeState.emit(true);
    this._focusOnSearchInput();
  }


  /**
   * Установка фокуса на инпуте ввода поискового запроса
   * @private
   */
  private _focusOnSearchInput() {
    if (isPlatformBrowser(this._platformId)) {
      this._cdr.detectChanges();
      this.searchInput.nativeElement.focus();
    }
  }


  /**
   * Обработка смены queryParams'ов при смене роута
   * @private
   */
  private _handleQueryParamsChanged() {
    this._route.queryParams.pipe(
      takeUntil(this._ngUnsubscribe$)
    ).subscribe(({searchQuery}) => {
      if (searchQuery) {
        this._searchService.setSearchValue(searchQuery);
        this.isActive = true;
      } else {
        this._searchService.clearSearchValue();
      }
    });
  }

  /**
   * Обработка смены роута при смене поискового запроса
   * @private
   */
  private _handleSearchValueChanged() {
    this._searchService.searchValue$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe$)
    ).subscribe(value => {
      this.searchInputValue = value;
      this._cdr.detectChanges();
      if (value) {
        return this._router.navigate([this._localize.translateRoute('/search')], {
          relativeTo: this._route,
          queryParams: {
            searchQuery: value
          }
        });
      }
      if (this._searchService.isSearchRoute()) {
        return this._router.navigate([this._localize.translateRoute('/search')], {
          relativeTo: this._route,
          queryParams: null
        });
      }
    });
  }
}
