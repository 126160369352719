import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CatalogFilterService } from '../games/modules/games-catalog/services/catalog-filter.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchValue$ = this._searchValue$.asObservable();
  constructor(
    private _catalogFilter: CatalogFilterService,
    private _router: Router
  ) {
  }
  /**
   * Получение значения текста поисковой строки
   */
  public getSearchValue() {
    return this._searchValue$.getValue();
  }

  /**
   * Установка значения текста поисковой строки
   * @param value
   */
  public setSearchValue(value) {
    this._searchValue$.next(value);
  }

  /**
   * Удаление значения текста поисковой строки
   * @param value
   */
  public clearSearchValue() {
    this._catalogFilter.setCategoryName(null);
    this._searchValue$.next('');
  }

  public isSearchRoute() {
    return this._router.url.includes('/search');
  }

}
