import { environment } from '../../../environments/environment';
import { ErrorHandlerClass } from '../classes/error-handler.class';
import * as Raven from 'raven-js';

export function provideErrorHandler() {
  if (environment.production) {
    Raven
      .config('https://959569ec5a3e4c4d9380dcd683ade326@cloudgaming.sentry.gmru.net/9')
      .install();

    return new ErrorHandlerClass((err) => {
      Raven.captureException(err);
      console.error(err);
    });
  } else {
    return new ErrorHandlerClass();
  }
}
