export const LAUNCHERS_TO_BUY = [
  {
    gameCode: 'origin',
    name: 'EA Play Pro',
    analyticName: 'origin access',
    buyTitleTranslateKey: 'payment.buyOriginCardTitle',
    buyTooltipTranslateKey: 'payment.buyOriginCardTitleTooltipText',
    gameCardLogo: '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/new/launchers/origin.png'
  },
  {
    gameCode: 'Microsoft_store_launcher',
    name: 'XBOX Game Pass',
    analyticName: 'XBOX Game Pass',
    buyTitleTranslateKey: 'payment.buyXBOXGamePassCardTitle',
    buyTooltipTranslateKey: 'payment.buyXBOXGamePassCardTitleTooltipText',
    gameCardLogo: '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/card/new/launchers/Microsoft_store_launcher.png'
  }
];
