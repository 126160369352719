import { ErrorHandler } from '@angular/core';

export class ErrorHandlerClass implements ErrorHandler {

  /**
   * Массив действующих ошибок таймаута пришедших из рекапчи
   * для дальнейшего исключения ошибок таймаута промиса
   * (выбрасываются с таймаутом 20сек после каждой ошибки таймаута рекапчи)
   */
  private _recaptchaTimeoutErrorsArray = [];

  constructor(private _handleErrorCallback = null) {
  }

  /**
   * Глобальный обработчик ошибок. При условии, что данные ошибки не игнорируются
   * возвращает передаваемый в конструктор callback либо console.error
   * @param error
   */
  handleError(error: Error): void {
    if (!this._handleSkipError(error)) {
      this._handleErrorCallback
        ? this._handleErrorCallback(error)
        : console.error(error);
    }
  }

  /**
   * Обработка пропуска ошибок
   * Дополнительные обработчики добавляются в качестве отдельных методов
   * и перечисляются через условие or в return Boolean(method || method)
   * @param error
   * @private
   * @return boolean - true для пропуска обработки ошибки, false для стандартной обработки
   */

  private _handleSkipError(error) {
    return Boolean(this._recaptchaErrors(error));
  }

  /**
   * Обработки пропуска ошибок таймаута рекапчи
   * пропускаем RecaptchaError и следующий за ним Timeout
   * @param error
   * @private
   */
  private _recaptchaErrors(error) {
    if (/(RecaptchaError).+(Timeout)/g.test(error)) {
      this._recaptchaTimeoutErrorsArray.push([true]);
      return true;
    }

    if (this._recaptchaTimeoutErrorsArray.length > 0 && (/Timeout/g.test(error))) {
      this._recaptchaTimeoutErrorsArray.splice(0, 1);
      return true;
    }

    return false;
  }
}
