import {
  Component,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Inject,
  ViewContainerRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SubscriptionService } from './user/services/subscription.service';
import { UserService } from './user/services/user.service';
import { combineLatest, merge, Subject } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BannerService } from './shared/services/banner.service';
import { UtilsService } from './shared/services/utils.service';
import { ResumeSubscribeService } from './shared/services/resume-subscribe.service';
import { CookieWrapperService } from './shared/services/cookie-wrapper.service';
import { KohortService } from './user/services/kohort.service';
import * as moment from 'moment';
import { AnalyticService } from './shared/services/analytic.service';
import { MissionService } from './user/services/mission.service';
import { takeUntil, filter, map } from 'rxjs/operators';
import { SupportService } from './shared/services/support.service';
import { DynamicService } from './modal/service/dynamic.service';
import { RouterEventsService } from './shared/services/router-events.service';
import { FreshChatComponent } from './shared/modules/fresh-chat/fresh-chat.component';
import { FreshChatService } from './shared/modules/fresh-chat/fresh-chat.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HeaderService } from './shared/components/header/header.service';
import { MetaService } from './shared/services/meta.service';
import { SteamApiService } from './shared/modules/platforms-connect/steam/services/steam-api.service';
import { HrefLangService } from './shared/utils/href-lang.service';

@Component({
  selector: 'pk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('freshChatContainer', {read: ViewContainerRef}) freshChatContainerRef: ViewContainerRef;
  public isVisibleHeader = false;
  public isVisibleFooter = false;
  public isFixedHeader;
  public isBrowser = isPlatformBrowser(this._platformId);

  public updateAfterLangChanged = false;

  private _navigatedToSetEmail;
  // Отписка от Event- ов
  private _unsubscribe$: Subject<any> = new Subject();

  constructor(
    public router: Router,
    public resumeSubscribe: ResumeSubscribeService,
    public utils: UtilsService,
    public support: SupportService,
    private _route: ActivatedRoute,
    private _user: UserService,
    private _subscription: SubscriptionService,
    private _localize: LocalizeRouterService,
    private _banner: BannerService,
    private _cookie: CookieWrapperService,
    private _kohort: KohortService,
    private _analytic: AnalyticService,
    private _mission: MissionService,
    private _dynamic: DynamicService,
    private _freshChat: FreshChatService,
    private _routerEvents: RouterEventsService,
    private _viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _translate: TranslateService,
    private _titleService: Title,
    private _header: HeaderService,
    private _cdr: ChangeDetectorRef,
    private _metaService: MetaService,
    private _steamApi: SteamApiService,
    private _hrefLang: HrefLangService,
    private _utils: UtilsService,
  ) {
    //  получаем контейнер в котором будем рендерить динамические элементы и сохраняем в DynamicService
    this._dynamic.setRootViewContainerRef(this._viewContainerRef);
  }

  ngOnInit() {
    this._hrefLang.initHrefLang();
    this._metaService.initSubscribeForLocalizeMeta();
    this._onLangChangeHandle();
    this._header.getFixedState().pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe(isFixedHeader => {
      this.isFixedHeader = isFixedHeader;
      this._cdr.detectChanges();
    });

    this._setPageTitleSubscriber();
    const currentDate = new Date();
    this._analytic.pushTag([{
      lastday_year: currentDate.getFullYear().toString(),
    }, {
      lastday_month: (currentDate.getMonth() + 1).toString(),
    }, {
      lastday_week: moment(currentDate).week().toString(),
    }]);

    this._route.queryParams.pipe(
      takeUntil(this._unsubscribe$))
      .subscribe(data => {
        this._mission.init(data);
        if (data.utm_source !== undefined) {
          this._analytic.pushTag([{
            source: data.utm_source,
          }]);
        }
        if (Object.keys(data).indexOf('demo_finished') > -1) {
          this.router.navigate([this._localize.translateRoute('/steps/demo_finished')], {queryParams: {}});
        }
      });

    // подписка на подзагрузку модулей user and games( instanceof  RouteConfigLoadEnd == модуль загружен  )
    this.router.events.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this._analytic.pageview(event.urlAfterRedirects);
        this._hrefLang.updateHref();
        combineLatest([this._user.userInfo$, this._subscription.data$]).pipe(
          takeUntil(this._unsubscribe$))
          .subscribe(res => {
            this.isVisibleHeader = this._checkIsVisibleHeader();
            this.isVisibleFooter = this._checkIsVisibleFooter();
            const [user, sub] = res;
            if (this.isBrowser) {
              Boolean(user)
                ? this._freshChat.addFreshChatDynamicComponent(FreshChatComponent, this.freshChatContainerRef)
                : this._freshChat.destroyFreshChatWidget();
            }
            if (user
              && user.is_anonymous
              && sub && sub.length > 0
              && sub[0].is_active_tariff
              && this.router.url.indexOf('steps/success') === -1
              && !this.router.url.includes('users/set-email')
              && !this._navigatedToSetEmail
            ) {
              this._navigatedToSetEmail = true;
              this.router.navigate([this._localize.translateRoute('/users/set-email')]);
            } else {
              this._navigatedToSetEmail = false;
            }
            if (!this.router.url.includes('game/')) {
              this._metaService.updateCanonicalUrl(null);
            }
          });
      }
    });
    this.checkKohortsForGa();
  }

  checkKohortsForGa() {
    if (!this._cookie.get('ga_client_sent')) {
      const kohortTransitions = this._kohort.getTransitions();
      if (kohortTransitions.length) {
        this._kohort.sendGaClient(kohortTransitions).pipe(
          takeUntil(this._unsubscribe$))
          .subscribe((data) => {
            if (data) {
              this._cookie.put('ga_client_sent', '1');
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
  private _needNewTitleToRoute() {
    return !['category/', 'game/'].some(val => this.router.url.includes(val));
  }

  private _setPageTitleSubscriber() {
    const appTitle = 'PLAYKEY';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd && this._needNewTitleToRoute()),
      map(() => this._route.firstChild),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.snapshot.data['title'];
      }),
      map(title => Boolean(title) ? this._translate.instant(title) : appTitle),
      takeUntil(this._unsubscribe$)
    ).subscribe(title => {
      this._titleService.setTitle(title);
    });
  }

  private _checkIsVisibleFooter() {
    return this.router.url.indexOf('/game/') === -1
      && !this.router.url.includes('/games/client/download')
      && !this.router.url.includes('/users/auth/swap')
      && !this.router.url.includes('/union');
  }

  private _checkIsVisibleHeader() {
    return !this.router.url.includes('/games/client/download')
      && !this.router.url.includes('/users/auth/swap')
      && !this.router.url.includes('/union');
  }

  /**
   * Подписка и обработка события при смене языка.
   * Манипуляции с флагом updateAfterLangChanged, чтобы обновить router-outlet
   * @private
   */
  private _onLangChangeHandle() {
    merge(this._user.logoutEvent$, this._translate.onLangChange)
      .pipe(
        map((data) => {
          this.updateAfterLangChanged = true;
          this._cdr.detectChanges();
        }),
        takeUntil(this._unsubscribe$)
      ).subscribe(() => {
      this.updateAfterLangChanged = false;
      this._cdr.markForCheck();
    })
  }
}
