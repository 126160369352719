/**
 * CONSTANT. Short reveal data
 * // TODO: implement as data from API
 * @type {Object}
 */

export const SHORTREVEALDATA = {
    games: [
        {
            title:          'XCOM 2',
            code:           '',
            img:            '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/reveal/short_reveal_xcom2.jpg',
            mode:           'soon'
        },
        {
            title:          'Dark Souls III',
            code:           '',
            img:            '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/reveal/short_reveal_ds3.jpg',
            mode:           'soon'
        },
        {
            title:          'Hitman',
            code:           '',
            img:            '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/reveal/short_reveal_hitman.jpg',
            mode:           'soon'
        },
        {
            title:          'Tom Clancy\'s The Division',
            code:           'division',
            img:            '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/reveal/short_reveal_division.jpg',
            mode:           'done'
        },
        {
            title:          'Far Cry Primal',
            code:           'FarCryPrimal',
            img:            '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/reveal/short_reveal_far_cry_primal.jpg',
            mode:           'done'
        }
    ]
};


