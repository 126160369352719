import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  public isVisible = true;

  constructor() { }

  public setIsVisible(state) {
    if (this.isVisible !== state) {
      this.isVisible = state;
    }
  }
}
