import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secretNumber'
})
export class SecretNumberPipe implements PipeTransform {
  /**
   * Возвращает строку со скрытым началом, оставляя видимыми только последние 4 символа
   * @param  input String - строка, например, номер счета
   * @return String
   */
  transform(input: string): string {
    return '**** **** **** ' + input.substring(input.length - 4, input.length);
  }

}
