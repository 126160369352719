import {
  ComponentFactoryResolver,
  Directive, ElementRef,
  HostListener,
  Inject,
  Injector,
  Input, OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[pkTooltip]',
})
export class TooltipDirective implements OnChanges {
  @Input() tooltipContent;
  public component;

  constructor(
    private _injector: Injector,
    private _viewContainerRef: ViewContainerRef,
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.tooltipContent
      && changes.tooltipContent.currentValue !== changes.tooltipContent.previousValue
      && !changes.tooltipContent.firstChange
      && this.component
    ) {
        if (changes.tooltipContent.currentValue) {
          this.component.instance.tooltipContent = changes.tooltipContent.currentValue;
        } else {
          this._destroyTooltip();
        }
    }
  }

  @HostListener('mouseover') onMouseHover(event: MouseEvent) {
    if (
      !this.component
      && this.tooltipContent
    ) {
      this._createTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._destroyTooltip();
  }

  @HostListener('click') onElClick() {
    this._destroyTooltip();
  }

  /**
   * Создание тултипа в одном пространстве с элементом, на который назначена директива
   * @private
   */
  private _createTooltip() {
    this._viewContainerRef.clear();
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TooltipComponent);
    this.component = this._viewContainerRef.createComponent(componentFactory);
    this.component.instance.tooltipContent = this.tooltipContent;
  }

  /**
   * Удаление текущего тултипа
   * @private
   */
  private _destroyTooltip() {
    if (this.component) {
      this.component.destroy();
      this.component = null;
    }
  }

}
