<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>

  <div class="modalContent">
    <div class="popup-banner">
      <div class="white_space_normal">
          <h1  class="modalH1" *ngIf="!type?.paymentType">{{ 'payment.errorMain' | translate }}</h1>
          <h1  class="modalH1" *ngIf="type?.paymentType == 'pay'">{{ 'payment.error' | translate }}</h1>
          <h1  class="modalH1" *ngIf="type?.paymentType == 'buy'">{{ 'payment.errorBuy' | translate }}</h1>
          <h1  class="modalH1" *ngIf="type?.paymentType == 'bind'">{{ 'payment.errorBind' | translate }}</h1>

          <h3  class="modalDescription" *ngIf="type?.errMsg == null || type?.errMsg == ''">
            {{ 'payment.somethinsWrong' | translate }}
            <a [href]="supportLink" target="_blank" class="g-link">{{ 'payment.techSupport' | translate }}</a>.
          </h3>
          <h3  class="modalDescription" *ngIf="type?.errMsg != null || type?.errMsg != ''">{{ type?.errMsg }}</h3>
          <button (click)="closeModal()" class="modalButton modalButton--big modalButton--block">{{ 'next' | translate }}</button>
      </div>
  </div>
</div>
</div>

