import {Injectable} from '@angular/core';
import {CONST} from '../constant';


@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor() {
  }

  public get(str: string): any {
    const file = this.getCurrentConstFile(str);
    // если не найдено файла
    if (!file) {
      return undefined;
    }
    // если константа массив
    if (Array.isArray(file)) {
      return file;
    }
    const nest = str.split('.');
    // поиск вложеныx обьектов  или конечное значение типа string or number or boolean;
    if (nest.length > 1) {
      const nestingArr = nest.slice(1);
      return this.getNestedObj(file, nestingArr);
    } else {
      return file;
    }
  }

  private getNestedObj(obj, arr) {
    let res;
    arr.forEach((val) => {
      res = res ? res[val] : obj[val];
    });
    return res;
  }

  private getCurrentConstFile(str: string): any {
    const fileName = str.split('.')[0];
    return CONST.filter((file) => fileName === Object.keys(file)[0]).map(val => val[fileName])[0];
  }
}
