import {Injectable} from '@angular/core';

interface IResumeSubscribeParams {
  isChangeNow: boolean;
  isPktMain: boolean;
  userCanPayPkt: boolean;
  isTimePacket: boolean;
  isPktAvailible: boolean;
  pktFiat: number;
  pktDiscount: number;
  pktPrice: number;
}


/**
 * Сервис, управляющий отображением модалки resumeSubscribe (компонент вызывается в app.component.html) и выводимыми в ней данными
 * Пришлось вынести из dynamicService, потому что попадали в circularDependency
 */
@Injectable({
  providedIn: 'root'
})
export class ResumeSubscribeService {
  visible = false; // параметр отображения модалки
  params: IResumeSubscribeParams = { // параметры, необходимые для вывода данных в модалке
    isChangeNow: false,
    isPktMain: false,
    userCanPayPkt: false,
    isTimePacket: false,
    isPktAvailible: false,
    pktFiat: null,
    pktDiscount: null,
    pktPrice: null
  };

  constructor() { }

  setModalParams(params: IResumeSubscribeParams) {
    this.params = params;
    this.visible = true;
  }
}
