import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'pk-error-unavailable',
  templateUrl: './error-unavailable.component.html',
  styleUrls: ['./error-unavailable.component.scss']
})
export class ErrorUnavailableComponent implements OnInit {

  constructor(private _api: ApiService,
              private _translateService: TranslateService) { }

  public ipAdress: string;
  public country: string;
  public resultMsg = 'serviceBlock.success';
  public isResult = false;
  public isCity = false;
  public isLoad = false;
  public emailCities = '';

  ngOnInit() {
    this.getInfoApi();
  }

  private getInfoApi() {
    // TODO: infoip брать из куки, когда будет реализовано.
    this._api.get(`${environment.apiPathPrimary}infoip`)
      .subscribe(data => {
        this.ipAdress = data.ip;
        this.country = data.country_name;
      });
  }

  public sendEmail() {
    this.isLoad = true;
    this._api.post(`${environment.apiPathPrimary}informplay`, {
      lang: this._translateService.currentLang,
      email: this.emailCities
    }).subscribe(data => {
      if (data.error.code) {
        this.isLoad = false;
        this.isResult = true;
        this.resultMsg = data.error.message;
      } else {
        this.isLoad = false;
        this.isResult = true;
        this.resultMsg = 'serviceBlock.success';
      }
    });
  }

  public closeResult() {
    this.isResult = this.isCity = false;
  }
}
