import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreshChatComponent } from './fresh-chat.component';

@NgModule({
  declarations: [FreshChatComponent],
  imports: [
    CommonModule,
  ],
  entryComponents: [FreshChatComponent],
})
export class FreshChatModule {
}
