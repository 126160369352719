import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GameShipment } from '../../../games/interfaces/game-shipment';
import { take } from 'rxjs/operators';
import { GamesService } from '../../../shared/services/games.service';
import { PlatformsInfoService } from '../../../shared/services/platforms-info.service';

@Component({
  selector: 'pk-success-key',
  templateUrl: './success-key.component.html',
  styleUrls: ['./success-key.component.scss']
})
export class SuccessKeyComponent implements OnInit {
  public key: string;
  public rightholderName: string;
  public rightholderLink: string;
  public thisGame: GameShipment;

  @Input() type: any;
  @Output() Close = new EventEmitter();

  constructor(
    private _localazRouter: LocalizeRouterService,
    private _translate: TranslateService,
    private _router: Router,
    private _game: GamesService,
    private _platformsInfo: PlatformsInfoService
  ) { }

  ngOnInit() {
    this.thisGame = this.type.gameInfo;
    this._platformsInfo.getGamePlatformInfoByCode(this.thisGame.platform_code)
      .pipe(take(1))
      .subscribe( platformInfo => {
        if (Boolean(platformInfo)) {
          this.rightholderName = platformInfo.code;
          this.rightholderLink = platformInfo.link;
        }
        this.key = this.type.key;
      });
  }
  // Закрытие модального окна по ESC
  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

 closeModal(): void {
    // переходим на главную страницу
     this._router.navigate([this._localazRouter.translateRoute('/game'), this.thisGame.code]);
    // удаляем динамический компонент
    this.Close.next('close');
  }
}
