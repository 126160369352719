<div [ngClass]="{'loader_with-backface': isVisibleBackface}" [ngStyle]="{width: size + 'px'}">
  &nbsp;<svg viewBox="0 0 90 60"
       [attr.height]="size"
       [attr.width]="size"
       [attr.svg-width]="size"
       [attr.svg-height]="size"
       class="pkLoaderContent"
       [ngStyle]="{ top: 'calc('+top+'% - ' + size / 2 + 'px)', left: 'calc(50% - ' + size / 2 + 'px)'}">

  <polygon
    points="0 0 0 40 40 40 40 0"
    class="pk-loader"
    transform="rotate(45, 25, 40)"
    [attr.stroke]="color"/>

</svg>
</div>
<div class="loader-backface" *ngIf="isVisibleBackface"></div>
