import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';


@Component({
  selector: 'pk-preorder',
  templateUrl: './preorder.component.html',
  styleUrls: ['./preorder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreorderComponent implements OnInit {

  @Input() type: any;
  @Output() Close = new EventEmitter();

  constructor(
    private _router: Router,
    private _localazeRoute: LocalizeRouterService,
  ) {
  }

  ngOnInit() {
  }

  // Закрытие модального окна по ESC
  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }


  closeModal(): void {
    // переходим на главную страницу
    this._router.navigate([this._localazeRoute.translateRoute(`/game/${this.type.code_game}`)]);
    // удаляем динамический компонент
    this.Close.next('close');
  }

}
