export const FRESH_CHAT_SETTINGS = {
  appToken: '343d8bd3-df3b-464a-a072-a62b1677e843',
  widgetUrl: 'https://wchat.freshchat.com',
  channelsUrl: function () {
    return this.widgetUrl + '/app/services/app/webchat/' + this.appToken + '/channels';
  },
  siteId: 'playkey_net_portal',
  channelTag: 'playkey_net_portal',
  widgetCssClasses: {
    widget: 'custom_fc_frame',
    expanded: 'custom_fc_expanded',
  },
};
