import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as moment from 'moment';
import { Observable, from, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription } from '../../user/interfaces/subscription';
import { Product } from '../../games/interfaces/product';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';

interface IParsedUrl {
  route: string;
  queryParams: any;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private loadedScripts: string[] = [];

  constructor(
    private _translate: TranslateService,
    private _cookie: CookieWrapperService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
  }

  public apiTimeoutError = false;

  public requestErrorType = '';

  public minutesToDays(minutes: number): number {
    return Math.floor(minutes / 60 / 24);
  }

  public minutesToHours(minutes: number): number {
    return Math.floor(minutes / 60);
  }

  public minutesToSeconds(minutes: number): number {
    return Math.floor(minutes * 60);
  }

  public secondsToMinutes(seconds: number): number {
    return Math.round(seconds / 60);
  }

  public timestampToDays(timestamp: number): number {
    return Math.floor(timestamp / (1000 * 3600 * 24));
  }

  public timestampToHours(timestamp: number): number {
    return Math.floor((timestamp / (1000 * 60 * 60)) % 24);
  }

  public timestampToMinutes(timestamp: number): number {
    return Math.floor((timestamp / 1000 / 60) % 60);
  }

  public timestampToSeconds(timestamp: number): number {
    return Math.floor((timestamp / 1000) % 60);
  }

  public appendZero(time: number): string {
    return ('0' + time).slice(-2);
  }

  public secondsToHours(seconds: number): number {
    return Math.floor(seconds / 60 / 60);
  }

  public getWeekNumber(d: Date): number {
    return moment().week();
  }

  public getYear(): number {
    return moment().year();
  }

  public getYearFromNow(): Date {
    return moment().add(1, 'year').toDate();
  }

  public filter(input: string, pattern: string): string {
    if (!input) {
      return '';
    }
    const part: string[] = input.split('-');
    switch (pattern) {
      case 'dd-mm-yyyy':
        return part[0] + '-' + part[1] + '-' + part[2];
      case 'mm-dd-yyyy':
        return part[1] + '-' + part[0] + '-' + part[2];
      case 'yyyy-mm-dd':
        return part[2] + '-' + part[1] + '-' + part[0];
      case 'yyyy-dd-mm':
        return part[2] + '-' + part[0] + '-' + part[1];
      case 'dd.mm.yyyy':
        return part[0] + '.' + part[1] + '.' + part[2].substring(0, 4);
      case 'mm.dd.yyyy':
        return part[1] + '.' + part[0] + '.' + part[2];
      case 'mm/dd/yyyy':
        return part[1] + '/' + part[0] + '/' + part[2];
      case 'yyyy/dd/mm hh:mm':
        return part[2].substring(0, 4) + '/' + part[1] + '/' + part[0] + part[2].substring(4, 15);
      case 'mm.dd.yyyy hh:mm':
        return part[1] + '.' + part[0] + '.' + part[2].substring(0, 10);
      default:
        return input;
    }
  }

  public getParsedTime(text: string, sub?: ISubscription | null, product?: Product | null): string {
    let resutlText = '';
    if (product) {
      resutlText = text
        .replace('%from%', this.timespanConverter(product.start_action))
        .replace('%to%', this.timespanConverter(product.finish_action))
        .replace('%timeZone%', product.region_time_name);
    } else if (sub) {
      resutlText = text
        .replace('%from%', this.timespanConverter(sub.start_action))
        .replace('%to%', this.timespanConverter(sub.finish_action))
        .replace('%timeZone%', sub.region_time_name);
    }
    return resutlText;
  }

  public convertDateFromMoscowToLocalTimestamp(date) {
    return Date.parse(`${date} +0300`);
  }

  public getLocalDateByTime(timeStr: string) {
    const date = new Date();
    const times = this.timespanConverter(timeStr)
      .split(':')
      .map(t => Number(t));
    date.setHours(times[0] || 24, times[1]);
    return this.convertDateFromMoscowToLocalTimestamp(date);
  }

  public timespanConverter(timespan: string, type?: string): string {
    let convertedTime = '0';
    if (!timespan) {
      return '';
    }
    if (timespan[3] === 'H') {
      convertedTime += timespan[2];
      if (timespan[4] === undefined) {
        convertedTime += ':00';
      } else {
        convertedTime += `:${timespan[5]}${timespan[6]}`;
      }
    } else {
      if (timespan[3] === 'S') {
        convertedTime = timespan[2] + '0';
      } else {
        convertedTime = timespan[2] + timespan[3];
      }
      if (timespan[5] === undefined) {
        convertedTime += ':00';
      } else {
        convertedTime += timespan[6] === 'M' ? `:0${timespan[5]}` : `:${timespan[5]}${timespan[6]}`;
      }
    }
    if (type === 'short') {
      convertedTime = convertedTime.substring(0, 2);
    }
    return convertedTime;
  }

  private _load(scriptUrl: string, params = {}): any {
    if (this.loadedScripts.indexOf(scriptUrl) > -1) {
      return new Promise(resolve => resolve(true));
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = resolve;
      script.onerror = reject;
      script.src = scriptUrl;
      for (let paramsKey in params) {
        script[paramsKey] = params[paramsKey];
      }
      this.loadedScripts.push(scriptUrl);
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  public loadScript(url, params = {}): Observable<any> {
    if (isPlatformBrowser(this._platformId)) {
      return from(this._load(url, params));
    } else {
      return of(null);
    }
  }

  /**
   * Метод из полученной от АПИ строки с гет-параметрами формирует объект, который можно использовать с angular/router
   * Пример:
   * const parsedUrl = this._utils.parseUrlAndParams(url);
   * this._router.navigate([this._localize.translateRoute(parsedUrl.route)], {queryParams: parsedUrl.queryParams});
   * @param url
   * @returns IParsedUrl
   */
  public parseUrlAndParams(url: string): IParsedUrl {
    const parsedUrl = url.split('?');
    const params = parsedUrl[1];
    const search = Boolean(params) ? JSON.parse('{"' + decodeURI(params.replace(/&/g, '","').replace(/=/g, '": "')) + '"}') : {};

    return {
      route: parsedUrl[0],
      queryParams: search
    };
  }

  public getByCode(input, code): any {
    const len = input.length;
    for (let i = 0; i < len; i++) {
      if (input[i].code === code) {
        return input[i];
      }
    }
    return '';
  }

  public cropToFixedNumber(num: number, fractionDigits: number = 0): string {
    if (!num) {
      return '0';
    }
    if (!fractionDigits) {
      return num.toFixed(0);
    }
    return `${parseFloat(num.toFixed(fractionDigits))}`;
  }

  /**
   * Возвращает удобочитаемую строку, сформированную из массива строк
   * в формате "list[0]<separator>list[1]<lastSeparator>list[2]"
   * @param list - массив строк для формирования
   * @param separator - строка для разделения элементов списка,
   *                    должна включать в себя необходимые пробелы, по деволту ", "
   * @param lastSeparator - строка для разделения последних двух элементов списка,
   *                        должна включать в себя необходимые пробелы, по деволту " или "
   */
  public getFormattedTextFromList(list: string[], separator: string = ', ', lastSeparator?: string): string {
    if (!list) {
      return '';
    }

    // если в массиве всего 1 элемент, возвращаем его
    if (list.length === 1) {
      return list[0];
    }

    lastSeparator = lastSeparator ?? ` ${this._translate.instant('auth.or')} `;

    // если lastSeparator равен separator, то строка формируется просто
    if (separator === lastSeparator) {
      return list.join(`${separator}`);
    }

    let res = '';
    list.forEach((item, key, array) => {
      const isFist = key === 0;
      if (isFist) {
        res += item;
      } else {
        const isLast = key === array.length - 1;
        res += `${isLast ? lastSeparator : separator}${item}`;
      }
    });
    return res;
  }

  // метод копирования в буфер обмена
  public copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
