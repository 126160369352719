import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../account/service/account.service';
import { TranslateService } from '@ngx-translate/core';
import { IUserPayment } from '../../../payments/interfaces/user-payment-system';
import { ISubscription } from '../../../user/interfaces/subscription';
import { combineLatest, Subscription } from 'rxjs';
import { SubscriptionService } from '../../../user/services/subscription.service';
import { PaymentService } from '../../../payments/services/payment.service';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'pk-sure-unbind',
  templateUrl: './sure-unbind.component.html',
  styleUrls: ['./sure-unbind.component.scss']
})
export class SureUnbindComponent implements OnInit, OnDestroy {
  @Input() cardIdForDel: any;
  @Output() CloseConf = new EventEmitter<any>();

  private _unsub = new Subscription();
  private _subAll: ISubscription[];
  private _userPs: IUserPayment[];
  private _constSubStatus: string;
  private _isLastPs: boolean;
  private _hasTrial: boolean;
  public canNotUnbind = false;
  public warningText = '';
  public loading = false;

  constructor(
    private _account: AccountService,
    private _translate: TranslateService,
    private _sub: SubscriptionService,
    private _payment: PaymentService,
    private  _constant: ConstantService,
  ) { }

  ngOnInit() {
    this._constSubStatus = this._constant.get('CONSTANT.subscription.codeStatus');
    this._unsub.add(combineLatest([this._sub.data$, this._payment.userPs$])
      .subscribe((res: [ISubscription[], IUserPayment[]]) => {
        this._subAll = res[0];
        this._userPs = res[1];
        this._initState();
        this.loading = true;
    }));
  }

  private _initState() {
    // проверяем, последняя ли платёжжка, не учитывая PKT
    this._isLastPs = this._userPs.filter(p => p.payment_system.code !== 'PKTBalance').length < 2;
    this._hasTrial = this._subAll.filter(s => s.is_trial).length > 0;
    const sub = this._subAll[0];
    // Проверяем возможность отвязать карту
    // Для незакрытой регулярной подписки с автопродлением последнюю карту отвязать нельзя,
    // в этом случае выводится объяснение.
    // отключаем заботливую подписку при удалении последней привязанной карты CLOUDGAMES-29396
    // this.canNotUnbind = this._isLastPs && sub
    //   && ['RegularAllDay', 'RegularPartOfDay'].indexOf(sub.type) > -1
    //   && sub.code_status !== this._constSubStatus['frozen']
    //   && sub.code_status !== this._constSubStatus['suspend'];
    this.warningText = this._getWarningToUnbindText();
  }

  /**
   * Метод возвращает текст сообщения, которое необходимо отобразить в модальном окне.
   * При отвязке последней карты, если у пользователя есть триальная подписка по новым промикам(за этот признак отвечает поле is_trial),
   * триал у него приостановится, и об этом его предупреждаем,
   * если триала нет - обычный текст.
   * @private
   */
  private _getWarningToUnbindText () {
    if (this.canNotUnbind) {
      const subName = this._subAll[0].name_tariff;
      const text = `<h2>${this._translate.instant('accountSettings.canNotUnbind.title')}</h2>`
        + `<span>${this._translate.instant('accountSettings.canNotUnbind.description').replace('%subName%', subName)}</span>`;
      return text;
    }
    return this._translate.instant(this._isLastPs && this._hasTrial ? 'warningToUnbind.aboutPromo' : 'accountSettings.wannaDelete');
  }


  public unBindCard() {
    this._account.deletecard(this.cardIdForDel);
    this.CloseConf.next(null);
  }

  close() {
    this.CloseConf.next(null);
  }

  ngOnDestroy() {
    if (this._unsub) {
      this._unsub.unsubscribe();
    }
  }
}
