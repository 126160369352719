import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { extendNgRecaptchaPrototype } from './app/shared/utils/extend-ng-recaptcha-prototype.utils';

function createGTM() {
  const gtmScript = document.createElement('script');
  gtmScript.innerHTML = `
  var gtmId = 'GTM-NB6G92G';
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', gtmId);
    window.dataLayer = window.dataLayer || [];
    dataLayer.push('config', gtmId, {
      'linker': {
        'accept_incoming': true
      }
    })
  `;
  document.head.appendChild(gtmScript);
}

function createMetrika() {
  const yaScript = document.createElement('script');
  const counters = `w.yaCounter = new Ya.Metrika2({
      id: ${environment.yaMetrika},
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true,
      trackHash:true
    });
  `;
  yaScript.innerText = `
      (function (d, w, c) {
      (w[c] = w[c] || []).push(function() {
        try {
          ${counters}
          var yaClient = yaCounter.getClientID(),
            gaTracker = w.ga.getByName('Etalon'),
            gaClient = gaTracker.get('clientId'),
            userId = readCookie('user_id');

          if (!!gaClient) {
            w.yaCounter.userParams({
              ga_clientID: gaClient
            });
          };
          if (!!yaClient) {
            w.ga('Etalon.set', 'dimension6', yaClient);
          }
          if (!!userId) {
            w.yaCounter.setUserID(userId);
          }
        } catch(e) { }
      });
      var n = d.getElementsByTagName("script")[0],
        s = d.createElement("script"),
        f = function () { n.parentNode.insertBefore(s, n); };
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://mc.yandex.ru/metrika/tag.js";

      if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
      } else { f(); }
    })(document, window, 'yandex_metrika_callbacks2');
  `;
  document.head.appendChild(yaScript);
}

function createGlobalHelpers() {
  const script = document.createElement('script');
  script.innerHTML = `
    function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
  function parseQueryString() {
    var str = window.location.search;
    var objURL = {};

    str.replace(
      new RegExp( '([^?=&]+)(=([^&]*))?', 'g' ),
      function( $0, $1, $2, $3 ){
        objURL[ $1 ] = $3;
      }
    );
    return objURL;
  }
  `;

  document.head.appendChild(script);
}

function createGA() {
  const script = document.createElement('script');
  script.innerHTML = `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script', 'https://www.google-analytics.com/analytics.js','ga');

    ${environment.production
    ? 'ga(\'create\', \'UA-52416734-1\', \'auto\', {\'name\': \'playkey\', \'allowLinker\': \'true\'});'
    : ''}
    ga('create', '${environment.gaCounter}', 'auto', {'name': 'Etalon', 'allowLinker': 'true'});
    ga('require', 'linker');
    ga('linker:autoLink', ['welcome.playkey.net', 'new.playkey.net'], true, true);

    ga(function() {
      var gaTracker = ga.getByName('Etalon');
      clientId = gaTracker.get('clientId');
      cookieId    = readCookie('user_id');
      referer     = document.referrer;
      document.cookie = "_ga_cid=" + clientId + "; path=/";
      ga('playkey.set', 'dimension1', clientId);
      ga('Etalon.set', 'dimension1', clientId);

      var urlParams = parseQueryString();
      if (!!urlParams.gclid) {
        ga('playkey.set', 'dimension9', urlParams.gclid);
        ga('Etalon.set', 'dimension4', urlParams.gclid);
      }
      if (!!urlParams.yclid) {
        ga('playkey.set', 'dimension10', urlParams.yclid);
        ga('Etalon.set', 'dimension5', urlParams.yclid);
      }

      if (referer && referer.indexOf('//playkey.net') === -1)
        ga('playkey.set', 'dimension2', referer);

      if (cookieId) {
        ga('playkey.set', 'dimension3', cookieId);
        ga('Etalon.set', 'dimension2', cookieId);
      }

      var hasCookieList = [
        'banner',
        'email',
        'isAuth',
        'partnerCode',
        'referralLink',
        'region_code',
        'skipEducation',
        'token',
        'user_id'
      ];

      var hasAnyCookie = function () {
        for (var i = 0; i < hasCookieList.length; i++) {
          if (readCookie(hasCookieList[i]) !== undefined) {
            return true;
          }
        }

        return false;
      };

      if (hasAnyCookie() === false) {
        ga('playkey.set', 'dimension6', 'firstSession');
      }
    });`;
  document.head.appendChild(script);
}

createGlobalHelpers();
createGA();
createMetrika();

if (environment.production) {
  // createFBPixel();
  createGTM();

  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});


extendNgRecaptchaPrototype();
