import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UtilsService } from '../utils.service';
import { HttpClient } from '@angular/common/http';
import { AnalyticService } from '../analytic.service';
import createAxiosInstance from '../../../services/axios-with-proxy';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBrowserService extends ApiBaseService {
  constructor(protected _utils: UtilsService,
              protected http: HttpClient,
              @Inject(PLATFORM_ID) protected _platformId: Object,
              private _analytic: AnalyticService,) {
    super(_utils, http, _platformId, createAxiosInstance());
    this._setRequestBrowserInterceptor();
    this._setResponseInterceptor();
  }

  protected _setRequestBrowserInterceptor() {
    this.axiosClient.interceptors.request.use((config) => {
      if (config.method === 'post') {
        const gaId = this._analytic.getClientId(); // получаем ga clientId
        if (gaId) {
          config.data['ga_clientId'] = gaId;
        }
      }

      if (this._utils.apiTimeoutError) {
        return config;
      }

      return config;
    });
  }
}
