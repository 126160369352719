import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogFilterService {
  private _category$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private _searchName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  //  флаг если переходим по доп контенту из карточки(у игры есть dlc)
  // устанавливаеться  в game-card-additional-content.component.ts стр 42
  // нужно для корректного отображения и фильтрации ( а также правильного запроса в fetchGames in GameCatalogService
  // и отображения активного фильтра(при true нет активного ))
  private _isDlc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public category$ = this._category$.asObservable();

  public searchName$ = this._searchName$.asObservable();

  public isDlc$ = this._isDlc$.asObservable();

  constructor() {
  }

  /**
   * Получение значения текста поисковой строки фильтра
   */
  getSearchName() {
    return this._searchName$.getValue();
  }

  /**
   * Установка значения текста поисковой строки фильтра
   * @param value
   */
  setSearchName(value) {
    this._searchName$.next(value);
  }

  /**
   * Получение значения названия выбранной категории фильтра
   */
  getCategoryName() {
    return this._category$.getValue();
  }

  /**
   * Установка значения названия выбранной категории фильтра
   * @param value
   */
  setCategoryName(value) {
    this._category$.next(value);
  }

  /**
   * Получение значения параметра isDlc
   */
  getIsDlc() {
    return this._isDlc$.getValue();
  }

  /**
   * Установка значения параметра isDlc
   * @param value
   */
  setIsDlc(value) {
    this._isDlc$.next(value);
  }
}
