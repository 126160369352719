<div class="error-response">
  <div class="error-response-content">
    <div class="error-response-content__title"
         [innerHTML]="'errorResponse.title' | translate">
    </div>
    <div class="error-response-content__info">
      <button class="error-response-content__reload-button"
           (click)="reload()">
        {{ 'errorResponse.refresh' | translate }}
      </button>
      <div class="error-response-content__auto-update"
           [innerHTML]="'errorResponse.autoUpdate' | translate | replace:'%seconds%'
           : (seconds | decOfNum: [('duration.second1' | translate), ('duration.second2' | translate), ('duration.second3' | translate)])">
      </div>
    </div>
  </div>
</div>
