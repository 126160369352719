import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CatalogFilterService } from '../../games/modules/games-catalog/services/catalog-filter.service';
import { ConstantService } from './constant.service';
import { ApiService } from './api.service';
import { UserService } from '../../user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, of, ReplaySubject, combineLatest } from 'rxjs';
import { concatMap, mergeMap, map, switchMap, take, tap } from 'rxjs/operators';
import { IAllScrollGames, GameShipment } from '../../games/interfaces/game-shipment';
import { GamesContent } from '../../games/interfaces/games-content';
import { GamesKeysResponse } from '../../games/interfaces/games-keys-response';
import { GameShipmentAdditionalInfo } from '../../games/interfaces/game-shipment-additional-info';
import { GameShipmentInfoParams } from '../../games/interfaces/game-shipment-info-params';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { GameRequest } from '../../games/interfaces/game-request';
import { GAME } from '../../games/constants/game.const';
import { CategoryGamesDictionary } from '../../games/interfaces/games-category';
import { GamesKey } from '../../games/interfaces/games-key';
import { GamePlatform, GamePlatformsInfoResponse } from '../../games/interfaces/game-platform';
import { SearchService } from '../../search/search.service';
import { LAUNCHERS_TO_BUY } from '../constant/LAUNCHERS_TO_BUY';
import { CatalogGameItem } from '../../games/interfaces/catalog-game';
import { PLATFORMS } from '../constant/PLATFORMS';
import { PlatformsConnectService } from '../modules/platforms-connect/platforms-connect.service';
import { environment } from '../../../environments/environment';
import { PLATFORMS_CONNECT_CODES } from '../modules/platforms-connect/platforms-connect.types';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  private _userGamesKeys = new ReplaySubject<GamesKey[]>(1);
  public userGamesKeys$: Observable<GamesKey[]> = this._userGamesKeys.asObservable();

  public sendGAfromOnboarding = false;
  public userLastGameCardVisited: GameShipment = null;

  private _favouriteStateChanged = new BehaviorSubject(null);
  public favouriteStateChanged$: Observable<string> = this._favouriteStateChanged.asObservable();

  public games: GameShipment[] | CatalogGameItem[] = [];

  private readonly _platformsConnectCodes = PLATFORMS_CONNECT_CODES;

  private _gamesPlayParamsStorage = {};

  private gamesMainCatalogCategories = [];

  /**
   * Индекс с которого будет производиться подгрузка новых игр
   */
  private _offset = 0;

  /**
   * Количество игр загружаемых за один раз
   */
  private _limit = 9;

  /**
   * Текущий язык каталога игр (необходим для дальнейшей корректной смены языка в каталоге)
   */
  private _gamesCatalogLang = this._translate.defaultLang;

  /**
   * Базовый путь к api игр
   */
  private _baseUrl = `${environment.apiPathPrimary}gameshipments`;

  /**
   * Базовый путь к api основной
   */
  private _baseUrlPrimary = environment.apiPathPrimary;

  /**
   * Текст "распродажа"
   */
  private _saleWord = this._translate.get('menuFilter.discounts').subscribe(res => res);

  /**
   * Статусы игр
   */
  private _gameStatus: typeof GAME.status;


  constructor(
    private _api: ApiService,
    private _user: UserService,
    private _translate: TranslateService,
    private _filterGames: CatalogFilterService,
    private _search: SearchService,
    private _const: ConstantService,
    private _platformsConnect: PlatformsConnectService,
    @Inject(DOCUMENT) private _doc: any,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this._gameStatus = GAME.status;
    this._getGamesPlayParamsFromStorage();
    this.getKeys().subscribe();
    this._getGamePlatformsInfo().subscribe();
    this.getLaunchers().subscribe();
  }

  /**
   * Список игр и дополнительых свойств, отображаемый на главной странице каталога
   * gamesScrolled - игры
   * offset - смещение последней загруженной игры
   * positionScroll - позиция скролла на момент ухода со страницы
   */
  public mainGames: IAllScrollGames = {
    gamesScrolled: [],
    offset: 0,
    positionScroll: 0,
  };

  // Эта поле будет сохранять весь список загруженых ранее  категорий (имя категории, список загруженых игр , позицию скролла)

  /**
   * Список категорий с играми и данными
   */
  public categoryGames: CategoryGamesDictionary | Object = {};

  // Map для хранения временных объектов с кодами игр категории для загрузки обновленных игр при смене языка
  private _cachedCategoriesScrolledGamesCodes = new Map();

  private _cachedMainCategoryGames: GameShipment[] = [];

  // используем для активации установки скролла в каталоге
  private _activateScroll = new Subject();

  public _activateScroll$ = this._activateScroll.asObservable();

  private _gamesFilterNames = new BehaviorSubject<object>({});

  public gamesCategories$: Observable<object> = this._gamesFilterNames.asObservable();

  /**
   * Observable получения данных обо всех платформах на сервисе
   */
  private _gamePlatformsInfo = new BehaviorSubject<GamePlatform[]>(null);

  /**
   * Переписывает обложки в удобочитаемый формат
   * @param {GameShipment} game
   * @return {GameShipment}
   */
  private static _getParsedImages(game: GameShipment): GameShipment {
    game.parsed = {};
    // tslint:disable-next-line:forin
    for (const i in game.content) {
      const el: GamesContent = game.content[i];
      game.parsed[el.type] = el.links;
    }
    return game;
  }

  // Метод вызов события установки скролла
  public goScroll(): void {
    this._activateScroll.next();
  }


  /**
   * Возвращение значения оффсета сервиса
   */
  public getOffset() {
    return this._offset;
  }

  /**
   * Установка оффсета для сервиса
   * @param offset
   */
  public setOffset(offset: number) {
    this._offset = offset;
  }


  /**
   * Возвращение значения лимита сервиса
   */
  public getLimit() {
    return this._limit;
  }

  /**
   * Установка лимита для сервиса
   * @param limit
   */
  public setLimit(limit: number) {
    this._limit = limit;
  }

  /**
   * Обнуление дампа памяти загруженных игр
   */
  public resetGamesDump(): void {
    this.mainGames.offset = 0;
    this.mainGames.positionScroll = 0;
    this.mainGames.gamesScrolled = [];
    this.mainGames.virtualScroll = 0;
    this.categoryGames = {};
    this.games = [];
  }

  /**
   * Обнуление дампа памяти загруженных игр при смене языка
   */
  public resetGamesDumpAfterLanguageChanged(): void {
    this.setCachedMainCategoryGames([...this.mainGames.gamesScrolled]);
    this.mainGames.offset = 0;
    this.mainGames.gamesScrolled = [];
    // tslint:disable-next-line:forin
    for (const category in this.categoryGames) {
      const categoryValues = (this.categoryGames as CategoryGamesDictionary)[category];
      if (!this._cachedCategoriesScrolledGamesCodes.has(category)) {
        this.setCategoryCachedScrolledGamesCodes(category, [...categoryValues.gamesScrolled.map(game => game.code)]);
      }
      categoryValues.gamesScrolled = [];
      categoryValues.offset = 0;
    }
    this.games = [];
  }

  /**
   * Возврат игр основного каталога из временного хранилища
   * @param categoryName
   */
  public getCachedMainCategoryGames() {
    return this._cachedMainCategoryGames;
  }

  /**
   * Возврат игр основного каталога из временного хранилища
   * @param categoryName
   */
  public setCachedMainCategoryGames(values) {
    return this._cachedMainCategoryGames = [...values];
  }

  /**
   * Возврат игр основного каталога из временного хранилища
   * @param categoryName
   */
  public deleteCachedMainCategoryGames() {
    return this._cachedMainCategoryGames = [];
  }

  /**
   * Возврат данных категории из временного хранилища идентификаторов игр категорий
   * @param categoryName
   */
  public getCachedScrolledGamesCategory(categoryName) {
    return this._cachedCategoriesScrolledGamesCodes.get(categoryName);
  }

  /**
   * Добавление новой категории во временное хранилище идентификаторов игр категорий
   * @param categoryName
   * @param data
   */
  public setCategoryCachedScrolledGamesCodes(categoryName, data) {
    this._cachedCategoriesScrolledGamesCodes.set(categoryName, data);
  }

  /**
   * Удаление категории из временного хранилища идентификаторов игр категорий
   * @param categoryName
   */
  public deleteCachedScrolledGamesCategory(categoryName) {
    this._cachedCategoriesScrolledGamesCodes.delete(categoryName);
  }

  // Метод используется для установки offset глобально (который используется для работы с апи в методе fetchGames)
  public setGlobalOffset(): void {
    this.setOffset(this.mainGames.offset);
  }

  /**
   * Метод проверки что никакая фильтрация не используется
   * @param params{any}
   * @returns {boolean}
   */
  public isGameNotFiltered(params: any): boolean {
    return !params.filter_categories_and_genres && !params.filter_codes && !params.filter_name;
  }


  /**
   * Метод получения всех игр из памяти для главного каталога(не категория)
   * @returns {Array<GameShipment>}
   */
  public getDownloadGames(): Array<GameShipment> {
    this.setGlobalOffset();
    return this.mainGames.gamesScrolled;
  }

  /**
   * Метод записи в память игр при скролле для главного каталога(не категория)
   * @param game {Array<GameShipment>}
   */
  public setDownloadGames(game: GameShipment[]): void {
    this.mainGames.gamesScrolled = [].concat(game);
    this._setScrollOffsetForAllGames();
  }

  // Метод проверки существования категории в обьекте памяти
  public isCategoryExist(category: string): boolean {
    const categoryInst = Array.isArray(category) ? category[0] : category;
    return !!this.categoryGames[categoryInst];
  }

  /**
   * Метод сохраняет позицию скролла для конкретной категории в память
   * @param scroll{number}
   * @param category {string}
   */
  public saveCategoryPositionScroll(scroll: number, category: string): void {
    this.mainGames.positionScroll = scroll;
  }

  /**
   * Метод возвращает из памяти обьект данных выбраной категории
   * @param category{string}
   * @return {IAllScrollGames}
   */
  public getCategoryData(category: string): IAllScrollGames {
    return this.categoryGames[category];
  }

  /**
   * Сохранение новых значений для категории
   * @param category
   * @param values
   */
  public setCategoryData(category: string, values: IAllScrollGames) {
    this.categoryGames[category] = {
      ...this.categoryGames[category],
      ...values
    };
    this.setOffset(this.getCategoryData(category).offset);
  }

  /**
   * метод сохранения позиции виртуального скролла в категориях
   * @param category{string} имя категории
   * @param num {number} позиция скролла
   */
  public savePositionVirtualScrollForCategory(category: string, num: number): void {
    const catDump = this.categoryGames[category];
    if (Boolean(catDump)) {
      (catDump as IAllScrollGames).virtualScroll = num;
    }
  }

  /**
   * Метод запрашивает список игр из одной категории
   * @param category{string} имя категории
   * @param additionalParams дополнительные параметры запроса
   * @returns {Observable<GameShipment[]>}
   */
  public getGamesByCategory(category: string, additionalParams?: { [key: string]: any }): Observable<GameShipment[]> {
    let url = `${this._baseUrl}/all`;
    if (category === this._platformsConnectCodes.Steam) {
      additionalParams.limit = null;
    }
    const params: any = {
      lang: this._translate.currentLang,
      filter_categories_and_genres: [category],
      ...additionalParams
    };
    let keys: GamesKey[];
    return this._user.checkAuth()
      .pipe(
        take(1),
        mergeMap((data) => {
          if (data) {
            params.token = this._user.token;
            url = `${this._baseUrl}/all/auth`;
          }
          return this._userGamesKeys;
        }),
        mergeMap(data => {
          keys = data;
          return this._api.post(url, params);
        }),
        map((games) => {
          if (games && !games.error.code && games.game_shipments_any) {
            return games.game_shipments_any.map(game => this._overrideGameData(game, keys));
          } else {
            return [];
          }
        })
      );
  }

  /**
   * Получение списка игр для каталога по категории
   * @param category - код категории, либо null (получение игр при поиске)
   * @param offset
   * @param limit
   */
  public getGamesByCatalogCategory(category, offset, limit?) {
    const params: any = {
      lang: this._translate.currentLang,
      offset: offset,
      filter_name: this._search.getSearchValue(),
      filter_categories_and_genres: category ? [category] : null,
    };

    if (limit) {
      params.limit = limit;
    }

    if (category === this._platformsConnectCodes.Steam) {
      params.limit = 0;
      params.offset = 0;
    }

    /**
     * TODO удалить после реализации кеширования запросов к апи. Нижестоящий код использовать для тестирования большого массива игр
     */
    //   return of(steamGamesMockArray)
    //     .pipe(
    //       map(games => {
    //         this.addOrUpdateCategoryGames([category], games);
    //         return games;
    //       })
    //     )

    let url = `${this._baseUrl}/all/catalog-category`;

    return this._user.checkAuth()
      .pipe(
        take(1),
        mergeMap((data) => {
          if (data) {
            params.token = this._user.token;
            url = `${this._baseUrl}/all/catalog-category/auth`;
          }
          return this._api.post(url, params);
        }),
        map((games) => {
          if (games && !games.error.code && games.game_shipments_any) {
            this.addOrUpdateCategoryGames([category], games.game_shipments_any);
            return games.game_shipments_any;
          } else {
            return [];
          }
        })
      );
  }

  public fetchGames(newList: boolean = false, code?: Array<string> | string): Observable<GameShipment[]> {
    this.setOffset(newList ? 0 : this.getOffset());
    let url = `${this._baseUrl}/all`;
    const filterCategoryName = this._filterGames.getCategoryName();
    const filterSearchValue = this._search.getSearchValue();
    const params: GameRequest = {
      lang: this._translate.currentLang,
      filter_codes: code ? [].concat(code) : null,
      filter_categories_and_genres: !code && filterCategoryName && !filterSearchValue
        ? [filterCategoryName]
        : null,
      offset: this.getOffset(),
      limit: this.getLimit(),
      filter_name: code ? null : filterSearchValue
    };
    let keys: GamesKey[] = [];

    return this._user.checkAuth()
      .pipe(
        take(1),
        concatMap((data) => {
          if (data) {
            params.token = this._user.token;
            url = `${this._baseUrl}/all/auth`;
          }
          return this._userGamesKeys;
        }),
        mergeMap(data => {
          keys = data;
          return this._api.post(url, params);
        }),
        map(games => {
          return games.game_shipments_any.map(game => this._overrideGameData(game, keys));
        }),
        // тут сохраняем все игры при скролле
        tap((data: GameShipment[]) => {
          // данные основного каталога без фильтров и т.д
          if (this.isGameNotFiltered(params)) {
            this.mainGames.gamesScrolled = this.mainGames.gamesScrolled.concat(data);
            this._setScrollOffsetForAllGames(params.offset);
            // если данные категории
          } else if (this._isCategory(params)) {
            this._addOrUpdateCategory(params.filter_categories_and_genres, data);
          }
        })
      );
  }

  public saveGames(games: GameShipment[] | CatalogGameItem[]) {
    this.games = games;
  }

  public getGame(id: string): Observable<GameShipment> {
    return this.fetchGames(true, id)
      .pipe(
        map(data => data[0])
      );
  }

  public changeGameFavoriteStateObservable(gameCode) {
    this._favouriteStateChanged.next(gameCode);
  }

  public changeGameFavoriteState(game) {
    const url = `${this._baseUrlPrimary}user/games/favourite`;
    const params: any = {
      lang: this._gamesCatalogLang,
      game_codes: [game.code],
    };
    return this._user.checkAuth()
      .pipe(
        take(1),
        switchMap((data) => {
          if (data) {
            params.token = this._user.token;
          }
          return this._api.post(url, params);
        })
      );
  }

  /**
   * Метод получения данных об одной платформе по коду
   * @param platformCode {string}
   * @returns {Observable<GamePlatform>}
   */
  public getGamePlatformInfoByCode(platformCode: string): Observable<GamePlatform> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) =>
        Boolean(platformsData) ? platformsData.find(pl => pl.code === platformCode) : null
      ));
  }

  /**
   * Метод получения данных обо всех платформах в которых доступна игра
   * @param game {GameShipment}
   * @returns {Observable<GamePlatform[]>}
   */
  public getPlatformsForGame(game: GameShipment): Observable<GamePlatform[]> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) => {
        if (!platformsData) {
          return [];
        }
        let platformsCodes = Boolean(game.platform_code) ? [game.platform_code] : [];
        if (Boolean(game.child_games)) {
          platformsCodes = [...platformsCodes, ...game.child_games.map(childGame => childGame.platform_code)];
        }
        // Список формируется так, чтобы порядок платформ сохранился,
        // т.е. платформа основной игры первая, дочерних в том же порядке, как пришли
        // важно для игр, которые у нас продаются.
        return platformsCodes.map(pCode => platformsData.find(p => p.code === pCode));
      }));
  }

  /**
   * Метод получения данных обо всех платформах в которых доступна игра из каталога
   * @param gamePlatforms
   * @returns {Observable<GamePlatform[]>}
   */
  public getPlatformsDataForCatalogGame(gamePlatforms: string[]): Observable<GamePlatform[]> {
    return this._gamePlatformsInfo.pipe(
      map((platformsData: GamePlatform[]) => {
        if (!platformsData || gamePlatforms.length === 0) {
          return [];
        }
        return gamePlatforms.map(platformCode => platformsData.find(p => p.code === platformCode));
      }));
  }

  /**
   * Метод получения данных обо всех платформах на сервисе
   * @private
   * @returns {Observable<GamePlatform[]>}
   */
  private _getGamePlatformsInfo(): Observable<GamePlatform[]> {
    return this._api.post(
      `${this._baseUrlPrimary}gameplatforms/info`,
      {lang: this._translate.currentLang}
    ).pipe(
      take(1),
      map((data: GamePlatformsInfoResponse) => {
        if (Boolean(data) && Boolean(data.error) && data.error.code === 0) {
          this._gamePlatformsInfo.next(data.game_platforms_info);
          return data.game_platforms_info;
        } else {
          this._gamePlatformsInfo.next(null);
          return null;
        }
      })
    );
  }

  /**
   * Метод получения похожих игр
   * @param gameCode
   * @returns {Observable<any>}
   */
  public getSimilar(gameCode: string): Observable<GameShipment[]> {
    const params: any = {
      lang: this._translate.currentLang,
      filter_code: gameCode
    };
    let url = `${this._baseUrl}/similar`;
    let keys: GamesKey[];
    return this._user.checkAuth()
      .pipe(
        take(1),
        mergeMap((data) => {
          if (data) {
            params.token = this._user.token;
            url = `${this._baseUrl}/similar/auth`;
          }
          return this._userGamesKeys;
        }),
        mergeMap(data => {
          keys = data;
          return this._api.post(url, params);
        }),
        map((games) => {
          if (!games.error.code) {
            return games.game_shipments_any.map(game => this._overrideGameData(game, keys));
          } else {
            return [];
          }
        })
      );
  }

  /**
   * Добавление оффсета = лимиту
   */
  public nextStep() {
    return this.setOffset(this.getOffset() + this.getLimit());
  }

  /**
   * Переписываем данные игры в удобный для фронтенда формат
   * ====
   * Время работы метода как правило не превышает 0.3мс на игру, однако выглядит все это довольно печально.
   * Остается верить и надяться, что в светлом будущем все эти операции не потребуются, т.к. апи
   * будет отдавать данные в нормальном формате.
   * @param game
   * @return {any}
   */
  private _overrideGameData(game, keys: GamesKey[]) {
    game = GamesService._getParsedImages(game);

    if (this.hasLowPrice(game) === true) {
      game.categories.push({
        code: 'Sale',
        metadata: null,
        name: this._saleWord
      });
    }
    game.mpaa = this.getGoodRating(game.mpaa);
    game.button = this.getButton(game);
    game.isTrial = game.categories.some(x => x.code === 'FreeGames');
    game.isFreeGame = this.isFreeGame(game);
    game.isPaidGame = this.isPaidGame(game);
    game.isBought = !game.isFreeGame && Boolean(keys) && keys.length !== 0 &&
      keys.some(key => game.code === key.code);
    game.isLauncherCard = this._checkGameCategory(game, GAME.category.launcher);
    return game;
  }

  private _checkGameCategory(game: GameShipment, categoryCode: string): boolean {
    return game.categories?.some(cat => cat.code === categoryCode);
  }

  public isFreeGame(game: GameShipment): boolean {
    return Boolean(game) &&
      game.categories.some(x => x.code === GAME.category.freeGames);
  }

  public isPaidGame(game: GameShipment): boolean {
    return Boolean(game) &&
      game.categories.some(x => x.code === GAME.category.paidGames);
  }

  /**
   * Sale for game?
   * @param {GameShipment} game
   * @return {boolean}
   */
  public hasLowPrice(game: GameShipment): boolean {
    if (!game.buy || game.buy.length === 0) {
      return false;
    }
    return game.buy[0].price < game.buy[0].cost;
  }

  /**
   * MPAA rating to RU format
   * @param {string} rating
   * @return {any}
   */
  public getGoodRating(rating: string) {
    switch (rating) {
      case 'EC':
        return '3+';
      case 'E':
        return '6+';
      case 'E10+':
        return '10+';
      case 'T':
        return '13+';
      case 'M':
        return '17+';
      case 'AO':
        return '18+';
      default :
        return rating;
    }
  }

  /**
   * Получит статус у кнопки
   * @param game (не применять интерфейс, ТС валит ошибку)
   * @return {string}
   */
  public getButton(game): string {
    if (game.game_status === this._gameStatus.comingSoon) {
      return 'NotAvailable';
    } else if (game.buy.length !== 0 && (game.buy[0].is_preorder === true
      || game.game_status === this._gameStatus.inProgress)) {
      return 'Preorder';
    } else if (game.game_status === this._gameStatus.broken) {
      return 'Broken';
    } else if (game.game_status === this._gameStatus.updating) {
      return 'Updating';
    } else {
      return 'Play';
    }
  }

  private _getGamesPlayParamsFromStorage() {
    if (isPlatformBrowser(this._platformId)) {
      this._gamesPlayParamsStorage = JSON.parse(localStorage.getItem('gamesPlayParams')) || {};
    }
  }

  public getGamePlayParams(gameCode, paramName?) {
    const params = this._gamesPlayParamsStorage[gameCode];
    return (paramName && params) ? params[paramName] : params;
  }

  public setGamePlayParams(gameCode, params) {
    if (isPlatformBrowser(this._platformId)) {
      this._gamesPlayParamsStorage[gameCode] = params;
      localStorage.setItem('gamesPlayParams', JSON.stringify(this._gamesPlayParamsStorage));
    }
  }

  // Метод получения всех ключей игр для авторизованного пользователя
  public getKeys(): Observable<GamesKeysResponse> {
    if (!this._user.token) {
      this._userGamesKeys.next([]);
      return of(null);
    } else {
      const params = {
        lang: this._translate.currentLang,
        token: this._user.token,
      };
      const url = `${this._baseUrlPrimary}user/games/keys`;
      return this._api.post(url, params).pipe(
        map(response => {
          this._userGamesKeys.next(Boolean(response) ? response.keys : []);
          return response;
        }));
    }
  }

  // Запоминаем позицию скрола  каталога или категорий
  public savePositionScroll(pos) {
    this.mainGames.positionScroll = pos;
  }

  /**
   * Метод сохранения позиции виртуального скролла для главного каталога
   */
  public savePositionVirtualScrollCatalog(scroll: number) {
    this.mainGames.virtualScroll = scroll;
  }

  /**
   * Метод установки позиции глобального скролла
   * TODO - вынести в более общий сервис (функционал не относится к games)
   * @param x{number}
   * @param y{number}
   */
  public setScrollPositionGlobal(x: number, y: number): void {
    try {
      this._doc.documentElement.scrollTo(x, y);
    } catch (e) {
      // IE and Edge
      this._doc.documentElement.scrollTop = y; // В Edge  данное свойство не работает
      window.scroll(0, y);
    }
  }

  /**
   * Метод определяет что это url главного каталога(не категории)
   * @param url {string}
   * @return {boolean}
   */
  public isGameRoute(url: string): boolean {
    return (url.indexOf('/games') > -1 || url.indexOf('/game') > -1) && url.indexOf('/category') === -1;
  }

  /**
   * Метод определяет что это url категории
   * @param url {string}
   * @return {boolean}
   */
  public isCategoryRoute(url: string): boolean {
    return url.indexOf('category') > -1;
  }

  public getSteamUserGames(canUpdate) {
    if (canUpdate) {
      this.deleteCategoryGames(this._platformsConnectCodes.Steam);
      return this._getOwnedSteamGames();
    }

    const categoryData = this.getCategoryData(PLATFORMS.Steam.code);

    if (Boolean(categoryData)) {
      return of(categoryData);
    }

    return this._getOwnedSteamGames();
  }

  private _getOwnedSteamGames() {
    return this.getGamesByCatalogCategory(this._platformsConnectCodes.Steam, 0)
      .pipe(
        switchMap(games => {
            if (games) {
              return of(this.getCategoryData(this._platformsConnectCodes.Steam));
            }
            return of(null);
          }
        ));
  }

  public getUserPlatformGames(canUpdate = false) {
    return combineLatest([this.getSteamUserGames(canUpdate)])
      .pipe(
        switchMap(([steamGames]) => {
            let games;
            if (Boolean(steamGames)) {
              games = {
                ...games,
                Steam: steamGames.gamesScrolled
              }
            }
            return of(games);
          }
        ),
      )
  }

  /* Костыль до реализации api на стороне бэкенда и возврата стим-игр в каталог <<<< */

  /**
   * метод возвращает из строки урла имя категории
   * @param url {string}
   * @return {string}
   */
  public getCategoryName(url: string): string {
    const arrRoad = url.split('/');
    const i = arrRoad.indexOf('category');
    return arrRoad[i + 1];
  }

  public getGameKeyInfo(gameCode: string) {
    const specialGameInfoKey = 'keys.specialAfterBuyInfo.' + gameCode;
    let infoText = this._translate.instant(specialGameInfoKey);
    if (infoText === specialGameInfoKey) {  // don't have special message
      infoText = this._translate.instant('keys.shouldEnterKey');
    }
    return infoText;
  }

  /**
   * Метод получения дополнительной информации о игре
   * @param code
   * @return {Observable<GameShipmentAdditionalInfo>}
   */
  public getGameShipmentAdditionalInfo(code: string): Observable<GameShipmentAdditionalInfo> {
    const params: GameShipmentInfoParams = {
      lang: this._translate.currentLang,
      code,
    };
    let url = `${this._baseUrl}/info`;
    if (this._user.token) {
      params.token = this._user.token;
      url = `${this._baseUrl}/info/auth`;
    }
    return this._api.post(url, params);
  }

  /**
   * Получение языка текущего каталога игр.
   * Используется для организации обновления игр с сохранением позиционирования скролла
   */
  public getGamesCurrentLang() {
    return this._gamesCatalogLang;
  }

  /**
   * Присвоение языка текущего каталога игр
   * @param lang {string}
   */
  public setGamesCurrentLang(lang) {
    this._gamesCatalogLang = lang;
  }

  /**
   * Метод возвращает позицию скролла по Y координате (IE and Edge support)
   * @return {boolean}
   */
  public getScrollY(): number {
    return ((window.scrollY || this._doc.documentElement.scrollTop) as number);
  }

  public getLaunchers() {
    const cachedLaunchers = this.getCategoryData(GAME.category.launcher);
    if (cachedLaunchers && cachedLaunchers.gamesScrolled.length > 0) {
      return of(cachedLaunchers.gamesScrolled);
    } else {
      const originalSearchValue = this._search.getSearchValue();
      this._search.setSearchValue('');
      return this.getGamesByCatalogCategory(GAME.category.launcher, 0).pipe(
        map((data) => {
            this._search.setSearchValue(originalSearchValue);
            this.setCategoryData(GAME.category.launcher, {
              gamesScrolled: [...data],
              offset: data.length
            });
            return data;
          }
        ));
    }
  }

  /**
   * Получение полных данных лаунчера для возможности запуска лаунчера из любой части приложения
   * @param platformCode
   */

  public getPlatformFullData(platformCode: string) {
    const platformsCategoryData = this.getCategoryData(GAME.category.launcher);
    const platformCachedData = platformsCategoryData?.gamesScrolled?.find(launcher => launcher.code === platformCode);
    if (platformCachedData?.run!) {
      return of(platformCachedData);
    }

    return this.getGame(platformCode).pipe(
      map(game => {
        this.setCategoryData(GAME.category.launcher, {
          ...platformsCategoryData,
          gamesScrolled: platformsCategoryData?.gamesScrolled.map(gameScrolled => {
              if (gameScrolled.code === platformCode) {
                return {...gameScrolled, ...game}
              }
              return gameScrolled
            })
        });
        return game;
      })
    )
  }


  public getLauncherBuyData(gameCode: string) {
    return LAUNCHERS_TO_BUY.find(launcher => launcher.gameCode === gameCode);
  }

  public addOrUpdateCategoryGames(categoryCode, data) {
    this._addOrUpdateCategory(categoryCode, data);
  }

  public deleteCategoryGames(categoryCode) {
    delete this.categoryGames[categoryCode];
  }

  // Метод сохранения offset для главного каталога в память
  private _setScrollOffsetForAllGames(offset?: number): void {
    offset
      ? this.mainGames.offset = offset
      : this.mainGames.offset = this.getOffset();
  }

  /**
   * Метод проверки queryParams  что у нас категория
   * @param params{any}
   * @return {boolean}
   */
  private _isCategory(params: any): boolean {
    return params.filter_categories_and_genres && !params.filter_codes && !params.filter_name;
  }

  /**
   * Метод определяет если в памяти данная категория:
   * 1. Если нет динамически добавляет ее в память
   * 2. Если существет добавляет новые данные в память
   * @param category{any}
   * @param data{Array<GameShipment>}
   * @return {void}
   */
  private _addOrUpdateCategory(category, data) {
    if (this.isCategoryExist(category)) {
      this._updateCategory(category, data);
    } else {
      this._addCategory(category, data);
    }
  }

  /**
   * Метод динамически формирует новый обьект категории:
   * @param category{[string ]}
   * @param data{Array<GameShipment>}
   * @return {void}
   */
  private _addCategory(category: [string], data: GameShipment[]): void {
    const categoryInst = category[0];
    this.categoryGames[categoryInst] = {
      offset: data.length - this.getLimit(),
      gamesScrolled: data,
      positionScroll: this.mainGames.positionScroll,
    } as IAllScrollGames;
  }

  /**
   * Метод добавляет в обьект памяти для выбраной категории новые данные
   * @param category{string}
   * @param data {Array<GameShipment>}
   */
  private _updateCategory(category: string, data: GameShipment[]): void {
    const categorInst = category[0];
    // записываем только новые данные подтянутые с апи
    this.categoryGames[categorInst].gamesScrolled = this.categoryGames[categorInst].gamesScrolled.concat(data);
    this.categoryGames[categorInst].offset = this.categoryGames[categorInst].gamesScrolled.length - this.getLimit();
  }
}
