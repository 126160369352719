import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DebtService} from '../../../payments/services/debt.service';



@Component({
  selector: 'pk-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['./debt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DebtComponent implements OnInit {
  constructor(
    public DebtService: DebtService,
  ) { }

  ngOnInit() {
  }

}
