/**
 * CONSTANT.
 * @type {Object}
 */
export const MODALS = [
  'custom/contacts',
  'custom/ordering',
  'custom/privacyPolicy',
  'custom/refund',
  'custom/usage',
  'custom/waiver',
  'success/preorder',
  'binded',
  'confirm',
  'confirmed',
  'demo',
  'download',
  'expires',
  'expiresconfirm',
  'fail',
  'infoRightHolders',
  'notAuth',
  'oferta',
  'paymentProcessing',
  'privacyPolicy',
  'promoSuccess',
  'recover',
  'requisites',
  'resumeSubscribe',
  'sent',
  'showKey',
  'success',
  'success_bind',
  'success_change',
  'successConfirm',
  'successKey',
  'successTpl',
  'usage',
  'modalReg',
  'referralLink',
  'superBanner',
  'attentionNight',
  'setEmail'
];


