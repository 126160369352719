/**
 * CONSTANT. games Data list that used at portal
 * @type {Object}
 */
export const HARDCODEGAMES = {

  // Game codes
  gta5: 'GTA5',
  gta5steam: 'GTAVSteam',
  witcher3: 'Witcher3',
  syndicate: 'ac_syndicate',
  anno: 'anno_2205',
  rainbow_siege: 'rainbow_siege',
  FarCry3_BloodDragon: 'FarCry3_BloodDragon',
  valiant_hearts_the_great_war: 'valiant_hearts_the_great_war',
  fallout4: 'fallout4',
  Armored_Warfare: 'Armored_Warfare',
  rise_of_tomb_raider: 'rise_of_tomb_raider',
  division: 'division',
  FarCryPrimal: 'FarCryPrimal',
  WorldOfTanks: 'WorldOfTanks',
  WorldOfWarships: 'WorldOfWarships',
  EVEOnline: 'EVEOnline',
  re2: 'resident_evil_revelations_2',
  naruto: 'naruto_ult_storm_4',
  dark_souls_3: 'DARK_SOULS_3',
  dirt_rally: 'dirt_rally',
  hitman: 'hitman',
  f1: 'f1_2015',
  Deus_ex: 'Deus_ex',
  Payday2: 'Payday2',
  just_cause_3: 'just_cause_3',
  Warthunder: 'Warthunder',
  nier_automata: 'nier_automata',
  BulletStorm_FCE: 'BulletStorm_FCE',
  Outlast2: 'Outlast2',
  tes_online: 'tes_online',
  TES_Steam: 'TES_Steam',

  gamesWithSubProduct: [
    {
      main: 'GTA5',
      subProduct: 'GTAVSteam'
    },
    {
      main: 'tes_online',
      subProduct: 'TES_Steam'
    }
  ]
};


