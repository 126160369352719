import { Injectable } from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { filter, scan } from 'rxjs/operators';

interface HistoryEntry {
  id: number;
  url: string;
}

interface RouterHistory {
  history: HistoryEntry[];
  currentIndex: number;
  event: NavigationStart | NavigationEnd;
  trigger: 'imperative' | 'popstate' | 'hashchange';
  id: number;
  idToRestore: number;
}

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  private _prevUrl: string;
  private _currUrl: string;

  constructor(router: Router) {
    router.events
      .pipe(
        // only include NavigationStart and NavigationEnd events
        filter(
          event =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        ),
        scan<NavigationStart | NavigationEnd, RouterHistory>(
          (acc, event) => {
            if (event instanceof NavigationStart) {
              // We need to track the trigger, id, and idToRestore from the NavigationStart events
              return {
                ...acc,
                event,
                trigger: event.navigationTrigger,
                id: event.id,
                idToRestore:
                  (event.restoredState && event.restoredState.navigationId) ||
                  undefined
              };
            }

            // NavigationEnd events
            const history = [...acc.history];
            let currentIndex = acc.currentIndex;

            // router events are imperative (router.navigate or routerLink)
            if (acc.trigger === 'imperative') {
              // remove all events in history that come after the current index
              history.splice(currentIndex + 1);

              // add the new event to the end of the history and set that as our current index
              history.push({ id: acc.id, url: event.urlAfterRedirects });
              currentIndex = history.length - 1;
            }

            // browser events (back/forward) are popstate events
            if (acc.trigger === 'popstate') {
              // get the history item that references the idToRestore
              const idx = history.findIndex(x => x.id === acc.idToRestore);

              // if found, set the current index to that history item and update the id
              if (idx > -1) {
                currentIndex = idx;
                history[idx].id = acc.id;
              } else {
                currentIndex = 0;
              }
            }

            return {
              ...acc,
              event,
              history,
              currentIndex
            };
          },
          {
            event: null,
            history: [],
            trigger: null,
            id: 0,
            idToRestore: 0,
            currentIndex: 0
          }
        ),
        // filter out so we only act when navigation is done
        filter(
          ({ event, trigger }) => event instanceof NavigationEnd && !!trigger
        )
      )
      .subscribe(({ history, currentIndex }) => {
        const previous = history[currentIndex - 1];
        const current = history[currentIndex];
        // update current and previous urls
        let prevUrl = previous ? previous.url : null;
        if (Boolean(prevUrl) && prevUrl === current.url) {
          const previous2 = history[currentIndex - 2];
          prevUrl = previous2 ? previous2.url : null;
        }
        this._prevUrl = prevUrl;
        this._currUrl = current.url;
      });
  }

  /**
   * Возвращает url предыдущего route в контексте приложения
   * При переход на внешний URL и возврате вернёт undefined
   */
  getPrevUrl(): string {
    return this._prevUrl;
  }

  getCurrUrl(): string {
    return this._currUrl;
  }

}
