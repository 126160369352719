import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pk-header-rolled-panel',
  templateUrl: './header-rolled-panel.component.html',
  styleUrls: ['./header-rolled-panel.component.scss']
})
export class HeaderRolledPanelComponent implements OnInit {
  @Input() panelContent;
  @Input() okButtonText;
  @Output() closeButtonClick = new EventEmitter<boolean>();
  @Output() okButtonClick = new EventEmitter<boolean>();

  public applyNotVisibleRolledPanel = false;

  constructor() { }

  ngOnInit() {
  }

  public onOkClick() {
    this.okButtonClick.emit(true);
  }

  public onClose() {
    this.closeButtonClick.emit(this.applyNotVisibleRolledPanel);
  }

}
