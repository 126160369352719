import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'pk-error-response',
  templateUrl: './error-response.component.html',
  styleUrls: ['./error-response.component.scss']
})
export class ErrorResponseComponent implements OnInit, OnDestroy {
  public seconds = 60;
  private reloadTimeout = 60000;
  private interval = null;

  constructor(
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) { }

  ngOnInit() {
    this.seconds = 60;
    this.interval = setInterval(() => {
      if (this.seconds === 0) {
        this.seconds = 60;
        this.reload();
      } else {
        this.seconds -= 1;
      }
    }, 1000);
  }

  public reload (): void {
    if (isPlatformBrowser(this._platformId)) {
      window.location.reload();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
