import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ApiService } from '../../shared/services/api.service';
import { UserService } from '../../user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../shared/services/constant.service';
import { Observable, of } from 'rxjs';
import { IDebtInfoResponse } from '../interfaces/IDebtInfoResponse';
import { map } from 'rxjs/operators';
import { GameClientService } from '../../shared/services/game-client.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebtService {
  private _baseUrl = environment.apiPathPrimary;
  public showPopup = false;
  public debtCost: any = false; // Сумма долга
  // Состояние попапа. Используется для определения того, что выводить в попапе
  // Может быть равно одному из трех значений: null, paidError, paidSuccess
  public popupState: string = null;
  public playGameParams = null; // Параметры запуска игры
  public isPaying = false; // Флаг для отображения лоадера при попытке оплаты задолженности
  private _errorConst: any;

  constructor(
    private _api: ApiService,
    private  _user: UserService,
    private  _translate: TranslateService,
    private  _constant: ConstantService,
    private  _localaze: LocalizeRouterService,
    private  _router: Router,
    private _client: GameClientService,
  ) {
    this._errorConst = this._constant.get('ERRORCODE');
  }


  /**
   * Метод открытия попапа
   */
  public openPopup(): void {
    this.showPopup = true;
  }

  /**
   * Метод закрытия попапа
   */
  public closePopup(): void {
    this.showPopup = false;
  }

  /**
   * Метод проверки задолженности пользователя
   * Вызывается в ЛК и при запуске игры
   * @param {any} params - параметры запуска игры
   * @returns {Observable<boolean>}
   */
  public checkDebt(params?: any): Observable<boolean> {
    // Не выводим окно если мы в ЛК
    if (this.checkPopup()) {
      return of(false);
    }
    if (!!params) {
      this.playGameParams = params;
    }
    this.popupState = null;
    return this.getDebtInfo().pipe(
      map((data: any) => {
        if (data && +data.debt_cost > 0) {
          this.showPopup = !!data.debt_cost;
          this.debtCost = `${Math.floor(data.debt_cost / 100)}`;
          this.checkPopup();
          return this.showPopup;
        } else {
          this.showPopup = false;
          return this.showPopup;
        }
      })
    );
  }

  /**
   * Метод оплаты задолжености
   * Оплата производится с первой привязанной карты!
   */
  public payDebt(): void {
    this.isPaying = true;
    this.payDebtApi().subscribe(data => {
      this.isPaying = false;
      this.popupState = data ? 'paidSuccess' : 'paidError';
      this.showPopup = true;
      if (data) {
        this.debtCost = null;
      }
    });
  }


  /**
   * Метод перехода к каталогу с играми или запуска игры
   * Зависит от наличия параметров запуска игры
   */
  public play(): void {
    this.showPopup = false;
    if (!this.playGameParams) {
      this._router.navigate([this._localaze.translateRoute('/games')]);
    } else {
      this._client.playOrShowDownloadClick(this.playGameParams);
    }
  }


  // Проверяем, можно ли выводить попап. Если есть гет-параметры - скорей всего откроется модалка, и наш попап будет над ней, что не очень
  private checkPopup(): boolean {
    const url = this._router.url;
    if (url.indexOf('account') !== -1 && url.split('?').length > 1) {
      this.showPopup = false;
      return true;
    }
    return false;
  }

  public getDebtInfo(): Observable<IDebtInfoResponse> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
    };
    return this._api.post(`${this._baseUrl}user/postpurchase/debtinfo`, params).pipe(
      map(response => {
        if (response.error.code === this._errorConst.none) {
          return response;
        } else {
          of(null);
        }
      }));
  }

  private payDebtApi(): Observable<boolean> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
    };
    return this._api.post(`${this._baseUrl}user/postpurchase/paydebt`, params)
      .pipe(
        map(response => response.error.code === this._errorConst.none)
      );
  }


}
