<div *ngIf="payment.showPaymentLoader" class="waitingForPaymentWrapper">
  <div class="title">{{ 'waitingForPayment.wait' | translate }}</div>
  <div class="waitingСircle">
    <div class="cardIconAnimation">
      <div class="saturn"></div>
      <div class="star star-1"></div>
      <div class="star star-2"></div>
      <div class="cardIcon">
        <span class="wave"></span>
        <span class="wave"></span>
        <span class="wave"></span>
      </div>
      <div class="star star-3"></div>
      <div class="star star-4"></div>
    </div>
  </div>
</div>
