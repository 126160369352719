import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../shared/services/utils.service';
import { PaymentService } from '../../payments/services/payment.service';
import { ConstantService } from '../../shared/services/constant.service';
import { SubscriptionService } from '../../user/services/subscription.service';
import { DynamicService } from '../../modal/service/dynamic.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { mergeMap, take } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { ISubscription } from '../../user/interfaces/subscription';
import { Product } from '../../games/interfaces/product';
import { AnalyticService } from '../../shared/services/analytic.service';
import { ResumeSubscribeService } from '../../shared/services/resume-subscribe.service';
import { ProductService } from '../../payments/services/product.service';
import { GamesService } from '../../shared/services/games.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  // подписка на изменение состояние модальных окон
  private  _stateModal = new Subject<any>();
  public  stateModal$ = this._stateModal.asObservable();
  // Событие удаления карты
  private  _unbindCard = new Subject<any>();
  public  unbindcard$ = this._unbindCard.asObservable();
  public _chosenProduct: Product;
  public openPKT = false;
  readonly _constTime: any;
  constructor(
    private _util: UtilsService,
    private _payment: PaymentService,
    private _constant: ConstantService,
    private _sub: SubscriptionService,
    private _games: GamesService,
    private _dynamic: DynamicService,
    private _router: Router,
    private _localaze: LocalizeRouterService,
    private  _translate: TranslateService,
    private  _analytic: AnalyticService,
    private  _cookie: CookieWrapperService,
    private  _resumeSubscribe: ResumeSubscribeService,
    private  _product: ProductService,
  ) {
    this._constTime = this._constant.get('CONSTANT.subscription.type.time');
  }

  // Управляет видимостью модальных окон во всем ЛК
  public showModal(type = '', category = '', action = ''): void {
    let currentModal: any = false;
    switch (type) {
      case 'stopSub':
        currentModal = 'stopSub';
        this._sendToGoogle('user', 'unsubscribe opened banner');
        this._sendToGoogle('stopSub', 'show');
        break;
      case 'changePassword':
        currentModal = 'changePassword';
        this._sendToGoogle('changePassword', 'show');
        break;
      case 'changeSub':
        currentModal = 'changeSub';
        this._sendToGoogle('changeSub', 'show');
        break;
      case 'removeAccount':
        currentModal = 'removeAccount';
        break;
      default:
        currentModal = '';
        this._sendToGoogle(category, action);
    }
    this._stateModal.next(currentModal);
  }

  private _sendToGoogle (category, action) {
    if (!category && !action) {
      return
    }

    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: category,
      eventAction: action,
    });
  }

  public resume( subscriptions: ISubscription, products: Product[]) {
    const product = products.filter(x => x.sub_code === subscriptions.code_tariff)[0];
    if (Boolean(product)) {
      this._chosenProduct = product;
      if (subscriptions.type !== this._constTime && (subscriptions && subscriptions.is_active_tariff)) {
        this.resumeSubscription(subscriptions);
      } else if (this._payment.hasMainPaymentSystem() === true && this._chosenProduct.sub_code) {
        this._product.selectedProduct = this._chosenProduct;
        combineLatest([
          this._payment.userPs$,
          this._payment.canBuyProductWidthBalancePs(),
          this._payment.userCanPayPkt(false),
          this._payment.convertPktToRuble()
        ]).pipe(take(1))
          .subscribe((data) => {
            this._resumeSubscribe.setModalParams({
              isChangeNow: false,
              isPktMain: !!data[0].filter(ps => ps.is_main && ps.payment_system.code === 'PKTBalance').length,
              isPktAvailible: data[1],
              userCanPayPkt: data[2],
              pktPrice: this._payment.getProductPriceSellInPkt(false),
              pktFiat: data[3],
              isTimePacket: false,
              pktDiscount: Math.ceil(100 - (data[3] * 100 / (this._product.selectedProduct.price_sell / 100)))
            });
          });
      } else {
        const oldSubTariff = subscriptions.code_tariff;
        this._router.navigate([this._localaze.translateRoute('/steps/select_ps')], {queryParams: {tariff: oldSubTariff}});
      }
    }
  }

  resumeSubscription (subscription: ISubscription) {
   this._sub.resumeRegular(subscription).pipe(mergeMap(
     respons => {
       if (respons.error.code === 0) {
           return combineLatest([this._sub.get(), this._games.fetchGames()]);
       } else if (respons.error.code === 68) {
        this.changeTariff();
       } else {
        const errMsg = respons.error.message;
        this._dynamic.addDynamicComponent({type: 'error', data: {errMsg: errMsg}});
       }
     })).subscribe( () => {this._sub.setLoaderState({type: 'resume', state: false}); });
  }

  changeTariff () {
    this._router.navigate([this._localaze.translateRoute('/steps/payment')]);
  }

  //  карта для удаления
  public  deletecard(id) {
    this._unbindCard.next(id);
  }

  //  Отобнражение кол-ва PKT
  public getViewBalance(balance: number) {
    return `${balance * 1e-18}`.substr(0, 11);
  }

 // метод копирования в буфер обмена
  public copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  // метод для выделения текста инпута при его копировании в буфер
 public selectTextInput(elem: ElementRef): void {
   const inp = elem.nativeElement;
   if (inp.createTextRange) {
     const r = inp.createTextRange();
     r.collapse(false);
     inp.focus();
     r.select();
   } else if (inp.setSelectionRange) {
     const len = inp.value.length;
     inp.focus();
     inp.setSelectionRange(0, len);
   }
 }
}


