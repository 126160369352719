import { RecaptchaComponent } from 'ng-recaptcha';

/**
 * Расширение прототипа ng-recaptcha для устранения ошибок невыполненных Promise
 * при смеене роутов
 * TODO - подготовить данные изменения для PR в ветку библиотеки, следить за версией ng-recaptcha.
 */
export interface ExtendedRecaptchaPrototype {
  subscription?: any;
  resetCaptcha?: () => void;
  errorCallback?: (err: string) => void;
  renderRecaptcha?: () => void;
  ngOnDestroy?: () => void;
}

export function extendNgRecaptchaPrototype() {

  const ngRecaptchaComponentPrototype = RecaptchaComponent.prototype as RecaptchaComponent & ExtendedRecaptchaPrototype;

  /**
   * Возвращение ошибки рекапчи в виде исключения для дальнейшей обработки ErrorHandler
   * @param err
   */
  ngRecaptchaComponentPrototype.errorCallback = function (err) {
    throw new Error('RecaptchaError: ' + err);
  };

  /**
   * Отписка при уничтожении компонента
   */
  ngRecaptchaComponentPrototype.ngOnDestroy = function () {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

  /**
   * Сброс текущей капчи
   **/
  ngRecaptchaComponentPrototype.resetCaptcha = function () {
    this.grecaptcha.reset(this.widget);
  };

  /**
   * Полное переопределение метода ng-recaptcha,
   * private renderRecaptcha,
   * в котором не хватает возвращаемого error-callback;
   **/
  ngRecaptchaComponentPrototype.renderRecaptcha = function () {
    const self = this;
    this.widget = this.grecaptcha.render(this.elementRef.nativeElement, <ReCaptchaV2.Parameters>{
      badge: self.badge,
      callback: (response: string) => {
        this.zone.run(() => self.captchaResponseCallback(response));
      },
      'expired-callback': () => {
        this.zone.run(() => self.expired());
      },
      'error-callback': (err) => {
        this.zone.run(() => self.errorCallback(err));
      },
      sitekey: self.siteKey,
      size: self.size,
      tabindex: self.tabIndex,
      theme: self.theme,
      type: self.type,
    });

    if (this.executeRequested === true) {
      this.executeRequested = false;
      this.execute();
    }
  };
}
