import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../account/service/account.service';
import { IUserPayment } from '../../../payments/interfaces/user-payment-system';
import { ResumeSubscribeService } from '../../services/resume-subscribe.service';

@Component({
  selector: 'pk-manage-cards',
  templateUrl: './manage-cards.component.html',
  styleUrls: ['./manage-cards.component.scss']
})
export class ManageCardsComponent implements OnInit {
  public openPKT = false;
  public currentCard: any;
  public isConfirm = false;

  @Input() userPs: IUserPayment[];
  @Input() needDelete: boolean;
  @Output() updatePayment = new EventEmitter<string>();
  constructor(
    private _account: AccountService,
    private _resumeSubscribe: ResumeSubscribeService
  ) {
  }

  ngOnInit() {
    const pktPs = this.userPs.filter(ps => ps.payment_system.is_balanced);
    if (this._account.openPKT && pktPs.length) {
      this.openPktInfo(pktPs[0]);
    }
  }
  openPktInfo(card, e?) {
    if (!!e) {
      e.preventDefault();
    }
    this.currentCard = card;
    this.openPKT = true;
  }
  closepktInfo () {
    this.currentCard = false;
    this.openPKT = false;
  }

  selectCard(id) {
    this.updatePayment.next(id);
  }

  showConfirm(id) {
    this.currentCard = id;
    this.isConfirm = true;
  }

  closeConfirm(): void {
    this.isConfirm = false;
  }

  public getViewBalance(balance: number) {
    return  this._account.getViewBalance(balance);
  }
}

