import { Injectable } from '@angular/core';
import { UNION_BANNERS } from '../constant/UNION';
import { ApiService } from './api.service';
import { UserService } from '../../user/services/user.service';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Banner, BannerResponse } from '../modules/main-banner/banner';
import { map, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BannerService {
  public bannersData;
  private baseUrl = `${environment.apiPathPrimary}banners/all`;
  private _bannersData = new Subject();

  constructor(private _api: ApiService,
              private _userService: UserService,
              private _translate: TranslateService) { }

  public getBanners (): Observable<BannerResponse> {
    let url = this.baseUrl;

    const params: any = {
      lang: this._translate.currentLang
    };

    if (Boolean(this._userService.token)) {
      params.token = this._userService.token;
      url = `${this.baseUrl}/auth`;
    }

    // Union Playkey & VKPC >>>
      this.bannersData = this._prepareBannersData(UNION_BANNERS);
      this._bannersData.next(this.bannersData);
      return of(this.bannersData);
    // Union Playkey & VKPC <<<
  }

  public getRandomFullBanner(): Observable<Banner[]> {
    return this._bannersData.pipe(
      map(banners => banners['full']),
      filter(fullBanners => Boolean(fullBanners) && fullBanners.length > 0),
      map(banners => banners[Math.floor(Math.random() * Math.floor(banners.length))])
    );
  }

  private _prepareCommonBanners (srcBanners: Banner[]): Banner[] {
    const list = [];
    srcBanners.forEach((src) => {
      const item: Banner = Object.assign({}, src);
      item.options = {};

      for (const i in item.parameters) {
        const param = item.parameters[i];
        item.options[param.name] = param.value;
      }

      list.push(item);
    });

    return list;
  }

  private _prepareBanners(srcBanners: Banner[], type: string): Banner[] {
    return srcBanners
      .filter(banner => banner.format === type);
  }

  private _prepareBannersData(srcBanners: Banner[]): any {
    const banners = this._prepareCommonBanners(srcBanners);

    return {
      carousel: this._prepareBanners(banners, 'Carousel'),
      full: this._prepareBanners(banners, 'Fullscreen'),
      payment: this._prepareBanners(banners, 'Payment'),
      personal: this._prepareBanners(banners, 'PersonalTop'),
      personalPopup: this._prepareBanners(banners, 'PersonalPopup'),
    };
  }

}
