import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pk-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() tooltipContent;

  constructor(
    private _elRef: ElementRef,
  ) { }

  ngOnInit() {
  }

}
