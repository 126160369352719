import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostBinding, Inject,
  Input,
  OnInit,
  Output, PLATFORM_ID, Renderer2,
  ViewContainerRef
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'pk-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipComponent implements OnInit {
  @Input() timeout = 0;
  @Input() tipClass = '';
  @Output() tipClicked = new EventEmitter();
  @Output() tipClosed = new EventEmitter();

  public isVisible = false;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
  }

  @HostBinding('class') get class() {
    return this.isVisible ? `${this.tipClass} tip--visible` : `tip--hidden`;
  }


  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      const timeout = setTimeout(() => {
        this.isVisible = true;
        clearTimeout(timeout);
        this._cdr.markForCheck();
      }, this.timeout);
    }
  }

  closeTip (e) {
    this.tipClosed.emit(e);
    e.preventDefault();
    e.stopPropagation();
  }

  clickTip (e) {
    this.tipClicked.emit(e);
    e.preventDefault();
    e.stopPropagation();
  }

}
