import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UtilsService } from './utils.service';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { GTMDataLayerParams } from '../interfaces/analytic';

declare var ga: any;
declare var yaCounter: any;

interface ITag {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  constructor(private _utils: UtilsService,
              @Inject(PLATFORM_ID) private _platformId: Object) {
  }

  /**
   * Метод получения gaClientId
   */
  getClientId() {
    if (isPlatformBrowser(this._platformId)) {
      try {
        if (!ga.getAll) {
          return 'ga don\'t initialize';
        }
        const gaClients = ga.getAll();
        if (gaClients.length) {
          const clientId = (gaClients[0].get('clientId') as string) || 'ga doesn\'t have clientId';
          if (clientId) {
            return clientId;
          }
        } else {
          return 'no counters';
        }
      } catch (err) {
        console.warn('google analytics is blocked');
        return null;
      }
    }
    return null;
  }

  /**
   * Метод отправки pageview в GTM и метрика
   * Вызывается при каждой смене роута
   * @param url
   */
  pageview(url: string) {
    if (isPlatformBrowser(this._platformId)) {
      try {
        yaCounter.hit(url);
      } catch (e) {
        console.warn('yandex pageview analytic is blocked');
      }

      this.dataLayerPush({
        event: 'pageview',
        page_url: url,
      });
    }
  }

  /**
   * Метод добавления тэга в oneSignal (срабатывает только на бою)
   * @param tags
   */
  async pushTag(tags: ITag[]): Promise<void> {
    if (
      isPlatformBrowser(this._platformId)
      && environment.production
    ) {
      await this._utils.loadScript('//vkplaycloud.mrgcdn.ru/portal-static/OneSignalSDK.js');

      const oneSignal = (<any>window).OneSignal || [];

      oneSignal.push(() =>
        tags.forEach(x => oneSignal.sendTags(x)),
      );
    }
  }

  /**
   * Метод удаления тэга в OneSignal (срабатывает только на бою)
   * @param tag
   */
  public async deleteTag(tag: string): Promise<void> {
    if (
      isPlatformBrowser(this._platformId)
      && environment.production
    ) {
      this._utils.loadScript('//vkplaycloud.mrgcdn.ru/portal-static/OneSignalSDK.js')
        .subscribe((data) => {
          const oneSignal = (<any>window).OneSignal || [];
          oneSignal.push(() => oneSignal.deleteTag(tag));
        });
    }
  }

  /**
   * Метод отправки данных в GTM (срабатывает только на бою)
   * @param params
   */
  public dataLayerPush(params: GTMDataLayerParams): void {
    const gtmParams = {
      ...params,
      timestamp: Math.floor(Date.now() / 1000)
    };
    if (
      isPlatformBrowser(this._platformId)
      && environment.production
    ) {
      const w = (<any>window);
      const dl = w.dataLayer;
      try {
        dl.push(gtmParams);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  public sendUserID(id: string): void {
    if (isPlatformBrowser(this._platformId)) {
      try {
        yaCounter.setUserID(id);
        yaCounter.userParams({
          userID: id,
        });
      } catch (e) {
        console.warn('yandex analytics is blocked');
      }
    }
  }


  /**
   * Метод передачи информации в метрику о достижении цели
   * @param {string} goal
   */
  public reachGoal(goal: string): void {
    if (isPlatformBrowser(this._platformId)) {
      try {
        yaCounter.reachGoal(goal);
      } catch (e) {
        console.warn('yandex analytics is blocked');
      }
    }
  }
}
