/**
 * CONSTANT. Error codes from server.
 */

export const ERRORCODE = {
  none: 0,
  incorrect_parameters: 1,
  access_denied: 2,
  wrong_signature: 3,
  internal_error: 4,
  signature_expired: 5,
  no_rights: 6,
  partner_not_found: 7,
  product_not_found: 8,
  service_error: 9,
  key_not_found: 10,
  game_not_found: 11,
  ps_not_found: 12,
  incorrect_language: 13,
  invalid_user_id: 14,
  password_not_secure: 15,
  email_not_valid: 16,
  user_already_confirmed: 17,
  email_not_found: 18,
  email_not_confirm: 19,
  not_available_in_country: 24,
  wrong_captcha: 45,
  not_night: 47,

  // subscriptions error
  transaction_exists: 51,
  subscription_exists: 52,
  payment_system_not_tied: 53,
  payment_system_not_several: 54,
  subscription_not_exists: 55,
  user_already_exists: 56,
  payment_system_error: 57,
  tariff_expired: 68,

  // portal errors
  access_token_invalid: 100,
  auth_error: 101,
  email_already_exist: 102,
  user_blocked: 103,
  token_expired: 104,
  account_always_link: 105,
  cant_detect_country: 115,
  email_confirmed: 117,
  mail_token_expired: 119,

  // mission reward receive errors
  subscription_already_expired: 78,
  user_reward_already_received: 122,
  keys_ended: 32,
  order_in_progress: 123,
  /**
   * linked accounts
   */
  link_code_expired: 109,
  link_code_not_found: 110,
  link_code_already_used: 111,
  beta_functional: 1000,
  tech_work: 1001,
  offer_none: 114,

  // http error codes
  request_timeout: 408
};


