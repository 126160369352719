<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>
  <div class="modalContent">
    <img [src]="'//vkplaycloud.mrgcdn.ru/img/playkeynet/bundles/successBundle/' + bundleInfo.code + '.png'"
         class="success-bundle-modal-content__image"
         [ngClass]="bundleInfo.code"
         [alt]="bundleInfo.name">
    <div class="modalContentInner">
      <!-- Загрузка -->
      <div *ngIf="!key" class="success-key__container-loading">
        <h1 class="success-key__title">{{'pkLoader' | translate}}</h1>
      </div>

      <!-- Загружено -->
      <div *ngIf="!!key"
           class="success-bundle-modal-content-info">
        <h1 class="success-bundle-modal-content-info__title">
          {{'successBundle.title' | translate | replace: '%game%' : game.title}}
        </h1>

        <h2 id="success-key__description" class="success-bundle-modal-content-info__description">
          <p>{{'successBundle.offer' | translate}}</p>
          <p [innerHTML]="'successBundle.accruedTime' | translate | replace: '%durationTime%': productDurationTime"></p>
        </h2>

        <section class="success-bundle-modal-key"
                 title="{{ key }}">
          <div class="success-bundle-modal-key__title">
            {{'successBundle.keyPlatform' | translate | replace: '%platform%': gamePlatformName}}
          </div>
          <div class="success-bundle-modal-key-content">
            <span #copykeyEl class="success-bundle-modal-key-content__text">{{ key }}</span>
            <span class="success-bundle-modal-key-content__copy"
                  (click)="copyKeyClick()"></span>
          </div>
        </section>
        <div>{{'successBundle.keyNote' | translate}}</div>
        <button
          id="modalButtonOk"
          class="modalButton modalButton--veryBig"
          (click)="closeModal()">
          {{'popupBanner.play' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
