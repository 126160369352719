/**
 * CONSTANT. Redirects list
 * @type {Object}
 */
export const REDIRECTS = {
  base: '/',
  basePortal: {
    ru: '/ru/',
    en: '/en/',
    de: '/de/',
  },
  helpPlaying: {
    ru: '//support.playkey.net/ru-RU/support/solutions/articles/13000013836',
    en: '//support.playkey.net/en/support/solutions/articles/13000013836',
    de: '//support.playkey.net/en/support/solutions/articles/13000013836',
  },
  supportHome: {
    ru: '//support.playkey.net/ru-RU/support/home',
    en: '//support.playkey.net/en/support/home',
    de: '//support.playkey.net/en/support/home',
  },
  supportNewTicket: {
    ru: '//support.playkey.net/ru-RU/support/tickets/new',
    en: '//support.playkey.net/en/support/tickets/new',
    de: '//support.playkey.net/en/support/tickets/new',
  },
  paymentList: {
    ru: '/ru/steps/payment/',
    en: '/en/steps/payment/',
    de: '/de/steps/payment/',
  },
  redirectType: {
    modal: 'modal'
  },
  shortPayment: {
    ru: '/ru/account/',
    en: '/en/account/',
    de: '/de/account/',
  }
};


