import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BannerComponent } from './modal/component/banner/banner.component';
import { ErrorComponent } from './modal/component/error/error.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { NotifyComponent } from './modal/component/notify/notify.component';
import { PreorderComponent } from './modal/component/preorder/preorder.component';
import { SuccessKeyComponent } from './modal/component/success-key/success-key.component';
import { AttentionNightComponent } from './modal/component/attention-night/attention-night.component';
import { provideErrorHandler } from './shared/factories/provide-error-handler.factory';
import { LazyLoaderService } from './shared/services/lazy-loader.service';
import { LAZY_COMPONENTS } from './shared/constant/lazy-components.const';
import { lazyArrayToObj } from './shared/factories/lazy-compnents.factory';
import { SuccessBundleComponent } from './modal/component/success-bundle/success-bundle.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterEventsService } from './shared/services/router-events.service';
import { LangService } from './shared/services/lang.service';
import { ApiService } from 'app/shared/services/api.service';
import { ApiBrowserService } from 'app/shared/services/api/api-browser.service';
import createAxiosInstance from 'app/services/axios-with-proxy';

export function exportTranslateStaticLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    ErrorComponent,
    NotifyComponent,
    PreorderComponent,
    SuccessKeyComponent,
    SuccessBundleComponent,
    AttentionNightComponent
  ],
  providers: [
    LangService,
    LazyLoaderService,
    RouterEventsService,
    {
      provide: 'AXIOS_INSTANCE',
      useFactory: () => createAxiosInstance()
    },
    {
      provide: LAZY_COMPONENTS,
      useFactory: lazyArrayToObj
    },
    {
      provide: ApiService,
      useClass: ApiBrowserService
    },
    {
      provide: ErrorHandler,
      useFactory: provideErrorHandler,
    }
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    BrowserAnimationsModule,
    BrowserCookiesModule.forRoot(),
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient]
      }
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() {
  }
}
