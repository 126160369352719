import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ConstantService } from '../../../shared/services/constant.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UtilsService } from '../../../shared/services/utils.service';
import { UserService } from '../../../user/services/user.service';


@Component({
  selector: 'pk-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {

  constant: any;

  @Input() type: any;
  @Output() Close = new EventEmitter();

  constructor(
    public user: UserService,
    private _const: ConstantService,
    private _router: Router,
    private _utils: UtilsService,
    private _localizeService: LocalizeRouterService
  ) {
    this.constant = this._const.get('CONSTANT.banner');
  }

  ngOnInit() {

  }

  public newLineConverter(str: string): string {
    if (!str) {
      return str;
    }
    return str.replace(/\\n/g, '<br/>');
  }

  openPayment(): void {
     this._router.navigate( [this._localizeService.translateRoute('/steps/payment')]) ;
     this.CloseDinamicWindow();
  }

  // Union Playkey & VKPC >>>
  get unionBanner() {
    return this.type?.options?.TYPE === 'union';
  }
  // Union Playkey & VKPC <<<

  openLink(): void {
    if (this.type.options.LINK) {
      if (this.type.options.LINK.indexOf('//') > -1) {
        window.open(this.type.options.LINK, '_blank');
      } else {
        const parsedUrl = this._utils.parseUrlAndParams(this.type.options.LINK);
        this._router.navigate([this._localizeService.translateRoute(parsedUrl.route)], {queryParams: parsedUrl.queryParams});
      }
    } else {
      this._router.navigate( [this._localizeService.translateRoute('/game/'), this.type.options.GAME_CODE]);
    }
    this.CloseDinamicWindow();
  }

 // Закрытие модального окна по ESC
  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.CloseDinamicWindow();
    }
  }
 // Закрываем окно и удаляем элемент из дом
  CloseDinamicWindow(): void {
    this.Close.next('close');
  }
}
