import { Injectable } from '@angular/core';
import { CookiesOptions, CookiesService } from 'ngx-utils-cookies-port';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Обертка для cookieService
 * Ставит куку на домен в зависимости от окружения (environment)
 */
export class CookieWrapperService {

  constructor(private _cookie: CookiesService,
              private _constant: ConstantService) { }

  /**
   * Метод записывает строку в куки
   * @param key - имя куки
   * @param value - значение
   * @param lifeTime - время жизни куки, по умолчанию ставится на 9999 дней с текущего момента (infinity).
   */
  put (key: string, value: string, lifeTime: number | Date = this._constant.get('CONSTANT.time').infinity): void {
    const options = {
      domain: environment.cookiesDomain,
      expires: typeof lifeTime === 'number' ? moment().add(lifeTime, 'days').toDate() : lifeTime,
      path: '/'
    };
    this._cookie.put(key, value, options);
  }

  putObject (key: string, value: Object, options: CookiesOptions = {}): void {
    this._cookie.putObject(key, value, Object.assign(options, {domain: environment.cookiesDomain, path: '/'}));
  }

  get (key: string): string {
    return this._cookie.get(key);
  }

  getObject (key) {
    return this._cookie.getObject(key);
  }

  remove(key: string, options: CookiesOptions = {}) {
    this._cookie.remove(key, Object.assign(options, {domain: environment.cookiesDomain}));
  }
}
