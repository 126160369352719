export const GAME = {
  status: {
    inProgress: 'InProgress',
    comingSoon: 'ComingSoon',
    updating: 'Updating',
    broken: 'Broken',
  },
  category: {
    action: 'Action',
    online: 'Online',
    all: 'all',
    sale: 'Sale',
    dlcForGame: 'dlcForGame',
    freeGames: 'FreeGames',
    paidGames: 'PaidGames',
    recentGames: 'RecentGames',
    new: 'New',
    comingSoon: 'ComingSoon',
    favourite: 'FavouriteGames',
    launcher: 'Launcher',
  },
  trial: {
    defaultMaximumGames: 3,
  },
  parameters: {
    height: 'HEIGHT',
    fps: 'FPS',
    fullHd: '1080',
  },
  buttonState: {
    notAvailable: 'NotAvailable',
    preorder: 'Preorder',
    broken: 'Broken',
    updating: 'Updating',
    endedLimit: 'EndedLimit',
    night: 'Night',
    play: 'Play',
    run: 'Run',
  },
  mmoReferrer: 'mmoReferrer',
  doom: 'doom_demo',
  doom_de: 'doom_demo_de',
  witcher3: 'Witcher3demo',
  gameType: 'Game',
  editionType: 'Edition',
  dlcType: 'Dlc',
  editionContentType: {
    None: 'None',
    NotBought: 'NotBought',
    Bought: 'Bought',
  },
};

export const GAME_STATUS_BADGE_DATA = {
  [GAME.status.updating]: {
    text: 'catalog.updateComing',
    bgColor: '#666666',
  },
  [GAME.status.comingSoon]: {
    text: 'cardGame.comingSoon',
    bgColor: '#FECF3E',
    color: '#000',
  },
  [GAME.status.inProgress]: {
    text: 'catalog.preorder',
    bgColor: '#FF6607',
  },
  [GAME.status.broken]: {
    text: 'onBoardingGameCard.gameBroken',
    bgColor: '#666666',
  }
};

/**
 * Объект с данными для статусов игры (собирается из других констант)
 */
export const GAME_STATUS_DATA = Object.assign(
  {},
  ...Object.entries(GAME.status).map(([key, value]) => ({
      [value]: {
        badge: GAME_STATUS_BADGE_DATA[value]
      }
    })
  )
);
