<svg style="display: none;">
  <symbol id="search-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 16">
    <circle cx="6.5" cy="6.5" r="5.5" stroke-width="2"/>
    <path stroke-width="2" d="M10.707 10.293l5 5"/>
  </symbol>
</svg>
<div class="search-form">
  <div class="search-form__container"
       [class.search-form__container--active]="isActive">
    <ng-container *ngIf="isActive">
      <svg class="search-icon search-icon--black">
        <use xlink:href="#search-icon"></use>
      </svg>
      <button *ngIf="searchInputValue"
              (click)="clearSearch()"
              class="search-icon-clear"></button>

      <input type="text"
             placeholder="{{'search.search-form.placeholder' | translate}}"
             class="search-form__input"
             [(ngModel)]="searchInputValue"
             (ngModelChange)="onSearchInputValueChanged($event)"
             #searchInput>
    </ng-container>
    <ng-container *ngIf="!isActive">
      <svg class="search-icon search-icon--white"
           (click)="openSearchForm($event)">
        <use xlink:href="#search-icon"></use>
      </svg>
    </ng-container>
  </div>
</div>
