import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { environment } from '../../../environments/environment';
// Сервисы
import { ConstantService } from './constant.service';
import { UserService } from '../../user/services/user.service';
import { SubscriptionService } from '../../user/services/subscription.service';
import { CookieWrapperService } from './cookie-wrapper.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DynamicService } from '../../modal/service/dynamic.service';
import { UtilsService } from './utils.service';
import { GamesService } from './games.service';
// Lodash
import * as _ from 'lodash';
// rxjs block
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { mergeMap, map, take, withLatestFrom } from 'rxjs/operators';
// интерфейсы
import { ISubscription } from '../../user/interfaces/subscription';
import { PRunParamsClient } from '../../games/interfaces/p-run-params-client';
import { PlayConfig } from '../../games/interfaces/play-config';
import { PlayGame } from '../../games/interfaces/play-game';
import { GameShipment } from '../../games/interfaces/game-shipment';
import { TimeValue } from '../../games/interfaces/time-value';
import { GamesTime } from '../../games/interfaces/games-time';
import { isPlatformBrowser, Location } from '@angular/common';
import { AnalyticService } from './analytic.service';
import { ReplaySubject } from 'rxjs';
import { OS } from '../constant/os.const';
import { RouterEventsService } from './router-events.service';


@Injectable({
  providedIn: 'root'
})
export class GameClientService implements OnDestroy {
  public gamesTime = null;

  public os: string = null;

  public loadingGame = '';

  public selectedQuality = '';

  public currentSwitch = '';

  public skipOffer = false;

  // Состояние окна оферты
  public offerWindow = false;

  public thisGame: any;

  public ofertaText: string;

  private _baseUrl = environment.apiPathPrimary;

  private _isSupported: boolean;

  private _resultUrl = '';

  // подтягивем нужную среду для правильной ссылки на скачивание
  private _api = environment.api;

  //  константы ошибок инициализируются в конструкторе
  private _errorCodeConstant: any;

  private _runLink: string;

  // Блок переменых для карточки

  private _gameCode: string;

  private _isOpenedCard = true;

  private _playConfig: PlayConfig;

  private _timeSub: Subscription;

  // Константа
  private _tariffName: any;

  private defaultTimeTimeout = 2500;

  // Observable эмитищийся из компонента оферта для запуска игры => (подписчик) game-card-content-right так как в нем есть метод $play(game)
  private offerPlay = new Subject<any>();

  public offerPlay$ = this.offerPlay.asObservable();

  // Observable  эмитищийся  с данными   [prewUrl , currentUrl]
  private _routerHistory = new ReplaySubject<Array<string>>(1);

  public routerHistory$ = this._routerHistory.asObservable();

  constructor(
    private _constant: ConstantService,
    private _user: UserService,
    private _sub: SubscriptionService,
    private _cookie: CookieWrapperService,
    private _apiS: ApiService,
    private _translate: TranslateService,
    private _router: Router,
    private _localtranslrouter: LocalizeRouterService,
    private _util: UtilsService,
    private _dinamic: DynamicService,
    private _games: GamesService,
    private _location: Location,
    private _routerEvents: RouterEventsService,
    private _analytic: AnalyticService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    // константы
    this._errorCodeConstant = this._constant.get('ERRORCODE');
    this._tariffName = this._constant.get('TARIFFNAME');
    // инициализация версии OS
    this._initOs();
  }

  ngOnDestroy(): void {
    if (this._timeSub) {
      this._timeSub.unsubscribe();
    }
  }

  // Метод возвращает предыдущий урл страницы
  // TODO заменить везде на прямую работу с методом RouterEventsService
  public getPrevUrl(): any {
    return this._routerEvents.getPrevUrl();
  }

  // Метод эмитит событие в оферт компоненте для запуска игры
  public offerPlayNext(game): void {
    this.offerPlay.next(game);
  }

  public setSelectedOs(os?: string): void {
    switch (os) {
      case OS.windows:
      case OS.lowerCase.windows:
        this._resultUrl = environment.client.windows;
        break;
      case OS.mac:
      case OS.lowerCase.mac:
        this._resultUrl = environment.client.mac;
        break;
      default:
        break;
    }
  }

  public getDownloadUrl(os?: string): Observable<string> {
    os = os || this.os;
    this.setSelectedOs(os);
    return this._sub.data$
      .pipe(
        take(1),
        withLatestFrom(this._user.checkAuth()),
        mergeMap((data: [ISubscription[], boolean]) => data[1]
          ? combineLatest([this.getShortToken(data[0][0]), this._user.getPushToken()])
          : of([])),
        map((data: any) => {
          return this._getClientUrl(data);
        })
      );
  }

  public getShortToken(subscription: ISubscription): Observable<any> {
    let gameCode = null;
    let config = 'Ultra';
    const prevUrl = this._routerEvents.getPrevUrl();
    if ((prevUrl && prevUrl.indexOf('game/') > -1)) {
      gameCode = this._playConfig.code;
      config = this._playConfig.config;
    }
    if ((Boolean(gameCode) && Boolean(subscription) && subscription.is_active_tariff)) {
      this._sendGa(gameCode);
      const params = {
        lang: this._translate.currentLang,
        token: this._user.token,
        code_game: gameCode,
        play_config: config,
        get_short_token: true,
      };
      return this._apiS.post(`${environment.apiPathPrimary}games/play`, params)
        .pipe(
          map((response: any) => {
            if (response.error.code === this._errorCodeConstant.none) {
              return response;
            }
          })
        );
    } else {
      return of(null);
    }
  }

  /**
   * Метод определения браузера пользователя
   * @returns {string}
   */
  public checkBrowser(): string {
    if (isPlatformBrowser(this._platformId)) {
      let M, tem, userAgent;
      userAgent = navigator.userAgent;
      M = userAgent.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i);
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'ie';
      }
      if (M[1] === 'Chrome') {
        if ((tem = userAgent.match(/\bOPR\/(\d+)/)) != null) {
          return 'opera';
        }
        if ((tem = userAgent.match(/\bYaBrowser\/(\d+)/)) != null) {
          return 'yabrowser';
        }
        if ((tem = userAgent.match(/\bEdge\/(\d+)/)) != null) {
          return 'edge';
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return M[0].toLowerCase();
    }
  }

  public runClientOrShowDownload(params: PRunParamsClient): void {
    if (isPlatformBrowser(this._platformId)) {
      if (this._isFirefoxAndDetect(params) === true) {
        console.log('Firefox detected. Trying to play.');
      } else if (this._isOperaAndDetect(params) === true) {
        console.log('Opera detected. Trying to play.');
      } else if (this._getCookie() === undefined) {
        this._showModalDownload(params);
      } else {
        document.location.href = params.runlink;
      }
    }
  }

  public runGame(params?: PlayConfig): void {
    const responseplayConfig: PlayConfig = this._playConfig || params;
    /// *** Проверим, поддерживается ли ОС *** ///
    if (this._checkSupport()) {
      this._playGame(responseplayConfig).subscribe((data: PlayGame) => {
        if (data.error.code === this._errorCodeConstant.none) {
          // TODO РОМА ОБСУДИ
          //  this.recentGames.addGameToRecentPlayed(responseplayConfig.mainGameCode || responseplayConfig.baseCode);
          this._sendGAOnRunGame(responseplayConfig);
          if (Boolean(this._cookie.get('notificationsAreDisabled'))) {
            data.play_url += '&offboarding=0';
          }
          this.runClientOrShowDownload({
            runlink: data.play_url,
            gameCode: responseplayConfig.code,
            onModal: true,
          });
        } else {
          if (data.error.code === this._errorCodeConstant.email_not_confirm) {
            // TODO component need add ++=
            // this.$rootScope.openModal('confirm');
          } else {
            //  this.$rootScope.errMsg = data.error.message;
            // this.$rootScope.openModal('fail');
            this._dinamic.addDynamicComponent({type: 'error', data: {errMsg: data.error.message}});
            this._sub.get();
          }
          this._analytic.dataLayerPush({
            event: 'event',
            eventCategory: 'game',
            eventAction: 'fail',
            errorCode: data.error.code,
          });
        }
      });
    } else {
      // /*** Если, ОС не поддерживается открываем окно скачивания на соответствующей странице ***//
      this._downloadClientRouter();
    }
  }

  // метод запускает игру или делает роут на страницу скачивания если у клиенты нет куки
  public playOrShowDownloadClick(configParams: PlayConfig, game?: GameShipment): void {
    this._playConfig = {
      lang: this._translate.currentLang,
      token: this._user.token,
      code: configParams.clearCode,
      baseCode: configParams.code,
      isBought: configParams.isBought,
      mainGameCode: configParams.mainGameCode,
    };

    if (configParams.code === game.code) {
      this._playConfig = {
        ...this._playConfig,
        item: game,
      };
    }

    if (configParams.defaultConfig !== undefined) {
      this._playConfig = {
        ...this._playConfig,
        config: configParams.defaultConfig,
      };
    }

    if (this._cookie.get('isHasClient') !== undefined) {
      this.runGame();
    } else {
      //  роут на страницу скачивания клиента игры
      this._downloadClientRouter();
      this._sendGameGTM('click play and show download', configParams.code);
    }
  }

  // Метод проверки оферты и управления компонента оффер
  public getOffer(clearCode: string, params, game: GameShipment, mainGame?: GameShipment): void {
    const isNeedOffer = (): boolean => Boolean(game.platform_code);

    if (!mainGame) {
      mainGame = {...game};
    }

    const endGetOffer = (): void => {
      this.skipOffer = false;
      this.playOrShowDownloadClick(params, mainGame);
      this._startLoadTimeout();
    };

    if (isNeedOffer() === true && this.skipOffer === false) {
      this.getOfferApi(clearCode).subscribe((data) => {
        if (data.error.code === this._errorCodeConstant.none) {
          if (data.is_readed === false) {
            this.ofertaText = data.offer;
            this.showOffer(clearCode, game);
          } else {
            this.playOrShowDownloadClick(params, mainGame);
          }
          this._startLoadTimeout();
        } else if (data.error.code === this._errorCodeConstant.offer_none) {
          endGetOffer();
        } else {
          const errMsg = data.error.message;
          this._dinamic.addDynamicComponent({type: 'fail', data: {errMsg: errMsg, paymentType: null}});
        }
        this._startLoadTimeout();
      });
    } else {
      endGetOffer();
    }
  }

  // Метод  открывающий  Окно оферты
  public showOffer(clearCode, game) {
    this.thisGame = game;
    this.thisGame.clearCode = clearCode;
    this.offerWindow = true;

  }

  public closeOffer() {
    this.thisGame = null;
    this.offerWindow = false;
  }

  /**
   * Return default quality code
   * @param gameCode
   * @param configurations
   */
  public getDefaultQualityCode(configurations: any, gameCode: string): string {
    const configFromStorage = this._games.getGamePlayParams(gameCode, 'quality');
    const selectedQuality = this.selectedQuality = configFromStorage || '';
    let maxHeight = 0;
    let configCode = '';

    if (_.has(configurations, selectedQuality)) {
      return selectedQuality;
    }

    _.forIn(configurations, (config, code) => {
      const configHeight = parseInt(configurations[code].HEIGHT, 10);
      if (configHeight > maxHeight) {
        maxHeight = configHeight;
        configCode = code;
      }
    });
    return configCode;
  }

  public openPayment(game?: GameShipment): void {
    game.parsed.IS_ONLY_ULTRA === undefined
      ? this._router.navigate([this._localtranslrouter.translateRoute('/steps/select_ps')], {queryParams: {game: game.code}})
      : this._openUltra();
  }

  /**
   * Parse game.run in useful format
   * @param run
   * @returns {{}} configurations object
   */
  public getParsedGameConfig(run) {
    const configurations = {};

    _.forEach(run, (config) => {
      configurations[config.code] = {
        priority: config.priority,
        code: config.code
      };
      _.forEach(config.parameters, (param) => {
        configurations[config.code][param.parameter] = param.value;
      });
    });
    return configurations;
  }

  // Метод возвращает Триальное и Демо время у Юзера
  public getAllTimesAndTrial(): Observable<any> {
    return combineLatest([this.getLeftfree(), this._user.userInfo$])
      .pipe(
        map((data: Array<any>) => {
          const [timesData, userInfo] = data;
          // Если пользователь не авторизирован у него нет  времени
          //  возвращаем null
          if (!timesData) {
            return null;
          }
          if (timesData.error.code === 0) {
            const time: TimeValue = {} as TimeValue;
            const trialTime: TimeValue = {} as TimeValue;
            let demoResult = 0;
            let trialResult = 0;
            for (let i = 0; i < timesData.stat_play_info.length; i += 1) {
              const item = timesData.stat_play_info[i];
              if (this._isTrial(item, userInfo) === true) {
                trialTime[item.code_game] = item.left_free_time_info.left_seconds_trial;
                trialResult += item.left_free_time_info.left_seconds_trial;
              }
              if (item.left_free_time_info.left_seconds_demo !== undefined) {
                time[item.code_game] = item.left_free_time_info.left_seconds_demo;
                demoResult += item.left_free_time_info.left_seconds_demo;
              }
            }
            // метод проверяет есть ли какое то время Триал или Демо
            // возможно потом понадобиться можно вернуть третим в обьекте
            const hasAnyTime = (this._hasTime(trialResult, demoResult));
            this.gamesTime = {
              allTimes: time,
              allTrialTimes: trialTime,
              hasAnyTime: hasAnyTime,
              stat_play_info: timesData.stat_play_info
            };
            return this.gamesTime;
          } else {
            return null;
          }
        })
      );
  }

  // Метод отправляющий флаг на сервер что пользователь прочитал и согласился с Офертой для игры
  public readOffer(code: string): Observable<any> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_game: code,
    };
    const url = `${this._baseUrl}user/game/readoffer`;
    return this._apiS.post(url, params)
      ;
  }

  // метод получения Офферты с АПИ
  public getOfferApi(code: string): Observable<any> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_game: code,
    };
    const url = `${this._baseUrl}user/game/offer`;
    return this._apiS.post(url, params);
  }

// Метод получения Триального и Демо времени оставшегося у пользователя
  public getLeftfree(): Observable<any> {
    if (this._user.token) {
      const url = `${this._baseUrl}user/games/leftfree`;
      const params = {
        lang: this._translate.currentLang,
        token: this._user.token,
      };
      return this._apiS.post(url, params);
    } else {
      return of(null);
    }
  }

  public closeModal(): void {
    if (this.getPrevUrl()) {
      this._location.back();
    } else {
      this._router.navigate([this._localtranslrouter.translateRoute('/games')]);
    }
  }

  // Получаем ссылку на скачивание клиента по shortToken
  private _getClientUrl(data?: any): string {
    let shortToken: string = null;
    let pushToken: string = null;
    if (data.length) {
      shortToken = data[0] && data[0].short_key ? data[0].short_key : shortToken;
      pushToken = data[1] && data[1].push_token ? data[1].push_token : pushToken;
    }
    const splitedUrl: string[] = this._resultUrl.split('/');
    const file: string = splitedUrl[splitedUrl.length - 1];
    const ext: string = file.split('.')[1];
    const regionCode: string = this._user.getDetectedRegion();
    /**
     * добавляем в имя файла токен для авто-запуска игры, формат:
     * _S<prefix><short_play_token>
     * prefix - идентификатор окружения, возможные значения: d - dev, b - beta, p - prom (бой)
     * short_play_token - сам токен, полученный из апи в this.getShortToken
     * **/
    const env = this._api.indexOf('apidev') > -1 ? 'd' : (this._api.indexOf('apibeta') > -1 ? 'b' : 'p');
    const shortTokenPrefix: string = Boolean(shortToken) ? `_S${env}${shortToken}` : '';
    const pushTokenPrefix: string = Boolean(pushToken) ? `_T${pushToken}` : '';
    const regionPrefix: string = Boolean(regionCode) ? `_R${regionCode}` : '';

    this._resultUrl = this._resultUrl + `&name=PlaykeySetup${pushTokenPrefix}${shortTokenPrefix}${regionPrefix}.${ext}`;
    return this._resultUrl;
  }

  /**
   * Метод определения  операционной системы
   * устанавливает в сервисе this.os
   */
  private _initOs(): void {
    let osName = 'Unknown OS';
    if (isPlatformBrowser(this._platformId)) {
      if (navigator.appVersion.indexOf('Win') !== -1) {
        osName = 'Windows';
      } else if (navigator.appVersion.indexOf('Mac') !== -1) {
        osName = 'Mac';
      } else if (navigator.appVersion.indexOf('X11') !== -1) {
        osName = 'Unix';
      } else if (navigator.appVersion.indexOf('Linux') !== -1) {
        osName = 'Linux';
      }
    }
    this._setOs(osName);
  }

  private _showModalDownload(params: PRunParamsClient): void {
    if (params.onModal === true) {
      this.closeModal();
      document.location.href = params.runlink;
    } else {
      this._runLink = params.runlink;
      this._gameCode = params.gameCode;
      this._isOpenedCard = false;
      //  переходим на страничку скачивания клиента
      this._downloadClientRouter();
    }
  }

  // Это поле указывает скачан ли у юзера клиент игры
  private _getCookie(): string {
    return this._cookie.get('isHasClient');
  }

  private _isFirefoxAndDetect(params: PRunParamsClient): boolean {
    const isFirefox: boolean = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const unknownProtocol = 'NS_ERROR_UNKNOWN_PROTOCOL';
    const fileNotFound = 'NS_ERROR_FILE_NOT_FOUND';
    if (isFirefox === true) {
      try { // Firefox crashed when trying to open protocol
        const iframe = document.getElementsByTagName('iframe')[0];
        iframe.contentWindow.location.href = params.runlink;
      } catch (e) {
        if (e.name === unknownProtocol || e.name === fileNotFound) {
          this._showModalDownload(params);
        }
      }
      return true;
    }
  }

  private _isOperaAndDetect(params: PRunParamsClient): boolean {
    const isOpera: boolean = navigator.userAgent.toLowerCase().indexOf('opera') > -1;
    if (isOpera === true) {
      const iframe = document.getElementsByTagName('iframe')[0];
      iframe.contentWindow.location.href = params.runlink;
      window.setTimeout(
        () => {
          try { // Opera only crashed on alert, when dont have a protocol
            alert(iframe.contentWindow.location);
          } catch (e) {
            this._showModalDownload(params);
          }
        },
        0,
      );
      return true;
    }
  }

  private _sendGAOnRunGame(responseplayConfig: PlayConfig): void {
    this._sub.data$.subscribe((subresp: ISubscription[]): void => {
      const sub: ISubscription = subresp[0];
      let dateTrue: Date = null;
      if (sub !== undefined && sub.active_to !== undefined && responseplayConfig.isBought) {
        const dateConverted = this._util.filter(sub.active_to.substring(0, sub.active_to.length - 9), 'dd-mm-yyyy');
        dateTrue = new Date(dateConverted);
      }
      if (sub !== undefined &&
        dateTrue !== undefined &&
        dateTrue !== null &&
        dateTrue.getTime() > Date.now() &&
        responseplayConfig.isBought
      ) {
        this._sendGameGTM('play', responseplayConfig.code);
      } else if (responseplayConfig.item !== undefined && responseplayConfig.item.categories !== undefined) {
        if (responseplayConfig.isBought) {
          this._sendGameGTM('free', responseplayConfig.code);
        } else if (this._util.getByCode(responseplayConfig.item.categories, 'FreeGames') !== null) {
          localStorage.setItem('demoRun', 'true');
          this._sendGameGTM('trial', responseplayConfig.code);
          this._analytic.pushTag([{
            playdemo: '1',
          }]);
        } else if (responseplayConfig.item.buy.length !== 0) {
          this._sendGameGTM('play has key', responseplayConfig.code);
        } else {
          this._sendGameGTM('demo', responseplayConfig.code);
        }
      } else {
        this._sendGameGTM('play sub product', responseplayConfig.code);
      }
    });
  }

  private _sendGameGTM(action: string, gameCode: string) {
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'game',
      eventAction: action,
      gameCode: gameCode,
    });
  }

  private _checkSupport(): boolean {
    let isSupported = false;
    switch (this.os) {
      case OS.windows:
      case OS.mac:
        isSupported = true;
        break;
      default:
        isSupported = false;
        break;
    }
    return isSupported;
  }

  private _playGame(param: PlayConfig): Observable<PlayGame> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_game: param.code,
      play_config: param.config,
    };
    const url = `${environment.apiPathPrimary}games/play`;
    return this._apiS.post(url, params);
  }

  private _setOs(os: string): void {
    this.os = os;
  }

  private _openUltra(): void {
    this._router.navigate(
      [this._localtranslrouter.translateRoute('/steps/payment')], {queryParams: {tariff: this._tariffName.subCode.ultraFDH60}}
    );
  }

  private _startLoadTimeout(): void {
    setTimeout(() => {
        this.loadingGame = '';
      },
      this.defaultTimeTimeout,
    );
  }

  private _isTrial(item: GamesTime, userInfo): boolean {
    return item.left_free_time_info.left_seconds_trial !== undefined
      && (!userInfo || userInfo.is_confirmed === false);
  }

  private _hasTime(trial: number, demo: number): boolean {
    return trial > 0 || demo > 0;
  }

//  переход на страничку скачивания
  private _downloadClientRouter(): void {
    this._router.navigate([this._localtranslrouter.translateRoute('/games/client/download')]);
  }

  private _sendGa(gameCode: string) {
    if (Boolean(this._games.userLastGameCardVisited)) {
      this._analytic.dataLayerPush({
        event: 'event',
        eventCategory: 'user',
        eventAction: 'onboarding downloadapp opened',
        gameCode: gameCode,
        isFreeGame: this._games.userLastGameCardVisited.isFreeGame,
      });
    }
  }
}


