import { Component, ElementRef, OnDestroy, OnInit, ViewChild, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService } from '../../../user/services/user.service';
import { IUserInfo } from '../../../user/interfaces/user-info';
import { CookieWrapperService } from '../../services/cookie-wrapper.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LangService } from '../../services/lang.service';
import { ConstantService } from '../../services/constant.service';
import { isPlatformBrowser } from '@angular/common';
import { FreshChatService } from '../../modules/fresh-chat/fresh-chat.service';
import { GamesService } from '../../services/games.service';
import { UtilsService } from '../../services/utils.service';
import { FooterService } from './footer.service';

@Component({
  selector: 'pk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public userInfo: IUserInfo = null;
  public readonly copyrightYear = this._utils.getYear();

  // Отписка
  private _unsubscribe$ = new ReplaySubject<any>(1);
  public socialIcons = [
    {
      title: 'VK',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-vk.svg',
      aUrl: '//vk.com/playkey_net',
      region: 'CIS',
    },
    {
      title: 'Youtube',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-youtube.svg',
      aUrl: 'footer.youtubeLink',
      region: 'any',
    },
    {
      title: 'Twitch',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-twitch.svg',
      aUrl: '//www.twitch.tv/playkey_tv',
      region: 'CIS',
    },
    {
      title: 'Discord',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-discord.svg',
      aUrl: '//discordapp.com/invite/bX3t2fr',
      region: 'CIS',
    },
    /* {
      title: 'Facebook',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-fb.svg',
      aUrl: '//www.facebook.com/playkeycloudgaming?fref=nf',
      region: 'any',
    },*/
    {
      title: 'SteamBot',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-steam-bot.svg',
      aUrl: '//gosu.ai/bot/find_out/playkey_ru',
      region: 'CIS',
    },
    {
      title: 'Reddit',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-reddit.svg',
      aUrl: '//www.reddit.com/r/Playkey/',
      region: 'no-CIS',
    },
    {
      title: 'Twitter',
      imgUrl: '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/footer/icon-twitter.svg',
      aUrl: '//twitter.com/Playkey_EN',
      region: 'no-CIS',
    }
  ];

  @ViewChild('elOpenSelect') el: ElementRef;

  constructor(private _translate: TranslateService,
              private _cookie: CookieWrapperService,
              private _router: Router,
              private _localizeService: LocalizeRouterService,
              private _lang: LangService,
              private _games: GamesService,
              private _const: ConstantService,
              @Inject(PLATFORM_ID) private _platformId: Object,
              private _freshChat: FreshChatService,
              private _renderer: Renderer2,
              private _elRef: ElementRef,
              private _utils: UtilsService,
              public user: UserService,
              public footer: FooterService
  ) {
  }

  ngOnInit() {
    const lang = this._lang.getLang();
    this._translate.setDefaultLang(lang);
    this._translate.use(lang);
    this.user.userInfo$.pipe(
      takeUntil(this._unsubscribe$))
      .subscribe(data => {
        this.userInfo = data;
      });
    if (this._cookie.get('country_iso_code') === 'UA') {
      this.socialIcons = this.socialIcons.filter(icon => icon.title !== 'VK');
    }
  }

  openPromo(): void {
    window.open('https://welcome.playkey.net/lp/promokody/', '_blank');
  }

  openTermsOfUse(): void {
    this.user.openTermsOfUse();
  }

  openPrivacyPolicyCIS(): void {
    this.user.openPrivacyPolicyCIS();
  }

  openPrivacyPolicyNotCIS(): void {
    this.user.openPrivacyPolicyNotCIS();
  }

  openImprint(): void {
    this.user.openImprint();
  }

  openPrivacyPolicyForChildren(): void {
    this.user.openPrivacyPolicyForChildren();
  }

  openPrivacyPolicyCookie(): void {
    this.user.openPrivacyPolicyCookie();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  openBlog(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (isPlatformBrowser(this._platformId)) {
      window.open(this._translate.instant('footer.blogLink'), '_blank');
    }
  }
}
