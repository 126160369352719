import { Injectable, ComponentFactoryResolver, ViewContainerRef, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { NotifyComponent } from '../component/notify/notify.component';
import { PreorderComponent } from '../component/preorder/preorder.component';
import { ErrorComponent } from '../component/error/error.component';
import { BannerComponent } from '../component/banner/banner.component';
import { IDinamicData } from '../interfaces/IDinamicData';
import { SuccessKeyComponent } from '../component/success-key/success-key.component';
import { SuccessBundleComponent } from '../component/success-bundle/success-bundle.component';
import { AttentionNightComponent } from '../component/attention-night/attention-night.component';
import { ConstantService } from '../../shared/services/constant.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

// динамическое добавление компонентов(Модалки) =>  метод addDynamicComponent(arg: Object)
// Банер => {type:'banner', data:Object}  data => получаем с BannerService getBanners()
// Ошибка => {type:'error'.data: Object}  data => интерфейс  IErrorDataComponent.ts
// Ошибка => {type:'error'.data: Object}  data => {title: string, test: string}
// preorder => {type:'preorder'.data: Object}  data => {title: string: code_game: string}   title - название игры code_game - код игры
// success-key => {type:'successkey'.data: Object}  data => {gameInfo: GameShipment, key: string, keyInfo: string}   title -название игры
// attentionNight => {type:'attentionNight', data:{subscriptions: <ISubscription>}}

export class DynamicService {
  unsub: Subscription;

  factoryResolver: ComponentFactoryResolver;

  rootViewContainer: ViewContainerRef;

  // to store reference of dynamically created component
  component: any;

  private _constTime: any;

  constructor(
    private _factoryResolver: ComponentFactoryResolver,
    private _cookie: CookieWrapperService,
    private _constant: ConstantService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this._constTime = this._constant.get('CONSTANT.time');
    this.factoryResolver = _factoryResolver;
  }

  //  метод для получения контейнера, в который будет рендериться динамический компонент
  // (в нашем случае вызывается app.component.ts => контейнер  body)
  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(data: IDinamicData, handleAfterRemoveDynamicComponent?) {
    // проверяем клиент и тип компонента, если не браузер и баннер, не выводим окно
    if (!isPlatformBrowser(this._platformId) && data.type === 'banner') {
      return;
    }
    // если предыдущий компонент существует удаляем его
    if (this.component) {
      this.remove();
    }
    let choiceComponent = null;
    switch (data.type) {
      case 'error':
        choiceComponent = ErrorComponent;
        break;
      case 'banner':
        choiceComponent = BannerComponent;
        break;
      case 'notify':
        choiceComponent = NotifyComponent;
        break;
      case 'preorder':
        choiceComponent = PreorderComponent;
        break;
      case 'successkey':
        choiceComponent = SuccessKeyComponent;
        break;
      case 'successBundle':
        choiceComponent = SuccessBundleComponent;
        break;
      case 'attentionNight':
        choiceComponent = AttentionNightComponent;
        break;
      default:
        break;
    }
    if (!choiceComponent) {
      return;
    }
    const factory = this.factoryResolver.resolveComponentFactory(<any>choiceComponent);
    this.component = factory.create(this.rootViewContainer.injector);
    this.rootViewContainer.insert(this.component.hostView);
    // передаем данные в компонент
    this.component.instance.type = this.getInputData(data);
    // слушаем Output Event компонента => его закрытие
    this.unsub = this.component.instance.Close.subscribe(() => {
      this.removeDinamic();
      if (handleAfterRemoveDynamicComponent instanceof Function) {
        handleAfterRemoveDynamicComponent(this);
      }
    });
    // устанавливаем куку на 2 дня только для баннеров
    if (data.type === 'banner') {
      this.setPersonalCookie();
    }
  }

  private removeDinamic() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
    this.remove();
  }

  private remove() {
    if (this.rootViewContainer.length < 1) {
      return;
    }
    const vcrIndex: number = this.rootViewContainer.indexOf(this.component.hostView);
    // removing component from container
    this.rootViewContainer.remove(vcrIndex);
    this.component = undefined;
  }

  private getInputData(responseData) {
    return responseData.data;
  }

  // устанавливаем куки при инициализации динамического компонента на 2 дня, если их нет
  // только на клиенте!
  private setPersonalCookie(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._cookie.put('banner', 'true', this._constTime.banner);
    }
  }
}
