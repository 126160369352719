<ng-container *ngIf="useSymbol;then useSymbolTpl else notUseSymbolTpl"></ng-container>

<ng-template #useSymbolTpl>
  <ng-container *ngFor="let item of currencyList">
      <span *ngIf="item.code === code"
            class="price price--nobr"
            [innerHTML]="item.format | replace:'{symbol}': getSign(item.sign) | replace:'{price}':price">
      </span>
  </ng-container>
</ng-template>

<ng-template #notUseSymbolTpl>
  <span class="price">{{ price }}</span>
</ng-template>
