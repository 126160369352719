import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { IErrorDataComponent } from '../../interfaces/IErrorDataComponent';
import { isEqualJsonLikeObjects } from '../../../../../utils/is-equal-json-like-objects';
import { LangService } from '../../../shared/services/lang.service';
import { CURRENTLANG } from '../../../shared/constant/CURRENTLANG';


@Component({
  selector: 'pk-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnInit, OnChanges {
  supportLink: string;

  @Input() type: IErrorDataComponent;

  @Output() Close = new EventEmitter();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _lang: LangService,
  ) {
    this._cdr.detach();
  }

  @HostListener('document:keydown.escape', ['$event']) EscListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.type
      && !isEqualJsonLikeObjects(changes.type.currentValue, changes.type.previousValue)
    ) {
      this.type = {...changes.type.currentValue};
      this._cdr.markForCheck();
    }
  }

  ngOnInit() {
    this.supportLink = CURRENTLANG.support[this._lang.getLang()];
    this._cdr.reattach();
  }

  closeModal(): void {
    this.Close.next('close');
  }

}
