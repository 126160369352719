<div class="header-rolled-panel-content">
  <h3 class="header-rolled-panel-content__text" [innerHTML]="panelContent"></h3>
  <i class="header-rolled-panel-content__icon-hand"></i>
  <button (click)="onOkClick()"
          class="header-rolled-panel-content__button">{{okButtonText}}</button>
  <div class="header-rolled-panel-content__checkbox">
    <input class="header-rolled-panel-content__checkbox-input"
           name="applyNotVisibleRolledPanel"
           type="checkbox"
           [(ngModel)]="applyNotVisibleRolledPanel">
    <span class="header-rolled-panel-content__checkbox-text">
      {{ 'howUseLaunchers.banner.checkbox' | translate }}
    </span>
  </div>
</div>
<span (click)="onClose()" class="header-rolled-panel__button-close">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path fill="#000" fill-opacity=".5"
                                                                                fill-rule="evenodd"
                                                                                d="M1.334 0L0 1.333 6.667 8 0 14.667 1.333 16 8 9.333 14.667 16 16 14.667 9.333 8 16 1.334 14.666 0 8 6.667 1.334 0z"
                                                                                clip-rule="evenodd"/></svg>
</span>
