<div class="modal-container">
  <div class="modal-container__shadow" (click)="close()"></div>
  <div  class="modal-content modal-content--short">
    <div  class="modal-content__close" (click)="close()">
      <img
        src="//vkplaycloud.mrgcdn.ru/img/playkeynet/new/closeCard.png"
        alt="{{ 'img.alt.closeModal' | translate }}">
    </div>
    <div class="modal-content__inner">
      <img *ngIf="canNotUnbind" src="//vkplaycloud.mrgcdn.ru/img/playkeynet/new/account/lock.svg">
      <h1 class="modal-content__inner-title g-text-transform--uppercase" *ngIf="!canNotUnbind">
        {{ 'accountSettings.confirmUnbind' | translate }}
      </h1>
      <div *ngIf="loading"
           class="account-stop-sub__description"
           [innerHtml]="warningText"></div>
      <pk-loader *ngIf="!loading" [color]="'#0000006b'"></pk-loader>
      <button
        *ngIf="!canNotUnbind"
        class="modal-button modal-button--close modal-button--account"
        (click)="unBindCard()">
        {{ 'accountSettings.delete' | translate }}
      </button>
      <button
        *ngIf="!canNotUnbind"
        class="modal-button modal-button--account"
        (click)="close()">
        {{ 'accountSettings.changeMind' | translate }}
      </button>
      <button
        *ngIf="canNotUnbind"
        class="modal-button--can-not-unbind"
        (click)="close()">
        {{ 'close' | translate }}
      </button>
    </div>
  </div>
</div>
