import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailGood'
})
export class EmailGoodPipe implements PipeTransform {
  /**
 * Возвращает строку, обрезаную до  символа '@'
 * @param  input String - строка, содержащая '@' - например, 'Zaika@email.kva'
 * @return String - например, 'Zaika'
 */
  transform(input: string): string {
    input = input || '';
    input = input.split('@')[0];
    if (input.length > 14) {
      input = input.substring(0, 14) + '...';
    }
    return input;
  }

}
