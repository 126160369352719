<div class="modalContainer">
  <div (click)="closeModal()" class="modalShadow"></div>

  <div class="modalContent">
    <div class="popup-banner">
      <div class="white_space_normal">
        <h1  class="modalH1" *ngIf="!!type.title">{{ type.title }}</h1>
        <h3  class="modalDescription" *ngIf="!!type.text">{{ type.text }}</h3>
        <button (click)="closeModal()"
                class="modalButton modalButton--big modalButton--block">
          {{ 'next' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
