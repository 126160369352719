import * as Const from './CONSTANT';
import * as currentLang from './CURRENTLANG';
import * as hardcodeGames from './HARDCODEGAMES';
import * as errorCode from './ERRORCODE';
import * as modals from './MODALS';
import * as obser from './OBSERVEDACTIONS';
import * as platmorms from './PLATFORMS';
import * as redirects from './REDIRECTS';
import * as shortRevealData from './SHORTREVEALDATA';
import * as tariffName from './TARIFFNAME';
import * as urls from './URLS';

export const CONST: Array<any> = [
  Const,
  currentLang,
  hardcodeGames,
  errorCode,
  modals,
  obser,
  platmorms,
  redirects,
  shortRevealData,
  tariffName,
  urls
];
