export const UNION_BANNERS = [
  {
    "code": "carousel_union",
    "format": "Carousel",
    "parameters": [
      {
        "name": "LINK",
        "value": "\/union"
      },
      {
        "name" : "TYPE",
        "value" : "union"
      }
    ]
  }
];
